import React, { Component } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Badge, Button, Tooltip } from "reactstrap";
import classes from "./PreflightStatusDropdown.module.css";

class preflightStatusDropdown extends Component {
  state = {
    tooltipOpen: false
  };

  showTooltipHandler = () => {
    this.setState({ tooltipOpen: true });
  };

  hideTooltipHandler = () => {
    this.setState({ tooltipOpen: false });
  };

  render() {
    const { color, width, height, id, isTextShown, text, jobId } = this.props;
    const { onDownloadPDFReport, toggleList } = this.props;
    const { tooltipOpen } = this.state;

    const mainStyle =
      width && height
        ? { backgroundColor: color, width: width, height: height }
        : { backgroundColor: color };

    let style = { backgroundColor: color, color: "white", margin: "7px" };

    return (
      <>
        <div
          className={classes.ButtonSmall}
          style={mainStyle}
          onMouseEnter={this.showTooltipHandler}
          onMouseLeave={this.hideTooltipHandler}
        >
            <div
              id={"PreFlightResult" + jobId}
              style={style}
              onClick={onDownloadPDFReport}
            >
              {id !== 0 && (
                <FontAwesome name="shield" style={{ fontSize: "20px" }} />
              )}
            </div>
          
            <div className={classes.TextSmall} onClick={onDownloadPDFReport}>
              {isTextShown && text} &nbsp;
            </div>

            <Button id={id} className={classes.Caret} onClick={toggleList}>
              <FontAwesome
                size="2x"
                className={classes.CaretArrow}
                name="angle-down"
              />
            </Button>

          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={"PreFlightResult" + jobId}
            toggle={this.toggle}
          >
            {text}
          </Tooltip>
      </>
    );
  }
}

preflightStatusDropdown.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  toggleList: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  isTextShown: PropTypes.bool
};

export default preflightStatusDropdown;
