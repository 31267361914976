import IdentityAPI from "../../identityApi";
import PrivateAPI from "../../privateApi";
import API from "../../api";
import { put, call, all, delay } from "redux-saga/effects";
import * as actions from "../actions/index";
import { errorHandler } from "../../helpers/Logger/Logger";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { data } from "autoprefixer";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* authSaga(action) {
  yield put(actions.authStart());
  IdentityAPI.defaults.headers.common["Authorization"] =
    "Basic RHVvbk5HLlJlYWN0LldlYlVJOjRWP3hRd1pBazY4";
  IdentityAPI.defaults.headers.post["Content-Type"] = "multipart/form-data";
  let formData = new FormData();
  formData.append("grant_type", "password");
  formData.append(
    "scope",
    "openid offline_access profile DuonNG.Services.Jobs DuonNG.Services.Info DuonNG.Private.Services IdentityServerDB.Service.Users"
  );
  formData.append("password", action.password);
  formData.append("username", action.email);

  let url = "/connect/token";
  try {
    const response = yield IdentityAPI.post(url, formData);

    if (response.data && response.data.access_token) {
      const expirationDate = yield new Date(
        new Date().getTime() + response.data.expires_in * 1000
      ); // should be 1000
      yield call(
        [localStorage, "setItem"],
        "token",
        response.data.access_token
      );
      yield call([localStorage, "setItem"], "expirationDate", expirationDate);
      yield call(
        [localStorage, "setItem"],
        "userId",
        parseJwt(response.data.access_token).sub
      );
      yield put(
        actions.authSuccess(response.data.access_token, response.data.localId)
      );
      yield put(actions.fetchUserData(response.data.access_token));
      yield put(actions.checkAuthTimeout(response.data.expires_in));
      yield put(actions.fetchCurrentVersionFromDB());
    }
  } catch (error) {
    yield put(actions.authFail(error));
    yield logError(error, error.response, "auth", url);
  }
}

export function* logoutSaga(action) {
  yield call([localStorage, "removeItem"], "token");
  yield call([localStorage, "removeItem"], "expirationDate");
  yield call([localStorage, "removeItem"], "userId");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* authCheckStateSaga(action) {
  const token = yield call([localStorage, "getItem"], "token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(
      localStorage.getItem("expirationDate")
    );
    if (expirationDate > new Date()) {
      const userId = yield call([localStorage, "getItem"], "userId");
      yield put(actions.authSuccess(token, userId));
      yield put(actions.fetchUserData(token));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      ); // should be 1000
    } else {
      yield put(actions.logout());
    }
  }
}

export function* fetchUserDataSaga(action) {
  if (action.token) {
    yield (PrivateAPI.defaults.headers.common["Authorization"] =
      "Bearer " + action.token);
  }
  try {
    const response = yield PrivateAPI.get(
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
    if (response.data) {
      yield put(actions.setUserData(response.data));
    }
  } catch (error) {
    if (action.token) {
      yield put(actions.logout());
    }
    yield put(actions.fetchUserDataFail());
    yield logError(
      error,
      error.response,
      "fetchUserData",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveUserRegistrationSaga(action) {
  try {
    if(action.userRegistrationData.anrede === "Bitte auswählen ..." || action.userRegistrationData.anrede === "Please choose ..."){ 
      action.userRegistrationData.anrede = "";
    }
    yield PrivateAPI.post(
      apiEndpoints.REGISTER_USER_ENDPOINT,
      action.userRegistrationData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(
      actions.registerUserSuccess(
        action.userRegistrationData.anrede,
        action.userRegistrationData.vorname,
        action.userRegistrationData.nachname
      )
    );
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessages = [];
    if (response.status === 401) {
      errorMessages = (data || []).reduce(
        (arr, elem) => [...arr, ...elem.errors],
        []
      );
    } else if (response.status === 409) {
      errorMessages.push(data);
    } else {
      errorMessages.push(
        "Oops, ein Fehler ist aufgetreten. Bitte kontaktieren Sie Support."
      );
    }

    yield put(actions.registerUserFail(errorMessages));
    yield logError(
      error,
      response,
      "registerUser",
      apiEndpoints.REGISTER_USER_ENDPOINT
    );
  }
}

export function* saveUserRegistrationWithCompanyTicketSaga(action) {
  try {
    var payload = Object.assign(
      { EinladungsToken: action.ticket },
      action.userRegistrationData
    );
    yield PrivateAPI.post(
      apiEndpoints.REGISTER_USER_WITH_COMPANY_ENDPOINT,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(
      actions.registerUserSuccess(
        action.userRegistrationData.vorname,
        action.userRegistrationData.nachname
      )
    );
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessages = [];
    if (response.status === 401) {
      errorMessages = (data || []).reduce(
        (arr, elem) => [...arr, ...elem.errors],
        []
      );
    } else if (response.status === 409) {
      errorMessages.push(data);
    } else {
      errorMessages.push(
        "Oops, ein Fehler ist aufgetreten. Bitte kontaktieren Sie Support."
      );
    }

    yield put(actions.registerUserFail(errorMessages));
    yield logError(
      error,
      response,
      "registerUser",
      apiEndpoints.REGISTER_USER_ENDPOINT
    );
  }
}

export function* confirmUserRegistrationSaga(action) {
  try {
    const response = yield PrivateAPI.get(
      confirmUserRegoUrl(action.id, action.code)
    );
    yield put(actions.userRegistrationConfirmationSuccess(response.data));
  } catch (error) {
    const response = error ? error.response : null;
    var message = response.data
      ? response.data
      : "Der Link war ungültig. Bitte registrieren Sie sich erneut.";
    yield put(actions.userRegistrationConfirmationFail(message));
    yield logError(
      error,
      response,
      "confirmUserRegistration",
      confirmUserRegoUrl(action.id, action.code)
    );
  }
}

export function* sendPasswordResetLinkRequestSaga(action) {
  try {
    let formData = new FormData();
    formData.append("benutzerEmail", action.email);
    yield PrivateAPI.post(
      apiEndpoints.SEND_PASSWORD_RESET_LINK_REQUEST_ENDPOINT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(actions.sendPasswordResetLinkRequestSuccess());
  } catch (error) {
    yield put(actions.sendPasswordResetLinkRequestFail());
    yield logError(
      error,
      error.response,
      "sendPasswordResetLinkRequest",
      apiEndpoints.SEND_PASSWORD_RESET_LINK_REQUEST_ENDPOINT
    );
  }
}

export function* setNewPasswordSaga(action) {
  let url,
    formData = new FormData();
  if (action.code) {
    url = apiEndpoints.SET_NEW_PASSWORD_WITH_CODE_ENDPOINT;
    formData.append("email", action.email);
    formData.append("passwort", action.newPassword);
    formData.append("code", action.code);
  } else {
    url = apiEndpoints.SET_NEW_PASSWORD_ENDPOINT;
    formData.append("AltesPasswort", action.currentPassword);
    formData.append("NeuesPasswort", action.newPassword);
  }

  try {
    yield PrivateAPI.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    yield put(actions.setNewPasswortSuccess());
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessage = Array.isArray(data) ? data.join(" ") : data;
    yield put(actions.setNewPasswortFail(errorMessage));
    yield logError(error, error.response, "setNewPassword", url);
  }
}

export function* fetchUserProfileSaga(action) {
  try {
    const token =
      "Bearer " + (action.Token ? action.Token : localStorage.getItem("token"));
    const response = yield PrivateAPI.get(
      apiEndpoints.USER_PROFILE_ENDPOINT,
      null,
      { headers: { Authorization: token } }
    );
    yield put(actions.setUserProfile(response.data));
  } catch (error) {
    yield put(actions.fetchUserProfileFail());
    yield logError(
      error,
      error.response,
      "fetchUserProfile",
      apiEndpoints.USER_PROFILE_ENDPOINT
    );
  }
}

export function* fetchUserNewslettersSaga(action) {
  try {
    const token =
      "Bearer " + (action.Token ? action.Token : localStorage.getItem("token"));
    const response = yield PrivateAPI.get(
      apiEndpoints.USER_NEWSLETTER_ENDPOINT,
      null,
      { headers: { Authorization: token } }
    );
    yield put(actions.setUserProfile(response.data));
  } catch (error) {
    yield put(actions.fetchUserProfileFail());
    yield logError(
      error,
      error.response,
      "fetchUserNewsletters",
      apiEndpoints.USER_NEWSLETTER_ENDPOINT
    );
  }
}

export function* fetchCompanyInfoSaga(action) {
  try {
    const response = yield PrivateAPI.get(
      getCompanyInfoFromTicketUrl(action.ticket)
    );
    let company = null,
      firmenAdmin = { vorname: null, nachname: null },
      invitee = { vorname: null, nachname: null, email: null };

    if (response.data) {
      if (response.data.benutzer.length >= 1) {
        firmenAdmin = {
          vorname: response.data.benutzer[0].vorname,
          nachname: response.data.benutzer[0].nachname,
        };
      }

      if (response.data.benutzer.length >= 2) {
        invitee = {
          vorname: response.data.benutzer[1].vorname,
          nachname: response.data.benutzer[1].nachname,
          currentCompany: response.data.benutzer[1].firma,
        };
      }

      invitee.email =
        response.data.offeneBenutzerEinladungen.length > 0
          ? response.data.offeneBenutzerEinladungen[0].eMail
          : null;

      company = {
        name: response.data.name,
        strasseHNr: response.data.strasseHNr,
        postleitzahl: response.data.postleitzahl,
        stadt: response.data.stadt,
        land: response.data.land,
      };
    }
    yield put(actions.setCompanyInfo(company, firmenAdmin, invitee));
  } catch (error) {
    yield put(actions.fetchCompanyInfoFail(error.response));
    yield logError(
      error,
      error.response,
      "fetchCompanyInfo",
      getCompanyInfoFromTicketUrl(action.ticket)
    );
  }
}

export function* fetchCompanyUsersSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const response = yield PrivateAPI.get(
      apiEndpoints.COMPANY_USERS_ENDPOINT,
      null,
      { headers: { Authorization: token } }
    );
    const users = response.data.map((u) => {
      u.istFirmenAdmin = u.istFirmenAdmin === true;
      return u;
    });
    yield put(actions.setCompanyUsers(users)); //.filter(user => user.istEMailBestaetigt == true)));
  } catch (error) {
    yield put(actions.fetchCompanyUsersFail(error.response));
    yield logError(
      error,
      error.response,
      "fetchCompanyUsers",
      apiEndpoints.COMPANY_USERS_ENDPOINT
    );
  }
}

export function* sendCompanyInvitationSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    var payload = {
      BenutzerEMail: action.userEmail,
      Anrede: action.salutoryAddress,
    };

    yield PrivateAPI.post(
      apiEndpoints.SEND_COMPANY_INVITATION_TO_USER,
      payload,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    yield put(actions.sendCompanyInvitationSuccess(action.userEmail));
  } catch (error) {
    yield put(actions.sendCompanyInvitationFail(error.response));
    yield logError(
      error,
      error.response,
      "sendCompanyInvitation",
      apiEndpoints.SEND_COMPANY_INVITATION_TO_USER
    );
  }
}

export function* sendCompanyInvitationAcceptanceSaga(action) {
  try {
    const token = localStorage.getItem("token");
    const authHeader = token ? { Authorization: "Bearer " + token } : {};
    const payload = { EinladungsToken: action.ticket };

    yield PrivateAPI.post(
      apiEndpoints.ACCEPT_COMPANY_INVITATION_ENDPOINT,
      payload,
      { headers: authHeader }
    );
    yield put(actions.setCompanyChangeSuccess());

    yield delay(5000);
    yield put(actions.logout());
  } catch (error) {
    yield put(actions.setCompanyChangeFail(error));
    yield logError(
      error,
      error.response,
      "sendCompanyInvitationAcceptance",
      apiEndpoints.ACCEPT_COMPANY_INVITATION_ENDPOINT
    );
  }
}

export function* sendUserEmailConfirmationSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const data = new FormData();
    if (action.email) {
      data.append("benutzerEmail", action.email);
    }
    yield PrivateAPI.post(
      apiEndpoints.SEND_NEW_EMAIL_CONFIRMATION_ENDPOINT,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(actions.sendEmailConfirmationSuccess());
  } catch (error) {
    yield put(actions.sendEmailConfirmationFail());
    yield logError(
      error,
      error.response,
      "sendUserEmailConfirmationSaga",
      apiEndpoints.SEND_NEW_EMAIL_CONFIRMATION_ENDPOINT
    );
  }
}

export function* saveUserProfileAndPreferencesSaga(action) {
  yield all([
    put(actions.saveUserProfile(action.user)),
    put(actions.saveUserLanguage(action.language)),
  ]);
}

export function* deleteUserProfileSaga(action) {   
  try {
    const token = localStorage.getItem("token");
    const authHeader = token ? { Authorization: "Bearer " + token } : {};
    const payload = { UserId: action.userId };
    let url=apiEndpoints.DELETE_USER_PROFILE + "?UserId=" + action.userId;

   const response =  yield IdentityAPI.delete(
      url,
      { headers: authHeader }
    );
    
    yield put(actions.deleteUserProfileSuccess());
    yield put(actions.fetchCompanyUsers());
  } catch (error) {
    yield put(actions.deleteUserProfileFail());
    yield logError(
      error,
      error.response,
      "deleteUserProfileSaga",
      apiEndpoints.DELETE_USER_PROFILE
    );
  }
}

export function* saveUserNewsletterSaga(action) {
  try {
    const response = yield PrivateAPI.post(
      apiEndpoints.UPDATE_USER_PROFILE,
      action.user,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    yield put(actions.saveUserNewsletterSuccess(action.newsletterType));
    yield put(actions.setUserProfile(response.data)); // updates to name
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessages = [];
    if (response.status === 401) {
      errorMessages = (data || []).reduce(
        (arr, elem) => [...arr, ...elem.errors],
        []
      );
    } else if (response.status === 409) {
      errorMessages.push(data);
    } else {
      errorMessages.push(
        "Oops, ein Fehler ist aufgetreten. Bitte kontaktieren Sie Support."
      );
    }
    yield put(
      actions.saveUserNewsletterFail(errorMessages, action.newsletterType)
    );

    yield logError(
      error,
      response,
      "saveUserProfile",
      apiEndpoints.UPDATE_USER_PROFILE
    );
  }
}

export function* saveUserProfileSaga(action) {
  try {
    const response = yield PrivateAPI.post(
      apiEndpoints.UPDATE_USER_PROFILE,
      action.user,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    yield put(actions.saveUserProfileSuccess());
    yield put(actions.setUserProfile(response.data)); // updates to name
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessages = [];
    if (response.status === 401) {
      errorMessages = (data || []).reduce(
        (arr, elem) => [...arr, ...elem.errors],
        []
      );
    } else if (response.status === 409) {
      errorMessages.push(data);
    } else {
      errorMessages.push(
        "Oops, ein Fehler ist aufgetreten. Bitte kontaktieren Sie Support."
      );
    }

    yield put(actions.saveUserProfileFail(errorMessages));
    yield logError(
      error,
      response,
      "saveUserProfile",
      apiEndpoints.UPDATE_USER_PROFILE
    );
  }
}

export function* saveUserLanguageSaga(action) {
  try {
    let data = {
      schluessel: "ui.language",
      datenAlsJson: action.language,
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(actions.saveUserLanguageSuccess(action.language));
  } catch (error) {
    yield put(actions.saveUserLanguageFail());
    yield logError(
      error,
      error.response,
      "saveUserLanguage",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* hideReleaseBannerSaga(action) {
  try {
    let data = {
      schluessel: "ui.releaseBanner",
      datenAlsJson: "false",
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(actions.hideReleaseBannerSuccess());
  } catch (error) {
    yield put(actions.hideReleaseBannerFail());
    yield logError(
      error,
      error.response,
      "hideReleaseBanner",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* postponeCertificationOneMonthSaga(action) {
  try {
    let data = {
      schluessel: "general.certification.qualifiedinfo",
      datenAlsJson: JSON.stringify(action.newDatenAlsJson),
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(
      actions.postponeCertificationOneMonthSuccess(action.newDatenAlsJson)
    );
  } catch (error) {
    yield put(actions.postponeCertificationOneMonthFail());
    yield logError(
      error,
      error.response,
      "postponeCertificationOneMonth",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* accesptFarbUmwandlungOptOutSaga(action) {
  try{
    let data = {  
      schluessel: "job.FarbUmwandlungOptOut",
      datenAlsJson: action.isOptedOut,
    };
    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(actions.accesptFarbUmwandlungtOptOutSuccess(action.isOptedOut));
  } catch (error) {
    yield put(actions.accesptFarbUmwandlungtOptOutFail());
    yield logError(
      error,
      error.response,
      "accesptFarbUmwandlung",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}
//----------------------------------------------------------
export function* saveUserMediaHintOptOutSaga(action) {
  try {
    let data = {
      schluessel: "job.MediaHintOptOut",
      datenAlsJson: action.isOptedOut,
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(actions.saveUserMediaHintOptOutSuccess(action.isOptedOut));
  } catch (error) {
    yield put(actions.saveUserMediaHintOptOutFail());
    yield logError(
      error,
      error.response,
      "saveUserMediumFormatHintOptOut",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveUserSendJobCopyAsEmailOptInSaga(action) {
  try {
    let data = {
      schluessel: "job.SendCopyEmail",
      datenAlsJson: action.isOptedIn,
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    yield put(actions.saveUserSendJobCopyAsEmailOptInSuccess(action.isOptedIn));
  } catch (error) {
    const response = error ? error.response : null;
    const data = response ? response.data : null;
    let errorMessage = Array.isArray(data) ? data.join(" ") : data;
    yield put(actions.saveUserSendJobCopyAsEmailOptInFail(errorMessage));
    yield logError(
      error,
      error.response,
      "saveUserSendJobCopyAsEmailOptInSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* fetchAssignedMediaSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const response = yield PrivateAPI.get(
      assignedMediaUrl(action.userId),
      null,
      { headers: { Authorization: token } }
    );
    yield put(actions.setAssignedMedia(response.data));
  } catch (error) {
    yield put(actions.fetchAssignedMediaFail());
    yield logError(
      error,
      error.response,
      "fetchAssignedMedia",
      assignedMediaUrl(action.userId)
    );
  }
}

export function* fetchAssignedUsersForMediaSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const response = yield PrivateAPI.get(
      assignedUserForMediaUrl(action.mediaId),
      null,
      { headers: { Authorization: token } }
    );
    yield put(actions.setAssignedUsersForMedia(response.data));
  } catch (error) {
    yield put(actions.fetchAssignedUsersForMediaFail());
    yield logError(
      error,
      error.response,
      "fetchAssignedUsersForMedia",
      assignedUserForMediaUrl(action.mediaId)
    );
  }
}

export function* saveCompanyAdminSaga(action) {
  try {
    const data = {
      IstFirmenAdmin: action.isCompanyAdmin ? 1 : 0,
    };
    const token = "Bearer " + localStorage.getItem("token");
    yield PrivateAPI.post(saveCompanyAdminUrl(action.userId), data, {
      headers: { Authorization: token },
    });
    yield put(actions.updateCompanyAdmin(action.userId, action.isCompanyAdmin));
    yield put(actions.saveCompanyAdminFail());
  } catch (error) {
    yield put(actions.saveCompanyAdminFail());
    yield logError(
      error,
      error.response,
      "saveCompanyAdmin",
      saveCompanyAdminUrl(action.userId)
    );
  }
}

export function* saveAssignedMediaSettingPerMediaSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const response = yield PrivateAPI.put(
      saveAssignedMediaSettingUrl(action.userId, action.mediaId),
      action.permissions,
      { headers: { Authorization: token } }
    );

    yield put(actions.updateAssignedUserForMedia(action.userId, response.data));
    if (action.loggedInUserId === action.userId) {
      yield put(actions.updateAssignedMedia(action.userId, response.data));
    }
  } catch (error) {
    yield put(actions.saveAssignedMediaSettingFail());
    yield logError(
      error,
      error.response,
      "saveAssignedMediaSettingPerMedia",
      saveCompanyAdminUrl(action.userId, action.mediaId)
    );
  }
}

export function* saveAssignedMediaSettingPerUserSaga(action) {
  try {
    const token = "Bearer " + localStorage.getItem("token");
    const response = yield PrivateAPI.put(
      saveAssignedMediaSettingUrl(action.userId, action.mediaId),
      action.permissions,
      { headers: { Authorization: token } }
    );
    yield put(actions.updateAssignedMedia(action.userId, response.data));
    yield put(actions.updateAssignedUserForMedia(action.userId, response.data));
    yield put(
      actions.updateUserWithAssignedMediaCount(action.userId, response.data)
    );
  } catch (error) {
    yield put(actions.saveAssignedMediaSettingFail());
    yield logError(
      error,
      error.response,
      "saveAssignedMediaSettingPerUser",
      saveCompanyAdminUrl(action.userId, action.mediaId)
    );
  }
}

export function* downloadFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const siteURL = window.location.protocol + "//" + window.location.host;

    const response = yield API.get(siteURL + "/" + action.filePath, {
      responseType: "blob",
    });
    let disposition = response.headers["content-disposition"];
    let filename = action.fileName + "." + action.fileType;

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadJobFileFail());
    yield logError(error, error.response, "downloadJobFile", action.filePath);
  }
}

export function* fetchCurrentVersionSaga(action) {
  try {
    const response = yield PrivateAPI.get(appVersionNumberURL());
    yield put(actions.setCurrentVersionFromDB(response.data.version));
  } catch (error) {
    yield logError(
      error,
      error.response,
      "fetchCurrentVersion",
      appVersionNumberURL()
    );
  }
}

export function* fetchCurrentMessageSaga(action) {
  try {
    const response = yield PrivateAPI.get(portalMessageURL());
    if (response.data && response.data.nachricht !== "") {
      yield put(actions.setPortalMessage(response.data));
    }
  } catch (error) {
    yield logError(
      error,
      error.response,
      "fetchCurrentMessage",
      portalMessageURL()
    );
  }
}

export function* fetchSpecialMessageSaga(action) {
  try {
    const response = yield PrivateAPI.get(portalMessageSpecialURL());
    yield put(actions.setPortalMessageSpecial(response.data));
  } catch (error) {
    yield logError(
      error,
      error.response,
      "fetchSpecialMessageSaga",
      portalMessageSpecialURL()
    );
  }
}

export function* fetchPortalStatusSaga(action) {
  try {
    const response = yield PrivateAPI.get(portalStatusURL());
    let anzeigen = response.data
      .filter((status) => {
        return status.rangfolge === 51;
      })
      .reduce((arr, el) => {
        return el;
      });
    let firmen = response.data
      .filter((status) => {
        return status.rangfolge === 1901;
      })
      .reduce((arr, el) => {
        return el;
      });
    let medien = response.data
      .filter((status) => {
        return status.rangfolge === 1800;
      })
      .reduce((arr, el) => {
        return el;
      });
    let verlage = response.data
      .filter((status) => {
        return status.rangfolge === 1801;
      })
      .reduce((arr, el) => {
        return el;
      });
    yield put(
      actions.setPortalStatus(
        anzeigen.wert,
        firmen.wert,
        medien.wert,
        verlage.wert
      )
    );
  } catch (error) {
    yield logError(
      error,
      error.response,
      "fetchPortalStatus",
      portalStatusURL()
    );
  }
}

export function* sendCertificationRequestSaga(action) {
  try {
    yield PrivateAPI.post(sendCertificationRequestUrl(), action.billingAddress);
  } catch (error) {}
}






export function* acceptNewAGBSaga(action) {
  try {
    let formData = new FormData();
    formData.append("AGB", "true");
    formData.append("Datenschutz", "true");

    const data = {
      AGB: true,
      Datenschutz: true,
    };

    const response = yield PrivateAPI.post(acceptNewAGBUrl(), data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data) {
      if (response.data === "Created") {
        yield put(actions.updateUserAGBValidity());
      }
    }
  } catch (error) {
    yield logError(error, error.response, "acceptNewAGB", acceptNewAGBUrl());
  }
}

const appVersionNumberURL = () => {
  return apiEndpoints.FETCH_CURRENT_APP_VERSION;
};

const portalStatusURL = () => {
  return apiEndpoints.FETCH_PORTAL_STATUS;
};

const portalMessageURL = () => {
  return apiEndpoints.FETCH_CURRENT_APP_MESSAGE;
};

const portalMessageSpecialURL = () => {
  return apiEndpoints.FETCH_CURRENT_APP_MESSAGE_SPECIAL;
};

const confirmUserRegoUrl = (id, code) => {
  return apiEndpoints.CONFIRM_USER_REGISTRATION.replace("{id}", id).replace(
    "{code}",
    code
  );
};

const getCompanyInfoFromTicketUrl = (ticket) => {
  return apiEndpoints.COMPANY_INFO_FROM_TICKET_ENDPOINT.replace(
    "{ticket}",
    ticket
  );
};

const assignedMediaUrl = (userId) => {
  return apiEndpoints.ASSIGNED_MEDIA_ENDPOINT.replace("{id}", userId);
};

const assignedUserForMediaUrl = (mediaId) => {
  return apiEndpoints.ASSIGNED_USERS_FOR_MEDIA_ENDPOINT.replace(
    "{mediumId}",
    mediaId
  );
};

const saveCompanyAdminUrl = (userId) => {
  return apiEndpoints.SAVE_COMPANY_ADMIN_ENDPOINT.replace("{id}", userId);
};

const saveAssignedMediaSettingUrl = (userId, mediaId) => {
  return apiEndpoints.SAVE_ASSIGNED_MEDIA_SETTINGS_ENDPOINT.replace(
    "{id}",
    userId
  ).replace("{mediumId}", mediaId);
};

const sendCertificationRequestUrl = () => {
  return apiEndpoints.SEND_CERTIFICATION_REQUEST;
};

const acceptNewAGBUrl = () => {
  return apiEndpoints.ACCEPT_NEW_AGB_ENDPOINT;
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
