// sorted based on first letter alphabatically
// media
export const FETCH_MEDIA_ITEMS = "FETCH_MEDIA_ITEMS";
export const FETCH_MEDIA_AZURE_ITEMS = "FETCH_MEDIA_AZURE_ITEMS";
export const FETCH_MEDIA_ITEMS_FAILED = "FETCH_MEDIA_ITEMS_FAILED";
export const FETCH_MEDIA_ITEM_NAMES = "FETCH_MEDIA_ITEM_NAMES";
export const FETCH_ISSUES = "FETCH_ISSUES";
export const FETCH_ISSUES_FAILED = "FETCH_ISSUES_FAILED";
export const FETCH_DATES = "FETCH_DATES";
export const SET_MEDIA_ITEMS = "SET_MEDIA_ITEMS";
export const SET_MEDIA_ITEM_NAMES = "SET_MEDIA_ITEM_NAMES";
export const SET_ISSUES = "SET_ISSUES";
export const LEAVE_MEDIA_ITEM = "LEAVE_MEDIA_ITEM";

// duon certified
export const FETCH_DUON_CERTIFIED = "FETCH_DUON_CERTIFIED";
export const FETCH_DUON_CERTIFIED_FAILED = "FETCH_DUON_CERTIFIED_FAILED";
export const SET_CERTIFIED_ITEMS = "SET_CERTIFIED_ITEMS";

// publishers
export const FETCH_PUBLISHERS = "FETCH_PUBLISHERS";
export const FETCH_PUBLISHERS_FAILED = "FETCH_PUBLISHERS_FAILED";
export const SET_PUBLISHERS = "SET_PUBLISHERS";
export const FETCH_PUBLISHER_DETAIL = "FETCH_PUBLISHER_DETAIL";
export const FETCH_PUBLISHER_DETAIL_FAILED = "FETCH_PUBLISHER_DETAIL_FAILED";
export const SET_PUBLISHER_DETAIL = "SET_PUBLISHER_DETAIL";
export const LEAVE_PUBLISHERS = "LEAVE_PUBLISHERS";
export const LEAVE_PUBLISHER_DETAIL = "LEAVE_PUBLISHER_DETAIL";
export const SHOW_PUBLISHER_DETAIL_RESULT = "SHOW_PUBLISHER_DETAIL_RESULT";
export const HIDE_PUBLISHER_DETAIL_RESULT = "HIDE_PUBLISHER_DETAIL_RESULT";

// freigabe
export const FETCH_FREIGABE_MEDIA_ITEMS = "FETCH_FREIGABE_MEDIA_ITEMS";
export const FETCH_FREIGABE_MEDIA_ITEMS_FAILED =
  "FETCH_FREIGABE_MEDIA_ITEMS_FAILED";
export const FETCH_FREIGABE_DATES = "FETCH_FREIGABE_DATES";
export const FETCH_FREIGABE_MEDIA_DETAIL = "FETCH_FREIGABE_MEDIA_DETAIL";
export const FETCH_FREIGABE_INFO = "FETCH_FREIGABE_INFO";
export const FETCH_FREIGABE_INFO_FAIL = "FETCH_FREIGABE_INFO_FAIL";
export const INIT_FREIGABE_DETAIL = "INIT_FREIGABE_DETAIL";
export const INIT_FREIGABE_DETAIL_FAIL = "INIT_FREIGABE_DETAIL_FAIL";
export const SET_FREIGABE_MEDIA_ITEMS = "SET_FREIGABE_MEDIA_ITEMS";
export const SET_FREIGABE_DATES = "SET_FREIGABE_DATES";
export const SET_FREIGABE_MEDIA_DETAIL = "SET_FREIGABE_MEDIA_DETAIL";
export const SET_FREIGABE_INFO = "SET_FREIGABE_INFO";
export const DOWNLOAD_FREIGABE_FILE = "DOWNLOAD_FREIGABE_FILE";
export const DOWNLOAD_FREIGABE_FROM_LIST = "DOWNLOAD_FREIGABE_FROM_LIST";
export const LEAVE_FREIGABE_DETAIL = "LEAVE_FREIGABE_DETAIL";

// media detail
export const ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO =
  "ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO";
export const ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO_FAIL =
  "ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO_FAIL";
export const DOWNLOAD_TECHNICAL_INFO = "DOWNLOAD_TECHNICAL_INFO";
export const DOWNLOAD_TECHNICAL_INFO_FAIL = "DOWNLOAD_TECHNICAL_INFO_FAIL";
export const FETCH_MEDIA_DETAIL = "FETCH_MEDIA_DETAIL";
export const FETCH_MEDIA_DETAIL_FAIL = "FETCH_MEDIA_DETAIL_FAIL";
export const FETCH_MEDIA_TECHNICAL_INFO = "FETCH_MEDIA_TECHNICAL_INFO";
export const INIT_MEDIA_DETAIL = "INIT_MEDIA_DETAIL";
export const INIT_TECHNICAL_URL = "INIT_TECHNICAL_URL";
export const SET_MEDIA_DETAIL_TECHNICAL_INFO = "SET_MEDIA_DETAIL_ISSUE";
export const SET_MEDIA_DETAIL_ISSUE = "SET_MEDIA_DETAIL_ISSUE";
export const SET_MEDIA_DETAIL_ISSUES = "SET_MEDIA_DETAIL_ISSUES";
export const SET_MEDIA_DETAIL_SELECTED_ISSUE =
  "SET_MEDIA_DETAIL_SELECTED_ISSUE";
export const FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO =
  "FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO";
export const FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO_FAIL =
  "FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO_FAIL";
export const FETCH_TECHNICAL_URL_INFO = "FETCH_TECHNICAL_URL_INFO";
export const FETCH_TECHNICAL_URL_INFO_FAIL = "FETCH_TECHNICAL_URL_INFO_FAIL";
export const FETCH_MEDIA_DETAIL_TECHNICAL_INFO =
  "FETCH_MEDIA_DETAIL_TECHNICAL_INFO";
export const FETCH_MEDIA_DETAIL_TECHNICAL_INFO_FAIL =
  "FETCH_MEDIA_DETAIL_TECHNICAL_INFO_FAIL";
export const FETCH_TECHNICAL_INFO_FOR_SPECIFIC_ISSUE_FAIL =
  "FETCH_TECHNICAL_INFO_FOR_SPECIFIC_ISSUE_FAIL";
export const SET_ACTUALIZED_MEDIA_DETAIL_TECHNICAL_INFO =
  "SET_ACTUALIZED_MEDIA_DETAIL_TECHNICAL_INFO";
export const SET_MEDIA_DETAIL = "SET_MEDIA_DETAIL";
export const SET_MEDIA_DETAIL_CONTACT_PERSON =
  "SET_MEDIA_DETAIL_CONTACT_PERSON";
export const LEAVE_MEDIA_DETAIL = "LEAVE_MEDIA_DETAIL";

// jobBuilder
export const ACTUALIZE_TECHNICAL_INFO = "ACTUALIZE_TECHNICAL_INFO";
export const ACTUALIZE_TECHNICAL_INFO_FAIL = "ACTUALIZE_TECHNICAL_INFO_FAIL";
export const ADD_CHILD_JOBS_TO_PARENT = "ADD_CHILD_JOBS_TO_PARENT";
export const ADD_ALL_CHILD_JOBS_TO_PARENT = "ADD_ALL_CHILD_JOBS_TO_PARENT";
export const ADD_FILE_TO_EXISTING_JOB = "ADD_FILE_TO_EXISTING_JOB";
export const ADD_FILE_TO_EXISTING_JOB_FAIL = "ADD_FILE_TO_EXISTING_JOB_FAIL";
export const ADD_FILE_TO_NEW_JOB = "ADD_FILE_TO_NEW_JOB";
export const ADD_FILE_TO_NEW_JOB_FAILED = "ADD_FILE_TO_NEW_JOB_FAILED";
export const ADD_FREE_FORMAT = "ADD_FREE_FORMAT";
export const ADD_PARENT_ORPHAN_JOBS = "ADD_PARENT_ORPHAN_JOBS";
export const ALLOW_REPROOF = "ALLOW_REPROOF";
export const CHANGE_ISSUE = "CHANGE_ISSUE";
export const CHANGE_ISSUE_FORMAT = "CHANGE_ISSUE_FORMAT";
export const CHECK_JOB_STATUS = "CHECK_JOB_STATUS";
export const CHECK_JOB_STATUS_FAIL = "CHECK_JOB_STATUS_FAIL";
export const CHECK_JOB_STATUS_FILE_ERROR = "CHECK_JOB_STATUS_FILE_ERROR";
export const CHECK_SENT_STATUS = "CHECK_SENT_STATUS";
export const CHECK_CONTAINER_IS_SENT = "CHECK_CONTAINER_IS_SENT";
export const CHECK_CONTAINER_CAN_BE_SENT = "CHECK_CONTAINER_CAN_BE_SENT";
export const CREATE_CONTAINER_FAIL = "CREATE_CONTAINER_FAIL";
export const CREATE_CONTAINER_ONE_JOB = "CREATE_CONTAINER_ONE_JOB";
export const CREATE_CONTAINER_ONE_JOB_FAIL = "CREATE_CONTAINER_ONE_JOB_FAIL";
export const CREATE_CONTAINER_MANY_JOBS = "CREATE_CONTAINER_MANY_JOBS";
export const CREATE_CONTAINER_MANY_JOBS_SPLIT =
  "CREATE_CONTAINER_MANY_JOBS_SPLIT";
export const DELETE_JOB_JOBBUILDER = "DELETE_JOB_JOBBUILDER";
export const DELETE_CHILD_JOBS = "DELETE_CHILD_JOBS";
export const DISABLE_SAVING_CONTAINER = "DISABLE_SAVING_CONTAINER";
export const HAS_CONVERTED_COLOR_GMG_FILE = "HAS_CONVERTED_COLOR_GMG_FILE"; 
export const ENABLE_SAVING_CONTAINER = "ENABLE_SAVING_CONTAINER";
export const START_SENDING_CONTAINER_FORM = "START_SENDING_CONTAINER_FORM";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const DOWNLOAD_REPORT_FAIL = "DOWNLOAD_REPORT_FAIL";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const DOWNLOAD_PDF_FAIL = "DOWNLOAD_PDF_FAIL";
export const EDIT_FREE_FORMAT = "EDIT_FREE_FORMAT";
export const EDIT_JOB_BUILDER = "EDIT_JOB_BUILDER";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_CONTAINER_SUCCESS = "EDIT_CONTAINER_SUCCESS";
export const EDIT_CONTAINER_FAIL = "EDIT_CONTAINER_FAIL";
export const INIT_JOB_BUILDER = "INIT_JOB_BUILDER";
export const FETCH_MEDIA_FAIL = "FETCH_MEDIA_FAIL";
export const FETCH_JOB_ISSUES = "FETCH_JOB_ISSUES";
export const FETCH_JOB_ISSUES_FAIL = "FETCH_JOB_ISSUES_FAIL";
export const FETCH_TECHNICAL_INFO = "FETCH_TECHNICAL_INFO";
export const FETCH_TECHNICAL_INFO_FAIL = "FETCH_TECHNICAL_INFO_FAIL";
export const FETCH_TECHNICAL_INFO_EDIT_MODE = "FETCH_TECHNICAL_INFO_EDIT_MODE";
export const FETCH_PARENT_TECHNICAL_INFO_EDIT_MODE =
  "FETCH_PARENT_TECHNICAL_INFO_EDIT_MODE";
export const FETCH_CONTAINER_SUCCESS = "FETCH_CONTAINER_SUCCESS";
export const FETCH_CONTAINER_FAIL = "FETCH_CONTAINER_FAIL";
export const FETCH_JOBBUILDER_MEDIA = "FETCH_JOBBUILDER_MEDIA";
export const FETCH_MEDIA_EDIT_MODE = "FETCH_MEDIA_EDIT_MODE";
export const FETCH_PARENT_MEDIA_EDIT_MODE = "FETCH_PARENT_MEDIA_EDIT_MODE";
export const FETCH_TECHNICAL_DATA = "FETCH_TECHNICAL_DATA";
export const FETCH_JOB_DATES = "FETCH_JOB_DATES";
export const FETCH_JOB_DATES_EDIT_MODE = "FETCH_JOB_DATES_EDIT_MODE";
export const GET_JOB_CONTACTPERSONS = "GET_JOB_CONTACTPERSONS";
export const FETCH_PARENT_JOB_DATES_EDIT_MODE =
  "FETCH_PARENT_JOB_DATES_EDIT_MODE";
export const FETCH_JOB_IMAGE = "FETCH_JOB_IMAGE";
export const FETCH_JOB_IMAGE_FAIL = "FETCH_JOB_IMAGE_FAIL";
export const FINIALIZE_JOB_UPLOAD = "FINIALIZE_JOB_UPLOAD";
export const LEAVE_JOB_BUILDER = "LEAVE_JOB_BUILDER";
export const PROOF_CONTAINER = "PROOF_CONTAINER";
export const PROOF_CONTAINER_FAIL = "PROOF_CONTAINER_FAIL";
export const COLOR_CONVERSION = "COLOR_CONVERSION"; 
export const CHECK_CONTAINER_CAN_BE_CONVERTED ="CHECK_CONTAINER_CAN_BE_CONVERTED"; 
export const CHECK_CONTAINER_CAN_BE_CONVERTED_WITHOUT_CURRENT_JOB ="CHECK_CONTAINER_CAN_BE_CONVERTED_WITHOUT_CURRENT_JOB"; 
export const CHECK_JOB_HAS_ERGEBNIS_DOWNLOADS = "CHECK_JOB_HAS_ERGEBNIS_DOWNLOADS"; 
export const SAVE_ALL_COLOR_GMG_FILES = "SAVE_ALL_COLOR_GMG_FILES"; 
export const PROOF_CONTAINER_WITHOUT_COLOR_GMG_JOBS = "PROOF_CONTAINER_WITHOUT_COLOR_GMG_JOBS";
export const HIDE_COLOR_GMG_CONTAINER_BUTTON = "HIDE_COLOR_GMG_CONTAINER_BUTTON"; 
export const SHOW_COLOR_GMG_CONTAINER_BUTTON = "SHOW_COLOR_GMG_CONTAINER_BUTTON"; 
export const SAVE_COLOR_GMG_FILE = "SAVE_COLOR_GMG_FILE"; 
export const SAVE_COLOR_GMG_FILE_START = "SAVE_COLOR_GMG_FILE_START";
export const SAVE_COLOR_GMG_FILE_SUCCESS = "SAVE_COLOR_GMG_FILE_SUCCESS";
export const SAVE_COLOR_GMG_FILE_FAIL = "SAVE_COLOR_GMG_FILE_FAIL"; 
export const SAVE_COLOR_GMG_JOB = "SAVE_COLOR_GMG_JOB";
export const SAVE_COLOR_GMG_JOB_SUCCESS = "SAVE_COLOR_GMG_JOB_SUCCESS";
export const SAVE_COLOR_GMG_JOB_FAIL = "SAVE_COLOR_GMG_JOB_FAIL"; 
export const GET_COLOR_GMG_JOB_RESULT = "GET_COLOR_GMG_JOB_RESULT";
export const GET_COLOR_GMG_JOB_RESULT_SUCCESS = "GET_COLOR_GMG_JOB_RESULT_SUCCESS";
export const GET_COLOR_GMG_JOB_RESULT_FAIL = "GET_COLOR_GMG_JOB_RESULT_FAIL";
export const GET_COLOR_GMG_CONVERTED_FILE = "GET_COLOR_GMG_CONVERTED_FILE";
export const GET_COLOR_GMG_CONVERTED_FILE_SUCCESS = "GET_COLOR_GMG_CONVERTED_FILE_SUCCESS";
export const GET_COLOR_GMG_CONVERTED_FILE_FAIL = "GET_COLOR_GMG_CONVERTED_FILE_FAIL";
export const SAVE_CONVERTED_FILE = "SAVE_CONVERTED_FILE";
export const SAVE_CONVERTED_FILE_SUCCESS = "SAVE_CONVERTED_FILE_SUCCESS";
export const SAVE_CONVERTED_FILE_FAIL = "SAVE_CONVERTED_FILE_FAIL";
export const SAVE_CONVERTED_COLOR_GMG_FILE_SUCCESS = "SAVE_CONVERTED_COLOR_GMG_FILE_SUCCESS";
export const SAVE_CONVERTED_COLOR_GMG_FILE_FAIL = "SAVE_CONVERTED_COLOR_GMG_FILE_FAIL";
export const CHECK_CONVERTED_COLOR_GMG_JOB_STATUS = "CHECK_CONVERTED_COLOR_GMG_JOB_STATUS";
export const CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FILE_ERROR = "CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FILE_ERROR";
export const SAVE_CONVERTED_COLOR_GMG_JOB_FINISH = "SAVE_CONVERTED_COLOR_GMG_JOB_FINISH";
export const CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FAIL = "CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FAIL"; 
export const FINIALIZE_CONVERTED_COLOR_GMG_JOB_UPLOAD = "FINIALIZE_CONVERTED_COLOR_GMG_JOB_UPLOAD";
export const CONVERTED_COLOR_GMG_STATUS_CHECK_SUCCESS = "CONVERTED_COLOR_GMG_STATUS_CHECK_SUCCESS";


export const SET_ERGEBNIS_DOWNLOADS = "SET_ERGEBNIS_DOWNLOADS"; 
export const ERGEBNIS_DOWNLOADS_SUCCESS = "ERGEBNIS_DOWNLOADS_SUCCESS"; 
export const ERGEBNIS_DOWNLOADS_FAIL = "ERGEBNIS_DOWNLOADS_FAIL"; 


export const PROOF_FILE = "PROOF_FILE";
export const PROOF_FILE_SUCCESS = "PROOF_FILE_SUCCESS";
export const PROOF_FILE_FAIL = "PROOF_FILE_FAIL";
export const PROOF_FILE_START = "PROOF_FILE_START";

export const REMOVE_CHILD_JOBS_FROM_PARENT = "REMOVE_CHILD_JOBS_FROM_PARENT";
export const REMOVE_NORMAL_JOB = "REMOVE_NORMAL_JOB";
export const TOGGLE_UPLOAD_COMPONENT = "TOGGLE_UPLOAD_COMPONENT";
export const SAVE_JOB_FINISH = "SAVE_JOB_FINISH";
export const SAVE_NEW_JOB_NO_FILE = "SAVE_NEW_JOB_NO_FILE";
export const SAVE_EXISTING_CONTAINER = "SAVE_EXISTING_CONTAINER";
export const SAVE_EXISTING_CONTAINER_FAIL = "SAVE_EXISTING_CONTAINER_FAIL";
export const SAVE_NEW_CONTAINER = "SAVE_NEW_CONTAINER";
export const SAVE_NEW_CONTAINER_FAIL = "SAVE_NEW_CONTAINER_FAIL";
export const SAVE_CONTAINER_FAILED = "SAVE_CONTAINER_FAILED";
export const SAVE_FILE = "SAVE_FILE";
export const SAVE_FILE_SUCCESS = "SAVE_FILE_SUCCESS";
export const SAVE_FILE_FAIL = "SAVE_FILE_FAIL";
export const STATUS_CHECK_SUCCESS = "STATUS_CHECK_SUCCESS";
export const JOB_CHECK_SUCCESS = "JOB_CHECK_SUCCESS";
export const JOB_CHECK_START = "JOB_CHECK_START";
export const SEND_CONTAINER = "SEND_CONTAINER";
export const SET_ACTUALIZED_TECHNICAL_INFO = "SET_ACTUALIZED_TECHNICAL_INFO";
export const SET_CHILD_JOB_ID = "SET_CHILD_JOB_ID";
export const SET_CONTAINER_FORM_DATA = "SET_CONTAINER_FORM_DATA";
export const SET_SELECTED_ISSUE = "SET_SELECTED_ISSUE";
export const SET_MEDIA = "SET_MEDIA";
export const SET_MEDIA_EDIT_MODE = "SET_MEDIA_EDIT_MODE";
export const SET_PARENT_MEDIA_EDIT_MODE = "SET_PARENT_MEDIA_EDIT_MODE";
export const SET_PARENT_JOB = "SET_PARENT_JOB";
export const SET_MEDIA_SECTIONS = "SET_MEDIA_SECTIONS";
export const SET_NON_CHILD_JOB_ID = "SET_NON_CHILD_JOB_ID";
export const SET_JOB = "SET_JOB";
export const SET_JOB_ISSUES = "SET_JOB_ISSUES";
export const SET_JOB_ISSUES_EDIT_MODE = "SET_JOB_ISSUES_EDIT_MODE";
export const SET_ISSUE = "SET_ISSUE";
export const SET_ISSUE_EDIT_MODE = "SET_ISSUE_EDIT_MODE";
export const SET_PARENT_ISSUE_EDIT_MODE = "SET_PARENT_ISSUE_EDIT_MODE";
export const SET_CONTACT_PERSON = "SET_CONTACT_PERSON";
export const SET_CONTACTS_EDIT_MODE = "SET_CONTACTS_EDIT_MODE";
export const SET_FILE_IMAGE_PREVIEW = "SET_FILE_IMAGE_PREVIEW";
export const SET_CONTAINER = "SET_CONTAINER";
export const SET_CONTAINER_ID = "SET_CONTAINER_ID";
export const SET_CONTAINER_FIRMEN_ID = "SET_CONTAINER_FIRMEN_ID";
export const SET_JOB_ID = "SET_JOB_ID";
export const SET_JOB_PROGRESS = "SET_JOB_PROGRESS";
export const SET_JOB_FREE_FORMAT_TEXT = "SET_JOB_FREE_FORMAT_TEXT";
export const SET_JOB_STATUS_ID = "SET_JOB_STATUS_ID";
export const SET_CREATED_JOB_IMAGE = "SET_CREATED_JOB_IMAGE";
export const SET_UPDATED_JOB_FORM_DATA = "SET_UPDATED_JOB_FORM_DATA";
export const SET_UPDATED_CONTAINER_FORM_DATA =
  "SET_UPDATED_CONTAINER_FORM_DATA";
export const SHOW_CONTAINER_SAVED_SUCCESS_MESSAGE =
  "SHOW_CONTAINER_SAVED_SUCCESS_MESSAGE";
export const HIDE_CONTAINER_SAVED_SUCCESS_MESSAGE =
  "HIDE_CONTAINER_SAVED_SUCCESS_MESSAGE";
export const UPDATE_CONTAINER = "UPDATE_CONTAINER";
export const FETCH_JOB_ANONYMOUSLY = "FETCH_JOB_ANONYMOUSLY";
export const FETCH_JOB_ANONYMOUSLY_FAILED = "FETCH_JOB_ANONYMOUSLY_FAILED";
export const SET_JOB_FOR_ANONYMOUS_USER = "SET_JOB_FOR_ANONYMOUS_USER";
export const DOWNLOAD_JOB_FILE_ANONYMOUSLY = "DOWNLOAD_JOB_FILE_ANONYMOUSLY";
export const DOWNLOAD_JOB_FILE_ANONYMOUSLY_FAILED =
  "DOWNLOAD_JOB_FILE_ANONYMOUSLY_FAILED";
export const FETCH_SENDER_INFO = "FETCH_SENDER_INFO";
export const FETCH_SENDER_INFO_FAIL = "FETCH_SENDER_INFO_FAIL";
export const SET_SENDER_INFO = "SET_SENDER_INFO";

// jobList
export const APPROVE_JOB = "APPROVE_JOB";
export const CHECK_CONTAINER_STATUS = "CHECK_CONTAINER_STATUS";
export const COPY_JOB = "COPY_JOB";
export const COPY_JOB_FAIL = "COPY_JOB_FAIL";
export const COPY_CONTAINER = "COPY_CONTAINER";
export const COPY_CONTAINER_FAIL = "COPY_CONTAINER_FAIL";
export const EXCHANGE_JOB = "EXCHANGE_JOB";
export const EXCHANGE_JOB_FAIL = "EXCHANGE_JOB_FAIL";
export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const DELETE_CONTAINER_FAIL = "DELETE_CONTAINER_FAIL";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";
export const DOWNLOAD_JOB = "DOWNLOAD_JOB";
export const DOWNLOAD_JOB_FILE = "DOWNLOAD_JOB_FILE";
export const DOWNLOAD_JOB_FILE_FAIL = "DOWNLOAD_JOB_FILE_FAIL";
export const OPEN_CONVERTED_FILE_REPORT_HTML = "OPEN_CONVERTED_FILE_REPORT_HTML";
export const DOWNLOAD_CONVERTED_FILE_REPORT_BLOB = "DOWNLOAD_CONVERTED_FILE_REPORT_BLOB";
export const DOWNLOAD_ORIGINAL_REPORT_BLOB = "DOWNLOAD_ORIGINAL_REPORT_BLOB";
export const OPEN_ORIGINAL_REPORT_HTML = "OPEN_ORIGINAL_REPORT_HTML";
export const DOWNLOAD_REPORT_FILE_FAIL = "DOWNLOAD_REPORT_FILE_FAIL";
export const DOWNLOAD_REPORT_BY_TYPE = "DOWNLOAD_REPORT_BY_TYPE";
export const OPEN_REPORT_HTML = "OPEN_REPORT_HTML";
export const DOWNLOAD_REPORT_BLOB = "DOWNLOAD_REPORT_BLOB";
export const ENTER_JOB_LIST = "ENTER_JOB_LIST";
export const FETCH_CONTAINER_JOB_NOTES = "FETCH_CONTAINER_JOB_NOTES";
export const FETCH_CONTAINER_JOB_NOTES_FAIL = "FETCH_CONTAINER_JOB_NOTES_FAIL";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_FAIL = "FETCH_JOBS_FAIL";
export const FETCH_JOBS_BY_DATE = "FETCH_JOBS_BY_DATE";
export const FETCH_JOBS_BY_DATE_FAIL = "FETCH_JOBS_BY_DATE_FAIL";
export const FETCH_RECEIVER_JOBS = "FETCH_RECEIVER_JOBS";
export const FETCH_RECEIVER_JOBS_BY_DATE = "FETCH_RECEIVER_JOBS_BY_DATE";
export const FETCH_USER_PREFERENCES = "FETCH_USER_PREFERENCES";
export const FETCH_RECEIVER_USER_PREFERENCES =
  "FETCH_RECEIVER_USER_PREFERENCES";
export const FILTER_JOBS_BY_STATUS = "FILTER_JOBS_BY_STATUS";
export const FILTER_JOBS_BY_STATUS_SUCCESS = "FILTER_JOBS_BY_STATUS_SUCCESS";
export const FILTER_JOBS_BY_STATUS_FAIL = "FILTER_JOBS_BY_STATUS_FAIL";
export const FILTER_JOBS_BY_PUBLICATION_STATUS =
  "FILTER_JOBS_BY_PUBLICATION_STATUS";
export const FILTER_JOBS_BY_PUBLICATION_STATUS_SUCCESS =
  "FILTER_JOBS_BY_PUBLICATION_STATUS_SUCCESS";
export const FILTER_JOBS_BY_PUBLICATION_STATUS_FAIL =
  "FILTER_JOBS_BY_PUBLICATION_STATUS_FAIL";
export const FILTER_JOBS_BY_JOB_EXCHANGE = "FILTER_JOBS_BY_JOB_EXCHANGE";
export const FILTER_JOBS_BY_JOB_EXCHANGE_SUCCESS =
  "FILTER_JOBS_BY_JOB_EXCHANGE_SUCCESS";
export const FILTER_JOBS_BY_JOB_EXCHANGE_FAIL =
  "FILTER_JOBS_BY_JOB_EXCHANGE_FAIL";
export const FILTER_JOBS_BY_WORD = "FILTER_JOBS_BY_WORD";
export const FILTER_JOBS_BY_WORD_SUCCESS = "FILTER_JOBS_BY_WORD_SUCCESS";
export const FILTER_JOBS_BY_WORD_FAIL = "FILTER_JOBS_BY_WORD_FAIL";
export const FILTER_JOBS_BY_COLOR = "FILTER_JOBS_BY_COLOR";
export const FILTER_JOBS_BY_COLOR_SUCCESS = "FILTER_JOBS_BY_COLOR_SUCCESS";
export const FILTER_JOBS_BY_COLOR_FAIL = "FILTER_JOBS_BY_COLOR_FAIL";
export const FILTER_JOBS_BY_PREFLIGHT_STATUS =
  "FILTER_JOBS_BY_PREFLIGHT_STATUS";
export const FILTER_JOBS_BY_PREFLIGHT_STATUS_SUCCESS =
  "FILTER_JOBS_BY_PREFLIGHT_STATUS_SUCCESS";
export const FILTER_JOBS_BY_PREFLIGHT_STATUS_FAIL =
  "FILTER_JOBS_BY_PREFLIGHT_STATUS_FAIL";
export const FINISH_SENDING_CONTAINER = "FINISH_SENDING_CONTAINER";
export const FINISH_COPYING_CONTAINER = "FINISH_COPYING_CONTAINER";
export const HIDE_CONTAINER_SENT_SUCCESS_MESSAGE =
  "HIDE_CONTAINER_SENT_SUCCESS_MESSAGE";
export const LEAVE_JOB_LIST = "LEAVE_JOB_LIST";
export const NOTIFY_JOB_CHANGE = "NOTIFY_JOB_CHANGE";
export const RESET_ERROR = "RESET_ERROR";
export const RESET_JOB_LIST = "RESET_JOB_LIST";
export const RESET_RECEIVER_JOB_LIST = "RESET_RECEIVER_JOB_LIST";
export const SAVE_LABELED_JOB = "SAVE_LABELED_JOB";
export const SAVE_WORD_TAGS = "SAVE_WORD_TAGS";
export const SAVE_WORD_TAGS_SUCCESS = "SAVE_WORD_TAGS_SUCCESS";
export const SAVE_WORD_TAGS_FAIL = "SAVE_WORD_TAGS_FAIL";
export const SAVE_COLOR_TAGS = "SAVE_COLOR_TAGS";
export const SAVE_COLOR_TAGS_SUCCESS = "SAVE_COLOR_TAGS_SUCCESS";
export const SAVE_COLOR_TAGS_FAIL = "SAVE_COLOR_TAGS_FAIL";
export const SAVE_GRID_SETTINGS = "SAVE_GRID_SETTINGS";
export const SAVE_GRID_SETTINGS_SUCCESS = "SAVE_GRID_SETTINGS_SUCCESS";
export const SAVE_GRID_SETTINGS_FAIL = "SAVE_GRID_SETTINGS_FAIL";
export const SAVE_RECEIVER_GRID_SETTINGS = "SAVE_RECEIVER_GRID_SETTINGS";
export const SAVE_RECEIVER_GRID_SETTINGS_SUCCESS =
  "SAVE_RECEIVER_GRID_SETTINGS_SUCCESS";
export const SAVE_RECEIVER_GRID_SETTINGS_FAIL =
  "SAVE_RECEIVER_GRID_SETTINGS_FAIL";
export const START_COPYING_CONTAINER = "START_COPYING_CONTAINER";
export const SAVE_SENDER_GROUPING = "SAVE_SENDER_GROUPING";
export const SAVE_SENDER_GROUPING_SUCCESS = "SAVE_SENDER_GROUPING_SUCCESS";
export const SAVE_SENDER_GROUPING_FAIL = "SAVE_SENDER_GROUPING_FAIL";
export const SAVE_RECEIVER_GROUPING = "SAVE_RECEIVER_GROUPING";
export const SAVE_RECEIVER_GROUPING_SUCCESS = "SAVE_RECEIVER_GROUPING_SUCCESS";
export const SAVE_RECEIVER_GROUPING_FAIL = "SAVE_RECEIVER_GROUPING_FAIL";
export const SAVE_SENDER_SORTING = "SAVE_SENDER_SORTING";
export const SAVE_SENDER_SORTING_SUCCESS = "SAVE_SENDER_SORTING_SUCCESS";
export const SAVE_SENDER_SORTING_FAIL = "SAVE_SENDER_SORTING_FAIL";
export const SAVE_RECEIVER_SORTING = "SAVE_RECEIVER_SORTING";
export const SAVE_RECEIVER_SORTING_SUCCESS = "SAVE_RECEIVER_SORTING_SUCCESS";
export const SAVE_RECEIVER_SORTING_FAIL = "SAVE_RECEIVER_SORTING_FAIL";
export const SEARCH_JOBS = "SEARCH_JOBS";
export const SEARCH_JOBS_FAIL = "SEARCH_JOBS_FAIL";
export const SEARCH_RECEIVER_JOBS = "SEARCH_RECEIVER_JOBS";
export const SEND_JOB_FAIL = "SEND_JOB_FAIL";
export const SET_COPY_CONTAINER_SUCCESS = "SET_COPY_CONTAINER_SUCCESS";
export const SET_EXCHANGE_JOB_SUCCESS = "SET_EXCHANGE_JOB_SUCCESS";
export const SEND_CONTAINER_JOB_LIST = "SEND_CONTAINER_JOB_LIST";
export const SEND_CONTAINER_JOB_LIST_FAIL = "SEND_CONTAINER_JOB_LIST_FAIL";
export const SET_JOBS = "SET_JOBS";
export const SET_JOBS_BY_DATE = "SET_JOBS_BY_DATE";
export const SET_RECEIVER_JOBS_BY_DATE = "SET_RECEIVER_JOBS_BY_DATE";
export const SET_JOB_AS_RECEIVED = "SET_JOB_AS_RECEIVED";
export const SET_JOB_AS_RECEIVED_FAIL = "SET_JOB_AS_RECEIVED_FAIL";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_JOBS_FROM_LOCAL = "SET_JOBS_FROM_LOCAL";
export const SET_JOBLIST_NOTES = "SET_JOBLIST_NOTES";
export const START_SENDING_CONTAINER = "START_SENDING_CONTAINER";
export const TAG_JOB = "TAG_JOB";
export const TAG_JOB_FAIL = "TAG_JOB_FAIL";
export const UPDATE_JOBS = "UPDATE_JOBS";
export const FILTER_BY_MEDIUM = "FILTER_BY_MEDIUM";
export const FILTER_BY_MEDIUM_SUCCESS = "FILTER_BY_MEDIUM_SUCCESS";
export const FILTER_BY_MEDIUM_FAIL = "FILTER_BY_MEDIUM_FAIL";
export const FILTER_BY_ISSUE = "FILTER_BY_ISSUE";
export const FILTER_BY_ISSUE_SUCCESS = "FILTER_BY_ISSUE_SUCCESS";
export const FILTER_BY_ISSUE_FAIL = "FILTER_BY_ISSUE_FAIL";
export const RESET_JOBLIST_FILTERS = "RESET_JOBLIST_FILTERS";
export const SAVE_SENDER_DATETAG = "SAVE_SENDER_DATETAG";
export const SAVE_SENDER_DATETAG_SUCCESS = "SAVE_SENDER_DATETAG_SUCCESS";
export const SAVE_SENDER_DATETAG_FAIL = "SAVE_SENDER_DATETAG_FAIL";
export const SAVE_RECEIVER_DATETAG = "SAVE_RECEIVER_DATETAG";
export const SAVE_RECEIVER_DATETAG_SUCCESS = "SAVE_RECEIVER_DATETAG_SUCCESS";
export const SAVE_RECEIVER_DATETAG_FAIL = "SAVE_RECEIVER_DATETAG_FAIL";
export const SAVE_VIEW = "SAVE_VIEW";
export const SAVE_VIEW_SUCCESS = "SAVE_VIEW_SUCCESS";
export const SAVE_VIEW_FAIL = "SAVE_VIEW_FAIL";
export const CHANGE_SENDER_GRID_SORTING = "CHANGE_SENDER_GRID_SORTING";
export const CHANGE_RECEIVER_GRID_SORTING = "CHANGE_RECEIVER_GRID_SORTING";
export const CHANGE_SENDER_GRID_GROUPING = "CHANGE_SENDER_GRID_GROUPING";
export const CHANGE_RECEIVER_GRID_GROUPING = "CHANGE_RECEIVER_GRID_GROUPING";
export const FETCH_RECEIVER_JOB_CONTACT_INFO = "FETCH_RECEIVER_JOB_CONTACT_INFO";
export const FETCH_RECEIVER_JOB_CONTACT_INFO_FAIL = "FETCH_RECEIVER_JOB_CONTACT_INFO_FAIL";
export const FETCH_SENDER_JOB_CONTACT_INFO = "FETCH_SENDER_JOB_CONTACT_INFO";
export const FETCH_SENDER_JOB_CONTACT_INFO_FAIL = "FETCH_SENDER_JOB_CONTACT_INFO_FAIL";
export const SET_JOBLIST_CONTACT_INFO = "SET_JOBLIST_CONTACT_INFO";
export const REQUEST_SENT_EMAIL = "REQUEST_SENT_EMAIL";
export const REQUEST_CONFIRMATION_EMAIL_FAIL = "REQUEST_CONFIRMATION_EMAIL_FAIL";
export const REQUEST_CONFIRMATION_EMAIL_SUCCESS = "REQUEST_CONFIRMATION_EMAIL_SUCCESS";
export const REQUEST_RECEIVED_EMAIL = "REQUEST_RECEIVED_EMAIL";
export const HIDE_CONFIRMATION_EMAIL_SUCCESS_MESSAGE = "HIDE_CONFIRMATION_EMAIL_SUCCESS_MESSAGE";

// auth
export const AUTH = "AUTH";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const CHANGE_LANGUAGE_SETTING = "CHANGE_LANGUAGE_SETTING";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_DATA_FAIL = "FETCH_USER_DATA_FAIL";
export const FETCH_USER_DATA_AND_PROFILE = "FETCH_USER_DATA_AND_PROFILE";
export const FETCH_USER_PREFERENCES_FAIL = "FETCH_USER_PREFERENCES_FAIL";
export const RESET_AUTH = "RESET_AUTH";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_USER_PREFERENCES_AND_FETCH_JOBS =
  "SET_USER_PREFERENCES_AND_FETCH_JOBS";
export const SET_RECEIVER_USER_PREFERENCES_AND_FETCH_JOBS =
  "SET_RECEIVER_USER_PREFERENCES_AND_FETCH_JOBS";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_RECEIVER_USER_PREFERENCES = "SET_RECEIVER_USER_PREFERENCES";
export const SET_USER_DATA = "SET_USER_DATA";
export const REGISTERING_USER_WITH_COMPANY_TICKET =
  "REGISTER_USER_WITH_COMPANY_TICKET";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTERING_USER = "REGISTERING_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const CONFIRM_USER_REGISTRATION = "CONFIRM_USER_REGISTRATION";
export const USER_REGISTRATION_CONFIRMATION_SUCCESS =
  "USER_REGISTRATION_CONFIRMATION_SUCCESS";
export const USER_REGISTRATION_CONFIRMATION_FAIL =
  "USER_REGISTRATION_CONFIRMATION_FAIL";
export const SEND_PASSWORD_RESET_LINK_REQUEST =
  "SEND_PASSWORD_RESET_LINK_REQUEST";
export const SEND_PASSWORD_RESET_LINK_REQUEST_SUCCESS =
  "SEND_PASSWORD_RESET_LINK_REQUEST_SUCCESS";
export const SEND_PASSWORD_RESET_LINK_REQUEST_FAIL =
  "SEND_PASSWORD_RESET_LINK_REQUEST_FAIL";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_NEW_PASSWORD_WITH_CODE = "SET_NEW_PASSWORD_WITH_CODE";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAIL = "SET_NEW_PASSWORD_FAIL";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_NEWSLETTERS = "FETCH_USER_NEWSLETTERS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const FETCH_USER_PROFILE_FAIL = "FETCH_USER_PROFILE_FAIL";
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";
export const SAVE_USER_PROFILE_FAIL = "SAVE_USER_PROFILE_FAIL";
export const DELETE_USER_PROFILE = "DELETE_USER_PROFILE";         
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS"; 
export const DELETE_USER_PROFILE_FAIL = "DELETE_USER_PROFILE_FAIL"; 
export const HIDE_DELETE_USER_MESSAGE = "HIDE_DELETE_USER_MESSAGE"; 
export const HIDE_DELETE_USER_FAILER_MESSAGE="HIDE_DELETE_USER_FAILER_MESSAGE"; 

export const SAVE_USER_LANGUAGE = "SAVE_USER_LANGUAGE";
export const SAVE_USER_LANGUAGE_SUCCESS = "SAVE_USER_LANGUAGE_SUCCESS";
export const SAVE_USER_LANGUAGE_FAIL = "SAVE_USER_LANGUAGE_FAIL";
export const SAVE_USER_PROFILE_AND_PREFERENCES =
  "SAVE_USER_PROFILE_AND_PREFERENCES";
export const ACCEPT_FARB_UMWANDLUNG_OPTOUT ="ACCEPT_FARB_UMWANDLUNG_OPTOUT"; 
export const ACCEPT_FARB_UMWANDLUNG_OPTOUT_SUCCESS ="ACCEPT_FARB_UMWANDLUNG_OPTOUT_SUCCESS"; 
export const ACCEPT_FARB_UMWANDLUNG_OPTOUT_FAIL = "ACCEPT_FARB_UMWANDLUNG_OPTOUT_FAIL";
export const SAVE_USER_MEDIA_HINT_OPT_OUT = "SAVE_USER_MEDIA_HINT_OPT_OUT";
export const SAVE_USER_MEDIA_HINT_OPT_OUT_SUCCESS =
  "SAVE_USER_MEDIA_HINT_OPT_OUT_SUCCESS";
export const SAVE_USER_MEDIA_HINT_OPT_OUT_FAIL =
  "SAVE_USER_MEDIA_HINT_OPT_OUT_FAIL";
export const SEND_EMAIL_CONFIRMATION = "SEND_EMAIL_CONFIRMATION";
export const SEND_EMAIL_CONFIRMATION_SUCCESS =
  "SEND_EMAIL_CONFIRMATION_SUCCESS";
export const SEND_EMAIL_CONFIRMATION_FAIL = "SEND_EMAIL_CONFIRMATION_FAIL";
export const FETCH_COMPANY_INFO = "FETCH_COMPANY_INFO";
export const FETCH_COMPANY_INFO_FAIL = "FETCH_COMPANY_INFO_FAIL";
export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const FETCH_COMPANY_USERS = "FETCH_COMPANY_USERS";
export const FETCH_COMPANY_USERS_FAIL = "FETCH_COMPANY_USERS_FAIL";
export const SET_COMPANY_USERS = "SET_COMPANY_USERS";
export const SEND_COMPANY_INVITATION = "SEND_COMPANY_INVITATION";
export const SEND_COMPANY_INVITATION_SUCCESS =
  "SEND_COMPANY_INVITATION_SUCCESS";
export const SEND_COMPANY_INVITATION_FAIL = "SEND_COMPANY_INVITATION_FAIL";
export const ACCEPT_COMPANY_CHANGE = "ACCEPT_COMPANY_CHANGE";
export const ACCEPT_COMPANY_CHANGE_SUCCESS = "ACCEPT_COMPANY_CHANGE_SUCCESS";
export const ACCEPT_COMPANY_CHANGE_FAIL = "ACCEPT_COMPANY_CHANGE_FAIL";
export const FETCH_ASSIGNED_MEDIA = "FETCH_ASSIGNED_MEDIA";
export const SET_ASSIGNED_MEDIA = "SET_ASSIGNED_MEDIA";
export const UPDATE_ASSIGNED_MEDIA = "UPDATE_ASSIGNED_MEDIA";
export const UPDATE_USER_WITH_ASSIGNED_MEDIA_COUNT =
  "UPDATE_USER_WITH_ASSIGNED_MEDIA_COUNT";
export const FETCH_ASSIGNED_MEDIA_FAIL = "FETCH_ASSIGNED_MEDIA_FAIL";
export const FETCH_ASSIGNED_USER_FOR_MEDIA = "FETCH_ASSIGNED_USER_FOR_MEDIA";
export const SET_ASSIGNED_USERS_FOR_MEDIA = "SET_ASSIGNED_USERS_FOR_MEDIA";
export const UPDATE_ASSIGNED_USER_FOR_MEDIA = "UPDATE_ASSIGNED_USER_FOR_MEDIA";
export const FETCH_ASSIGNED_USER_FOR_MEDIA_FAIL =
  "FETCH_ASSIGNED_USER_FOR_MEDIA_FAIL";
export const SAVE_COMPANY_ADMIN = "SAVE_COMPANY_ADMIN";
export const UPDATE_COMPANY_ADMIN = "UPDATE_COMPANY_ADMIN";
export const SAVE_COMPANY_ADMIN_FAIL = "SAVE_COMPANY_ADMIN_FAIL";
export const SAVE_ASSIGNED_MEDIA_SETTING = "SAVE_ASSIGNED_MEDIA_SETTING";
export const SAVE_ASSIGNED_MEDIA_SETTING_PER_MEDIA =
  "SAVE_ASSIGNED_MEDIA_SETTING_PER_MEDIA";
export const SAVE_ASSIGNED_MEDIA_SETTING_PER_USER =
  "SAVE_ASSIGNED_MEDIA_SETTING_PER_USER";
export const SAVE_ASSIGNED_MEDIA_SETTING_FAIL =
  "SAVE_ASSIGNED_MEDIA_SETTING_FAIL";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const FETCH_CURRENT_VERSION = "FETCH_CURRENT_VERSION";
export const SET_CURRENT_VERSION = "SET_CURRENT_VERSION";
export const FETCH_PORTAL_STATUS = "FETCH_PORTAL_STATUS";
export const SET_PORTAL_STATUS = "SET_PORTAL_STATUS";
export const FETCH_PORTAL_MESSAGE = "FETCH_PORTAL_MESSAGE";
export const SET_PORTAL_MESSAGE = "SET_PORTAL_MESSAGE";
export const FETCH_PORTAL_MESSAGE_SPECIAL = "FETCH_PORTAL_MESSAGE_SPECIAL";
export const SET_PORTAL_MESSAGE_SPECIAL = "SET_PORTAL_MESSAGE_SPECIAL";
export const CERTIFY_MEDIUM = "CERTIFY_MEDIUM";
export const SET_CERTIFIED_MEDIUM_RESULT = "SET_CERTIFIED_MEDIUM_RESULT";
export const SEND_CHANGE_REQUEST = "SEND_CHANGE_REQUEST";
export const FETCH_CURRENT_APP_ENVIRONMENT = "FETCH_CURRENT_APP_ENVIRONMENT";
export const SET_CURRENT_APP_ENVIRONMENT = "SET_CURRENT_APP_ENVIRONMENT";
export const SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN =
  "SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN";
export const SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_SUCCESS =
  "SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_SUCCESS";
export const SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_FAIL =
  "SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN_FAIL";
export const LEAVE_SETTINGS = "LEAVE_SETTINGS";
export const SAVE_USER_NEWSLETTER_SUCCESS = "SAVE_USER_NEWSLETTER_SUCCESS";
export const SAVE_USER_NEWSLETTER_FAIL = "SAVE_USER_NEWSLETTER_FAIL";
export const SAVE_USER_NEWSLETTER = "SAVE_USER_NEWSLETTER";
export const POSTPONE_CERTIFICATION_ONE_MONTH =
  "POSTPONE_CERTIFICATION_ONE_MONTH";
export const POSTPONE_CERTIFICATION_ONE_MONTH_SUCCESS =
  "POSTPONE_CERTIFICATION_ONE_MONTH_SUCCESS";
export const POSTPONE_CERTIFICATION_ONE_MONTH_FAIL =
  "POSTPONE_CERTIFICATION_ONE_MONTH_FAIL";
export const SEND_CERTIFICATION_REQUEST = "SEND_CERTIFICATION_REQUEST";

// job history
export const FETCH_JOB_HISTORY = "FETCH_JOB_HISTORY";
export const FETCH_JOB_HISTORY_FAILED = "FETCH_JOB_HISTORY_FAILED";
export const SET_JOB_HISTORY = "SET_JOB_HISTORY";

// release overlay
export const HIDE_RELEASE_BANNER = "HIDE_RELEASE_BANNER";
export const HIDE_RELEASE_BANNER_SUCCESS = "HIDE_RELEASE_BANNER_SUCCESS";
export const HIDE_RELEASE_BANNER_FAIL = "HIDE_RELEASE_BANNER_FAIL";

// Accept new AGB
export const ACCEPT_NEW_AGB = "ACCEPT_NEW_AGB";
export const UPDATE_USER_AGB_VALIDITY = "UPDATE_USER_AGB_VALIDITY";

// Accept FARB_UMWANDLUNG
export const ACCEPT_FARB_UMWANDLUNG = "ACCEPT_FARB_UMWANDLUNG";
