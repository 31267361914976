import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Translate from "../../../hoc/Locale/Translate";
import { Modal } from "../../UI";
import { Button, Label, FormGroup, Form, Input } from "reactstrap";
import classes from "./CertificationRequestModal.module.css";

class CertificationRequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        checkbox: "",
        rechnungAdress: ""
      },
      validate: {
        checkboxState: "",
        rechnungAdressState: ""
      }
    };
  }

  componentDidMount() {
    this.props.onInitUserProfile();
  }

  onDownloadTermsOfUse = (e) => {
    const { appLanguage, onDownloadFile } = this.props;

    e.preventDefault();

    onDownloadFile(
      appLanguage === "en"
        ? "assets/pdf/DUON CERTIFIED PROGRAM TERMS OF USE.pdf"
        : "assets/pdf/20220401 DUON CERTIFIED PROGRAM Nutzungsbedingungen.pdf",
      "pdf",
      appLanguage === "en"
        ? "DUON CERTIFIED PROGRAM TERMS OF USE"
        : "20220401 DUON CERTIFIED PROGRAM Nutzungsbedingungen"
    );
  };

  onSubmitRequest = (event) => {
    event.preventDefault();
    this.props.onHandleFinishRequest();
    this.props.onHandleSending(event, this.state.values);
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState(prevState => ({
      values: {                   
          ...prevState.values,    
          [name]: value,      
      }
    }));
  };

  render() {
    const {
      appLanguage,
      name,
      showModal,
      companyInfo,
      userInfo,
      onHandleCancel,
    } = this.props;

    const { values } = this.state;

    return (

      <Modal
        onHideModal={onHandleCancel}
        isOpen={showModal}
        modalHeader={
          <div>
            <FontAwesome name="comments" />
            &nbsp;
            <span>DUON-CERTIFIED-PROGRAM</span>
          </div>
        }
        modalBody={
          <Form onSubmit={this.onSubmitRequest}>
            <div>
              <div className={classes.CompanySection}>
                <div className={classes.CompanyIcon}>
                  <FontAwesome name="address-card" size="5x" />
                </div>
                <div className={classes.CompanyInfo}>
                  {companyInfo && (
                    <div>
                      <div>
                        <b>{companyInfo.organisationName}</b>
                      </div>
                      <div>{companyInfo.organisationStrasseHNr}</div>
                      <div>
                        {companyInfo.organisationPLZ}{" "}
                        {companyInfo.organisationOrt}
                      </div>
                      <div>{companyInfo.organisationLand}</div>
                    </div>
                  )}
                  <br />
                  {userInfo && (
                    <div>
                      <div>
                        <b>{name}</b>
                      </div>
                      <div>{userInfo.eMail}</div>
                      <div>
                        {userInfo.telefon
                          ? "tel:" +
                            userInfo.telefon.replace(/[-_ /]/g, "").toString()
                          : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <br />
              </div>
              <div>
                <FormGroup check>
                  <Input 
                      type="checkbox" 
                      name="checkbox"
                      required
                      className={classes.Checkbox}
                      value={values.checkbox}
                      onChange={this.handleChange}
                    />
                  <Label check>{" "}
                    <div className={classes.CheckBoxText}>
                      <Translate id="Certification.IAccept" />{" "}
                      <span
                        onClick={e => this.onDownloadTermsOfUse(e)}
                        className={classes.TermsOfUse}
                      >
                        <Translate id="Certification.TermsOfUse" />
                      </span>{" "}
                      {appLanguage === "en" ? (
                        <span>
                          and hereby apply for the chargeable certification for
                          the{" "}
                          <b>
                            DUON-CERTIFIED-PROGRAM for EUR 360 plus VAT p.a.
                          </b>
                          The certification is only valid for one calendar year
                          and will be extended if not canceled until 30.09. of
                          the year for another year. The recertification is
                          subject to further fulfillment of the requirements.
                        </span>
                      ) : (
                        <span>
                          und beantrage hiermit die kostenpflichtige
                          Zertifizierung für das{" "}
                          <b>
                            DUON-CERTIFIED-PROGRAM für 360 EUR zzgl. MwSt p.a..{" "}
                          </b>
                          Die Zertifizierung gilt jeweils nur für ein
                          Kalenderjahr und verlängert sich ohne eine Kündigung
                          bis zum 30.09. des Jahres um eine weiteres Jahr. Die
                          Rezertifizierung erfolgt vorbehaltlich der weiteren
                          Erfüllung der Vorrausetzungen.
                        </span>
                      )}
                    </div>
                  </Label>
                </FormGroup>
              </div>
              <br />
              <br />
              <div>
                <div>
                  <b>
                    <Translate id="Certification.BillingAddress" />
                  </b>
                </div>
                <div>

                <FormGroup>
                  <Input
                    id="rechnungAdress"
                    name="rechnungAdress"
                    required
                    value={values.rechnungAdress}
                    className={classes.TextArea}
                    type="textarea"
                    rows={8}
                    onChange={this.handleChange}
                  />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div>
              <FormGroup style={{ textAlign: "right" }}>
                <Button
                  onClick={onHandleCancel}
                  className={classes.SecondaryButton}
                >
                  <Translate id="General.Cancel" />
                </Button>
                &nbsp;
                <Button className={classes.PrimaryButton}>
                  <Translate id="general.Send" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLanguage: state.auth.appLanguage,
    email: state.auth.email,
    name: state.auth.name,
    company: state.auth.company,
    companyId: state.auth.companyId,
    companyInfo: state.auth.companyInfo,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitUserProfile: () => dispatch(actions.fetchUserProfile()),
    onDownloadFile: (filePath, fileType, fileName) =>
      dispatch(actions.downloadFile(filePath, fileType, fileName)),
  };
};

CertificationRequestModal.propTypes = {
  showModal: PropTypes.bool,
  onHandleSending: PropTypes.func,
  onHandleCancel: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationRequestModal);
