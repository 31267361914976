import { all, take } from "redux-saga/effects";
import API from "../../api";
import PrivateAPI from "../../privateApi";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { errorHandler } from "../../helpers/Logger/Logger";
import { delay } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const byOrder = (a, b) => {
  if (a.sortierung < b.sortierung) return -1;
  if (a.sortierung > b.sortierung) return 1;
  return 0;
};

const byMedia = (a, b) => {
  if (a.medium < b.mediumId) return -1;
  if (a.mediumId > b.mediumId) return 1;
  return 0;
};

export function* deleteContainerJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    if (action.jobId) {
      yield put(
        actions.checkContainerCanBeConvertedWithoutCurrentJob(action.jobId)
      );
      yield API.delete(deleteJobURL(action.containerId, action.jobId));
    }
     yield put(actions.checkContainerCanBeConverted());

    yield put(actions.checkContainerCanBeSent());
  } catch (error) {
    yield put(actions.deleteJobFail());
    yield logError(
      error,
      error.response,
      "deleteContainerJob",
      deleteJobURL(action.containerId, action.jobId)
    );
  }
}

export function* deleteChildJobsSaga(action) {
  try {
    let childJobs = action.container.filter(
      job =>
        job.isChild &&
        job.mediaItem.mediumId === action.mediumId &&
        job.order === action.parentOrder &&
        job.id === action.parentId
    );

    // get child jobs with no parents
    let childOrphanJobs = [];
    childJobs.forEach(childJob => {
      if (childJob.jobId) {
        let parentJob = action.container
          .filter(
            job =>
              job.chosenMD5Hash === childJob.parentIssueFormat &&
              job.order === childJob.order &&
              job.mediaItem.mediumId === childJob.mediaItem.mediumId
          )
          .reduce((arr, el) => el, null);

        if (!parentJob) {
          childOrphanJobs.push(childJob);
        }
      }
    });

    for (let job of childOrphanJobs) {
      yield API.delete(deleteJobURL(action.containerId, job.jobId));
    }
  } catch (error) {
    yield put(actions.deleteJobFail());
    yield logError(
      error,
      error.response,
      "deleteJob",
      deleteJobURL(action.containerId, action.jobId)
    );
  }
}

// Called when split format changes back to normal format.
export function* addParentOrphanJobsSaga(action) {
  try {
    let parentJobs = action.container.filter(job => {
      return job.hasChildren;
    });

    // get parent jobs with no children
    let parentOrphanJobs = [];
    parentJobs.forEach(parentJob => {
      if (parentJob.jobId) {
        let childJobs = action.container
          .filter(
            job =>
              job.parentIssueFormat === parentJob.chosenMD5Hash &&
              job.order === parentJob.order &&
              job.mediaItem.mediumId === parentJob.mediaItem.mediumId
          )
          .reduce((arr, el) => el, null);

        if (!childJobs) {
          parentOrphanJobs.push(parentJob);
        }
      }
    });

    // save the previous parent jobs as new normal Jobs.
    for (let job of parentOrphanJobs) {
      // delete old  Job if it has job Id
      if (job.jobId) {
        try {
          yield API.delete(deleteJobURL(action.containerId, job.jobId));
        } catch (error) {}
      }

      // re-save the deleted job with new values
      const response = yield API.post(
        containerJobURL(action.containerId),
        prepareNewJobData(job)
      );

      let jobId = response.data.id;
      if (!job.jobId) {
        yield put(actions.setJobId(jobId, job));
      } else {
        yield put(actions.setParentJob(jobId, job));
      }
    }
  } catch (error) {
    yield put(actions.deleteJobFail());
    yield logError(
      error,
      error.response,
      "deleteJob",
      deleteJobURL(action.containerId, action.jobId)
    );
  }
}

const populateContainerJob = (job, key, hasParent, parentKey) => {
  return {
    selectedIssue: {
      nummer: job.heftNummer,
      datum: job.erscheinungsTermin,
      duSchluss: job.duSchlussOffiziell,
      bezeichnungDE: job.technikInfo ? job.technikInfo.sonderheftBezeichnungDE : null,
      bezeichnungEN: job.technikInfo ? job.technikInfo.sonderheftBezeichnungEN : null
    },
    chosenMD5Hash: job.formatMD5Hash,
    chosenFormatText: job.formatBezeichnung,
    isFreeFormat: job.istFreiesFormat,
    freeFormatProperties:
      job.istFreiesFormat && job.technikInfo
        ? job.technikInfo.anzeigenFormat
        : null,
    jobId: job.id,
    jobProgress: job.duDateiNameOriginal ? 100 : null,
    stepNumber: job.duDateiNameOriginal
      ? job.statusId === 81 || job.statusId === 82
        ? 4
        : 2
      : 1,
    statusId: job.statusId,
    statusColor: null,
    preflightErgebnis: {
      ergebnisId: job.preflightErgebnisId,
      ergebnis: job.preflightErgebnis
    },
    id: key,
    displayNumber: "" + parseInt(job.sortierung, 10),
    order: job.sortierung,
    originalFileName: job.duDateiNameOriginal,
    createdJobImage: null,
    jobData: {
      partialBooking: {
        value: job.teilbelegung === 1 ? true : false,
        valid: true,
        touched: true,
        validation: {
          required: false
        }
      },
      motive: {
        value: job.motiv ? job.motiv : "",
        valid: true,
        touched: true,
        validation: {
          required: true,
          maxLength: 255
        }
      },
      notes: {
        value: job.anmerkungen ? job.anmerkungen : "",
        valid: true,
        touched: false,
        validation: {
          required: false,
          maxLength: 1000
        }
      }
    },
    isUploadingFile: false,
    isFileProofRequired: job.duDateiNameOriginal ? true : false,
    isJobValid: job.motiv && job.motiv.length > 0 ? true : false,
    hasChildren: false,
    parentJobId: null,
    parentId: hasParent ? parentKey : null,
    parentFormat: hasParent ? job.elternFormatMD5Hash : null,
    isChild: hasParent ? true : false,
    splitOrder: job.linksOderRechts && job.linksOderRechts === "L" ? 1 : 2,
    parentIssueFormat: hasParent ? job.chosenMD5Hash : null,
    isJobInEditMode: true,
    ausgetauschtDurchJobId: job.ausgetauschtDurchJobId
      ? job.ausgetauschtDurchJobId
      : null,
    austauschContainerId: job.austauschContainerId
      ? job.austauschContainerId
      : null,
    austauschFuerJobId: job.austauschFuerJobId ? job.austauschFuerJobId : null
  };
};

const populateContainerParentJob = (job, key) => {
  return {
    selectedIssue: {
      nummer: job.heftNummer,
      datum: job.erscheinungsTermin,
      duSchluss: job.duSchlussOffiziell
    },
    chosenMD5Hash: job.elternFormatMD5Hash,
    chosenFormatText: job.formatBezeichnung,
    isFreeFormat: job.istFreiesFormat,
    freeFormatProperties:
      job.istFreiesFormat && job.technikInfo
        ? job.technikInfo.anzeigenFormat
        : null,
    jobId: null,
    jobProgress: null,
    stepNumber: 1,
    statusId: null,
    statusColor: null,
    preflightErgebnis: {
      ergebnisId: null,
      ergebnis: null
    },
    id: key,
    displayNumber: "" + parseInt(job.sortierung, 10),
    order: job.sortierung,
    originalFileName: null,
    createdJobImage: null,
    jobData: {
      partialBooking: {
        value: job.teilbelegung === 1 ? true : false,
        valid: true,
        touched: true,
        validation: {
          required: false
        }
      },
      motive: {
        value: "",
        valid: true,
        touched: true,
        validation: {
          required: true,
          maxLength: 255
        }
      },
      notes: {
        value: "",
        valid: true,
        touched: false,
        validation: {
          required: false,
          maxLength: 1000
        }
      }
    },
    isUploadingFile: false,
    isFileProofRequired: false,
    isJobValid: false,
    hasChildren: true,
    isChild: false,
    mediumId: job.mediumId,
    erscheinungsTermin: job.erscheinungsTermin,
    isJobInEditMode: true,
    ausgetauschtDurchJobId: job.ausgetauschtDurchJobId,
    austauschContainerId: job.austauschContainerId,
    austauschFuerJobId: job.austauschFuerJobId
  };
};

export function* editJobBuilderSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(fetchContainerURL(action.containerId));
    let jobs = { ...response.data.auftraege };
    let jobsArray = Object.keys(jobs)
      .map(k => jobs[k])
      .sort(byOrder)
      .sort(byMedia);
    let parentJobs = [];

    let mediaIds = [];
    jobsArray.forEach(job => {
      mediaIds.push(job.mediumId);
    });

    let uniqueMediaIds = mediaIds.filter(onlyUnique);
    let modifiedMedia = uniqueMediaIds.map((medium, key) => {
      return {
        mediumId: medium,
        order: parseInt(key, 10) + 1,
        sectionId: parseInt(key, 10) + 1,
        displayNumber: "" + (parseInt(key, 10) + 1)
      };
    });

    yield put(actions.setContainerId(response.data.id));
    yield put(actions.setContainerFirmenId(response.data.firmenId));
    yield put(
      actions.setContainerFormData(
        response.data.kunde,
        response.data.produkt,
        response.data.anmerkungen
      )
    );
    yield put(actions.setMediaSections(modifiedMedia));

    let modifiedJobs = jobsArray.map(job => {
      if (job.elternFormatMD5Hash) {
        if (job.linksOderRechts === "L") {
          let parentJob = populateContainerParentJob(job, job.sortierung);
          parentJobs.push(parentJob);
        }

        return populateContainerJob(job, job.sortierung, true, job.sortierung);
      } else {
        return populateContainerJob(job, job.sortierung, false, null);
      }
    });

    // combine parent and child jobs if present for split format
    let allJobs = modifiedJobs.concat(parentJobs);
    yield put(actions.setContainer(allJobs));

    // fetch and set Info for each child split/non-split normal Job
    for (let job of response.data.auftraege) {
      let section = modifiedJobs
        .filter(jobSection => jobSection.jobId === job.id)
        .reduce((arr, el) => el, []);

      yield put(
        actions.fetchMediaEditMode(job.mediumId, null, section.id, job.id)
      );

      if (job.duDateiNameOriginal) {
        yield put(actions.fetchJobImage(job.id, section));
      }

      yield put(
        actions.fetchJobDatesEditMode(
          job.mediumId,
          job.erscheinungsTermin,
          job.formatMD5Hash,
          job,
          action.appLanguage
        )
      );
      yield put(actions.checkContainerIsSent());
    }

    // fetch and set Info for parent jobs in case of split format
    for (let parentJob of parentJobs) {
      yield put(
        actions.fetchParentMediaEditMode(
          parentJob.mediumId,
          parentJob.erscheinungsTermin,
          parentJob
        )
      );
      yield put(
        actions.fetchParentJobDatesEditMode(
          parentJob.mediumId,
          parentJob.erscheinungsTermin,
          parentJob,
          action.appLanguage
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchContainerFailed());
    yield logError(
      error,
      error.response,
      "editJobBuilder",
      fetchContainerURL(action.containerId)
    );
  }
}


export function* getJobContactPersonsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(fetchContainerURL(action.containerId));
    let jobs = { ...response.data.auftraege };
    let jobsArray = Object.keys(jobs)
      .map(k => jobs[k])
      .sort(byOrder)
      .sort(byMedia);
    let parentJobs = [];

    let mediaIds = [];
    jobsArray.forEach(job => {
      mediaIds.push(job.mediumId);
    });

/*     let uniqueMediaIds = mediaIds.filter(onlyUnique); */
/*     let modifiedMedia = uniqueMediaIds.map((medium, key) => {
      return {
        mediumId: medium,
        order: parseInt(key, 10) + 1,
        sectionId: parseInt(key, 10) + 1,
        displayNumber: "" + (parseInt(key, 10) + 1)
      };
    }); */

    //yield put(actions.setContainerId(response.data.id));
   /*  yield put(
      actions.setContainerFormData(
        response.data.kunde,
        response.data.produkt,
        response.data.anmerkungen
      )
    );
    yield put(actions.setMediaSections(modifiedMedia));
 */
    /* let modifiedJobs = jobsArray.map(job => {
      if (job.elternFormatMD5Hash) {
        if (job.linksOderRechts === "L") {
          let parentJob = populateContainerParentJob(job, job.sortierung);
          parentJobs.push(parentJob);
        }

        return populateContainerJob(job, job.sortierung, true, job.sortierung);
      } else {
        return populateContainerJob(job, job.sortierung, false, null);
      }
    }); */

    // combine parent and child jobs if present for split format
/*     let allJobs = modifiedJobs.concat(parentJobs);
    yield put(actions.setContainer(allJobs)); */

    // fetch and set Info for each child split/non-split normal Job
    for (let job of response.data.auftraege) {
/*       let section = modifiedJobs
        .filter(jobSection => jobSection.jobId === job.id)
        .reduce((arr, el) => el, []); */

     /*  yield put(
        actions.fetchMediaEditMode(job.mediumId, null, section.id, job.id)
      ); */

     /*  if (job.duDateiNameOriginal) {
        yield put(actions.fetchJobImage(job.id, section));
      } */

      yield put(
        actions.fetchJobDatesEditMode(
          job.mediumId,
          job.erscheinungsTermin,
          job.formatMD5Hash,
          job,
          action.appLanguage
        )
      );
/*       yield put(actions.checkContainerIsSent()); */
    }

    // fetch and set Info for parent jobs in case of split format
    for (let parentJob of parentJobs) {
      yield put(
        actions.fetchParentMediaEditMode(
          parentJob.mediumId,
          parentJob.erscheinungsTermin,
          parentJob
        )
      );
      yield put(
        actions.fetchParentJobDatesEditMode(
          parentJob.mediumId,
          parentJob.erscheinungsTermin,
          parentJob,
          action.appLanguage
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchContainerFailed());
    yield logError(
      error,
      error.response,
      "getJobContactPersonsSaga",
      fetchContainerURL(action.containerId)
    );
  }
}

export function* initJobBuilderSaga(action) {
  yield put(
    actions.fetchMedia(
      action.mediaId,
      action.datum,
      null,
      action.formatMD5Hash,
      action.appLanguage
    )
  );
}

export function* fetchMediaSaga(action) {
  try {
    const response = yield API.get(mediaURL(action.mediaId));
    yield put(actions.setMedia(action.mediaId, response.data));

    if (action.formatMD5Hash) {
      yield put(
        actions.fetchJobDates(
          action.mediaId,
          action.datum,
          null,
          action.formatMD5Hash,
          action.appLanguage
        )
      );
    } else if (action.datum === undefined) {
      yield put(
        actions.fetchJobIssues(
          action.mediaId,
          action.activeJobSectionId,
          action.appLanguage
        )
      );
    } else {
      yield put(
        actions.fetchJobDates(
          action.mediaId,
          action.datum,
          action.activeJobSectionId,
          null,
          action.appLanguage
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchMediaFailed());
    yield logError(
      error,
      error.response,
      "fetchMedia",
      mediaURL(action.mediaId)
    );
  }
}

export function* fetchMediaEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(mediaURL(action.mediaId));
    yield put(
      actions.setMediaEditMode(action.mediaId, response.data, action.jobId)
    );
  } catch (error) {
    yield put(actions.fetchMediaFailed());
    yield logError(
      error,
      error.response,
      "fetchMediaEditMode",
      mediaURL(action.mediaId)
    );
  }
}

export function* fetchParentMediaEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(mediaURL(action.mediaId));
    yield put(
      actions.setParentMediaEditMode(action.mediaId, response.data, action.job)
    );
  } catch (error) {
    yield put(actions.fetchMediaFailed());
    yield logError(
      error,
      error.response,
      "fetchParentMediaEditMode",
      mediaURL(action.mediaId)
    );
  }
}

export function* fetchJobIssuesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(issuesURL(action.mediaId));
    let selectedIssue = getDefaultIssue(response.data, null);
    yield put(
      actions.fetchTechnicalData(
        response.data,
        action.mediaId,
        selectedIssue,
        action.activeJobSectionId,
        action.appLanguage
      )
    );
  } catch (error) {
    yield put(actions.fetchJobIssuesFailed());
    logError(
      error,
      error.response,
      "fetchJobIssues",
      issuesURL(action.mediaId)
    );
  }
}

export function* fetchTechnicalDataSaga(action) {
  yield all([
    put(
      actions.setJobIssues(
        action.issues,
        action.mediaId,
        action.activeJobSectionId
      )
    ),
    put(
      actions.fetchTechnicalInfo(
        action.mediaId,
        action.selectedIssue,
        null,
        null,
        action.appLanguage
      )
    )
  ]);
}

export function* fetchJobDatesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(datesURL(action.mediaId));
    let selectedIssue = getDefaultIssue(response.data, action.datum);
    yield put(actions.setJobIssues(response.data, action.mediaId));
    yield put(
      actions.fetchTechnicalInfo(
        action.mediaId,
        selectedIssue,
        action.activeJobSectionId,
        action.formatMD5Hash,
        action.appLanguage
      )
    );
  } catch (error) {
    yield put(actions.fetchJobIssuesFailed());
    yield logError(
      error,
      error.response,
      "fetchJobDates",
      datesURL(action.mediaId)
    );
  }
}

export function* fetchJobDatesEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(datesURL(action.mediaId));
    let selectedIssue = getDefaultIssue(response.data, action.datum);
    yield put(actions.setJobIssuesEditMode(response.data, action.mediaId));
    yield put(
      actions.fetchTechnicalInfoEditMode(
        action.mediaId,
        selectedIssue,
        action.selectedFormat,
        action.job,
        action.appLanguage
      )
    );
  } catch (error) {
    yield put(actions.fetchJobIssuesFailed());
    yield logError(
      error,
      error.response,
      "fetchJobDatesEditMode",
      datesURL(action.mediaId)
    );
  }
}

export function* fetchParentJobDatesEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(datesURL(action.mediaId));
    let selectedIssue = getDefaultIssue(response.data, action.datum);
    yield put(actions.setJobIssuesEditMode(response.data, action.mediaId));
    yield put(
      actions.fetchParentTechnicalInfoEditMode(
        action.mediaId,
        selectedIssue,
        action.job,
        action.appLanguage
      )
    );
  } catch (error) {
    yield put(actions.fetchJobIssuesFailed());
    yield logError(
      error,
      error.response,
      "fetchJobDatesEditMode",
      datesURL(action.mediaId)
    );
  }
}

export function* fetchTechnicalInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      technicalInfoURL(
        action.mediaId,
        action.selectedIssue.datum,
        action.appLanguage
      )
    );
    let chosenMD5Hash = null;
    // if the user clicks on job erstellen from Media Details page and selected an issue format
    if (action.formatMD5Hash) {
      chosenMD5Hash = action.formatMD5Hash;
    }

    let selectedIssue = action.selectedIssue;
    selectedIssue.istTeilbelegungMoeglich =
      response.data.istTeilbelegungMoeglich;

    if(response.data.sonderheftBezeichnungDE) {
      selectedIssue.bezeichnungDE = response.data.sonderheftBezeichnungDE;
    }

    if(response.data.sonderheftBezeichnungEN) {
      selectedIssue.bezeichnungEN = response.data.sonderheftBezeichnungEN;
    }
      
    let issueFormats = sortNoBleedToTop(response.data.anzeigenFestFormate);
    yield put(
      actions.setIssue(
        issueFormats,
        chosenMD5Hash,
        selectedIssue,
        action.activeJobSectionId,
        action.mediaId,
        response.data.geometrie,
        response.data.bunddoppelungInnenteil,
        response.data.bunddoppelungUmschlag,
        action.appLanguage
      )
    );

    if (response.data.ansprechpartner) {
      yield put(
        actions.setContactPersonState(
          response.data.ansprechpartner.filter(item => item.typId === 601),
          action.mediaId
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchTechnicalInfoFailed());
    yield logError(
      error,
      error.response,
      "fetchTechnicalInfo",
      technicalInfoURL(
        action.mediaId,
        action.selectedIssue.datum,
        action.appLanguage
      )
    );
  }
}

export function* fetchTechnicalInfoEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let selectedIssue = action.selectedIssue;

    if (!selectedIssue) {
      selectedIssue = {
        nummer: action.job.heftNummer,
        datum: action.job.erscheinungsTermin,
        duSchluss: action.job.duSchlussOffiziell
      };
    }

    const response = yield API.get(
      technicalInfoURL(action.mediaId, selectedIssue.datum, action.appLanguage)
    );
    let issueFormats = sortNoBleedToTop(response.data.anzeigenFestFormate);
    if (selectedIssue) {
      selectedIssue.istTeilbelegungMoeglich =
        response.data.istTeilbelegungMoeglich;

      if(response.data.sonderheftBezeichnungDE) {
        selectedIssue.bezeichnungDE = response.data.sonderheftBezeichnungDE;
      }
  
      if(response.data.sonderheftBezeichnungEN) {
        selectedIssue.bezeichnungEN = response.data.sonderheftBezeichnungEN;
      }
    }
    yield put(
      actions.setIssueEditMode(
        issueFormats,
        action.selectedFormat,
        selectedIssue,
        action.mediaId,
        action.job,
        response.data.geometrie,
        response.data.bunddoppelungInnenteil,
        response.data.bunddoppelungUmschlag
      )
    );
    if (response.data.ansprechpartner) {
      yield put(
        actions.setContactsEditMode(
          response.data.ansprechpartner.filter(item => item.typId === 601),
          action.mediaId
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchTechnicalInfoFailed());
    let selectedIssue = action.selectedIssue;
    if (!selectedIssue) {
      selectedIssue = {
        nummer: action.job.heftNummer,
        datum: action.job.erscheinungsTermin,
        duSchluss: action.job.duSchlussOffiziell
      };
    }
    yield logError(
      error,
      error.response,
      "fetchTechnicalInfoEditMode",
      technicalInfoURL(action.mediaId, selectedIssue.datum, action.appLanguage)
    );
  }
}

export function* fetchParentTechnicalInfoEditModeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let selectedIssue = action.selectedIssue;
    if (!selectedIssue) {
      selectedIssue = {
        nummer: action.job.heftNummer,
        datum: action.job.erscheinungsTermin,
        duSchluss: action.job.duSchlussOffiziell
      };
    }
    const response = yield API.get(
      technicalInfoURL(action.mediaId, selectedIssue.datum, action.appLanguage)
    );
    let issueFormats = sortNoBleedToTop(response.data.anzeigenFestFormate);
    selectedIssue.istTeilbelegungMoeglich =
      response.data.istTeilbelegungMoeglich;
    yield put(
      actions.setParentIssueEditMode(
        issueFormats,
        selectedIssue,
        action.mediaId,
        action.job
      )
    );
  } catch (error) {
    let selectedIssue = action.selectedIssue;
    if (!selectedIssue) {
      selectedIssue = {
        nummer: action.job.heftNummer,
        datum: action.job.erscheinungsTermin,
        duSchluss: action.job.duSchlussOffiziell
      };
    }
    yield put(actions.fetchTechnicalInfoFailed());
    yield logError(
      error,
      error.response,
      "fetchTechnicalInfoEditMode",
      technicalInfoURL(action.mediaId, selectedIssue.datum, action.appLanguage)
    );
  }
}

export function* actualizeTechnicalInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let response = null;
    let isAnschnitt = null;
    const istTeilbelegung =
      action.activeJobSection.jobData.partialBooking &&
      action.activeJobSection.jobData.partialBooking.value;
    if (!action.activeJobSection.isFreeFormat) {
      response = yield API.get(
        technicalCheckURL(
          action.mediaId,
          action.selectedIssue.datum,
          action.formatMD5Hash,
          istTeilbelegung,
          action.appLanguage
        )
      );
      isAnschnitt =
        response.data.anzeigenFormat && response.data.anzeigenFormat !== []
          ? response.data.anzeigenFormat.anschnitt
          : 0;
    } else {
      let platzierung = 462;
      if (action.activeJobSection.freeFormat) {
        platzierung = action.activeJobSection.freeFormat.platzierungImHeft;
      }
      if (action.activeJobSection.freeFormatProperties) {
        platzierung =
          action.activeJobSection.freeFormatProperties.platzierungsId;
      }

      response = yield API.get(
        technicalCheckFreeFormatURL(
          action.mediaId,
          action.selectedIssue.datum,
          platzierung,
          action.appLanguage
        )
      );
      isAnschnitt = action.activeJobSection.freeFormat
        ? action.activeJobSection.freeFormat.istAnschnitt
        : 0;
    }

    let platzierungDruckverfahrenFarbprofile = null;
    if (response.data.platzierungenDruckverfahrenFarbprofile) {
      platzierungDruckverfahrenFarbprofile =
        response.data.platzierungenDruckverfahrenFarbprofile[0];
    } else {
      platzierungDruckverfahrenFarbprofile =
        response.data.platzierungDruckverfahrenFarbprofil;
    }

    let farbProfil =
      platzierungDruckverfahrenFarbprofile.farbprofil.bezeichnung;
    let farbProfilUri =
      platzierungDruckverfahrenFarbprofile.farbprofil.profilPaketUri;

    let technicalInfo = {
      heftverarbeitung: response.data.verarbeitung.bezeichnung,
      format: {
        format:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.bezeichnungDE
            : null,
        endFormat: {
          breite:
            response.data.anzeigenFormat && response.data.anzeigenFormat !== []
              ? response.data.anzeigenFormat.breite
              : null,
          hoehe:
            response.data.anzeigenFormat && response.data.anzeigenFormat !== []
              ? response.data.anzeigenFormat.hoehe
              : null
        },
        platzierungsId:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.platzierungsId
            : null,
        platzierung:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.platzierung
            : null,
        ueberBund:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.ueberBund
            : null,
        anschnitt: {
          oben: isAnschnitt ? response.data.beschnittZugaben.oben : 0,
          unten: isAnschnitt ? response.data.beschnittZugaben.unten : 0,
          links: isAnschnitt ? response.data.beschnittZugaben.links : 0,
          rechts: isAnschnitt ? response.data.beschnittZugaben.rechts : 0,
          einheit: response.data.beschnittZugaben.einheit
        },
        sicherheitsabstand: {
          oben:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          unten:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          links:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          rechts:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          einheit: response.data.verarbeitung.beschnittSicherheit.einheit
        }
      },
      profil: {
        farbProfil: farbProfil,
        farbProfilUri: farbProfilUri,
        maxFlaechendeckung:
          platzierungDruckverfahrenFarbprofile.farbprofil
            .maximaleFlaechendeckungProzent,
        mD5Hash: platzierungDruckverfahrenFarbprofile.farbprofil.mD5Hash,
        druckverfahren:
          platzierungDruckverfahrenFarbprofile.druckverfahren.bezeichnung,
        ErsterDruckenderTonNormal:
          platzierungDruckverfahrenFarbprofile.farbprofil.ersterDruckenderTonNormal,
        ErsterDruckenderTonTechn:
          platzierungDruckverfahrenFarbprofile.farbprofil.ersterDruckenderTonTechn,
        LetzterDifferenzierenderTon:
          platzierungDruckverfahrenFarbprofile.farbprofil.letzterDifferenzierenderTon
      },
      gueltigeDateiformate: response.data.gueltigeDateiformate,
      grenzwerte: {
        strichstaerken: {
          minStrichstaerkePositiv: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .positivSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .positiv4C
          },
          minStrichstaerkeNegativ: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .negativSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .negativ4C
          },
          einheit:
            platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
              .einheit
        },
        schriftGroessen: {
          minSchriftGroessenPositiv: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.positivSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.positiv4C
          },
          minSchriftGroessenNegativ: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.negativSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.negativ4C
          },
          einheit:
            platzierungDruckverfahrenFarbprofile.druckverfahren.schriftgroessen
              .einheit
        },
        aufloesung: {
          graustufen: {
            minimaleFarbGraustufenBildAufloesung:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.minimaleFarbGraustufenBildAufloesung,
            farbGraustufenBilderEmpfohlen:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.farbGraustufenBilderEmpfohlen,
            einheit:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.einheit
          },
          bitmapStrichBilder: {
            minimaleStrichbildAufloesung:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.minimaleStrichbildAufloesung,
            strichBilderEmpfohlen:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.strichBilderEmpfohlen,
            einheit:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.einheit
          }
        }
      },
      bunddoppelungUmschlag: response.data.bunddoppelungUmschlag,
      bunddoppelungInnenteil: response.data.bunddoppelungInnenteil,
      geometrie: {
        breite: response.data.geometrie.breite,
        hoehe: response.data.geometrie.hoehe,
        satzspiegelBreite: response.data.geometrie.satzspiegelBreite,
        satzspiegelHoehe: response.data.geometrie.satzspiegelHoehe,
        einheit: response.data.geometrie.einheit
      },
      formatMD5Hash: action.formatMD5Hash,
      parentFormatMD5Hash: action.parentFormatMD5Hash
    };

    yield put(actions.setActualizedTechnicalInfo(technicalInfo));
  } catch (error) {
    yield put(actions.actualizeTechnicalInfoFailed());
    const istTeilbelegung =
      action.activeJobSection.jobData.partialBooking &&
      action.activeJobSection.jobData.partialBooking.value;
    yield logError(
      error,
      error.response,
      "actualizeTechnicalInfo",
      technicalCheckURL(
        action.mediaId,
        action.selectedIssue.datum,
        action.formatMD5Hash,
        istTeilbelegung,
        action.appLanguage
      )
    );
  }
}

export function* createContainerOneJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.post(
      apiEndpoints.NEW_CONTAINER_ENDPOINT,
      action.formData
    );

    let jobs = response.data.auftraege;
    let jobId = jobs[0].id;

    yield put(actions.setContainerId(response.data.id));
    yield put(actions.setJobId(jobId, action.activeJobSection));
    yield put(actions.setJobFreeFormatText(jobId, action.activeJobSection, jobs[0].formatBezeichnung));
    yield put(actions.setJobProgress(jobId, action.activeJobSection));
    yield put(actions.setJobStatusId(jobId, action.activeJobSection));
    yield put(actions.saveFile(jobId, action.file, action.activeJobSection));
  } catch (error) {
    yield put(actions.createContainerOneJobFailed());
    yield logError(
      error,
      error.response,
      "createContainerOneJob",
      apiEndpoints.NEW_CONTAINER_ENDPOINT
    );
  }
}

export function* addFileToNewJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.post(
      containerJobURL(action.containerId),
      action.jobData
    );

    let jobId = response.data.id;
    let job = action.activeJobSection;

    if (response.data.elternFormatMD5Hash) {
      yield put(actions.setChildJobId(jobId, job));
    } else {
      yield put(actions.setNonChildJobId(jobId, job));
    }
    yield put(actions.setJobProgress(jobId, action.activeJobSection));
    yield put(actions.setJobStatusId(jobId, action.activeJobSection));
    yield put(actions.saveFile(jobId, action.file, action.activeJobSection));
  } catch (error) {
    yield put(actions.addFileToNewJobFailed());
    yield logError(
      error,
      error.response,
      "createContainerOneJob",
      apiEndpoints.NEW_CONTAINER_JOB_ENDPOINT
    );
  }
}

export function* saveNewJobWithoutFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.post(
      containerJobURL(action.containerId),
      action.jobData
    );

    let jobId = response.data.id;
    yield put(actions.setJobId(jobId, action.activeJobSection));
  } catch (error) {
    // ToDo: replace with new action specific for this function
    yield put(actions.addFileToNewJobFailed());
    yield logError(
      error,
      error.response,
      "saveNewJobWithoutFile",
      apiEndpoints.NEW_CONTAINER_JOB_ENDPOINT
    );
  }
}

export function* addFileToExistingJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.put(editJobURL(action.jobId), action.formData);
    yield put(actions.setJobId(action.jobId, action.activeJobSection));
    yield put(actions.setJobProgress(action.jobId, action.activeJobSection));
    yield put(actions.setJobStatusId(action.jobId, action.activeJobSection));
    yield put(
      actions.saveFile(action.jobId, action.file, action.activeJobSection)
    );
  } catch (error) {
    yield put(actions.addFileToExistingJobFailed());
    yield logError(
      error,
      error.response,
      "uploadAnotherFile",
      editJobURL(action.jobId)
    );
  }
}

export function* saveFileSaga(action) {
  try {
    let responseSAS = yield PrivateAPI.get(job_PDF_SAS_URL(action.jobId));

    //  let fileData = new FormData();
    //  fileData.append('uploadFile', action.file, action.file.name);

    yield fetch(responseSAS.data, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob"
      },
      body: action.file
    });

    yield PrivateAPI.post(proofFileURL(action.jobId), {
      originalerDateiname: action.file.name,
      webUIVersion: process.env.REACT_APP_Version
    });

    /*      const response = yield API.post(job_PDF_URL(action.jobId), fileData,  {
			headers: {
				'Content-Type': 'multipart/form-data'
      }}
    ); */

    yield put(
      actions.saveFileSuccess(action.file.name, action.activeJobSection)
    );
    yield put(
      actions.checkJobStatus(action.jobId, 1, 50, null, action.activeJobSection)
    );
  } catch (error) {
    yield put(actions.saveFileFailed());
    yield put(actions.toggleUploadComponent(action.activeJobSection));
    yield logError(
      error,
      error.response,
      "saveFile",
      job_PDF_URL(action.jobId)
    );
  }
}

export function* saveOriginalColorGMGFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    yield put(
      actions.saveColorGMGFileStart(action.jobId)
    );

    let filePDFUrl = null;
    let fileResponse = yield API.get(jobOriginalPDFUrl(action.jobId));
   
   if(fileResponse && fileResponse.data){ 
    filePDFUrl = fileResponse.data;

    const response = yield API.put("/ColorGMG/" + action.jobId + "/File/", {
      filePath: filePDFUrl
      
    });


    yield put(
      actions.saveColorGMGFileSuccess(action.jobId, response.data)
    );

    if(response && response.data && response.data){
      console.log("ColorGMG File Response");
      console.log(response.data);
      console.log(filePDFUrl);
      yield put(actions.saveColorGMGJob(action.jobId, response.data));
   }
  }
  } catch (error) {
    yield put(actions.saveColorGMGFileFailed());
    yield logError(
      error,
      error.response,
      "saveColorGMGFileSaga",
      job_PDF_URL(action.jobId)
    );
  }
}

export function* saveAllColorGMGFilesSaga (action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    for (const job of action.colorJobs) {
    
        yield put(
          actions.saveColorGMGFile(job.jobId)
        );
    }
  } 
  catch (error) {
    yield put(actions.saveColorGMGFileFailed());
    yield logError(
      error,
      error.response,
      "saveColorGMGFileSaga",
      job_PDF_URL(action.jobId)
    );
  }
}


export function* saveColorGMGFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    yield put(
      actions.saveColorGMGFileStart(action.jobId)
    );
    yield put(
      actions.checkContainerCanBeConvertedWithoutCurrentJob(action.jobId)
    );
    yield put(
      actions.checkJobHasErgebnisDownloads(action.jobId)
    );

    let filePDFUrl = null;
    let fileResponse = yield API.get(jobPDFUrl(action.jobId));
   
   if(fileResponse && fileResponse.data){ 
    filePDFUrl = fileResponse.data;

    const response = yield API.put("/ColorGMG/" + action.jobId + "/File/", {
      filePath: filePDFUrl
      
    });


    yield put(
      actions.saveColorGMGFileSuccess(action.jobId, response.data)
    );

    if(response && response.data && response.data){
      console.log("ColorGMG File Response");
      console.log(response.data);
      console.log(filePDFUrl);
      yield put(actions.saveColorGMGJob(action.jobId, response.data));
   }
  }
  } catch (error) {
    yield put(actions.saveColorGMGFileFailed());
    yield logError(
      error,
      error.response,
      "saveColorGMGFileSaga",
      job_PDF_URL(action.jobId)
    );
  }
}
export function* saveColorGMGJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    
    // ToDo: Fix the temporary file name, colorTemplateName and templateName
    const response = yield API.post("/ColorGMG/" + action.jobId + "/Job/", {
      fileId: action.fileId,
        colorTemplateId: "aaca8f3f-2b55-4b65-b4b5-468a961f0d9e",
      templateName: "Use Flattening plus PDF-X Output Intent Test",
      clientId: "DUON-UI"
    });

    if(response && response.data && response.data){
      console.log("ColorGMG Job Response");
      console.log(response.data);

      yield put(
        actions.saveColorGMGJobSuccess(action.jobId, response.data)
      );

      yield put(
        actions.getColorGMGJobResult(action.jobId, response.data)
      );
    }
  } catch (error) {
    yield put(actions.saveColorGMGJobFailed());
    yield logError(
      error,
      error.response,
      "saveColorGMGJobSaga",
      "/ColorGMG/" + action.jobId + "/Job/"
    );
  }
}
export function* getColorGMGJobResultSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    
    var colorGMGJobId = action.colorGMGJobId.replace("{", "").replace("}", "");

    const response = yield API.get("/ColorGMG/" + colorGMGJobId + "/Result/");

    if(response && response.data && response.data){
      console.log("ColorGMG Job Result");
      console.log(response);

      var fileId =response.data.replace("{", "").replace("}", "");
      yield put(
        actions.getColorGMGJobResultSuccess(action.jobId, fileId)
      );

      yield put(
        actions.getColorGMGConvertedFile(action.jobId,fileId)
      );
    }
  } catch (error) {
    yield put(actions.getColorGMGJobResultFailed());
    yield logError(
      error,
      error.response,
      "getColorGMGJobResultSaga",
      "/ColorGMG/" + action.colorGMGJobId.replace("{", "").replace("}", "") + "/Result/"
    );
  }
}
export function* getColorGMGConvertedFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    
    var fileId = action.fileId.replace("{", "").replace("}", "");
    const response = yield API.get("/ColorGMG/" + fileId + "/ConvertedFile/", { responseType: "blob" });
    console.log("fileId:" + fileId);
    
    if(response.data){
      yield put(actions.getColorGMGConvertedFileSuccess(action.jobId, response.data)); 
      yield put(actions.saveConvertedFile(action.jobId, response.data));

    }

  } catch (error) {
    yield put(actions.getColorGMGConvertedFileFailed());
    yield logError(
      error,
      error.response,
      "getColorGMGConvertedFileSaga",
      "/ColorGMG/" + action.fileId + "/ConvertedFile/"
    );
  }
}
export function* saveConvertedFileSaga(action) {
  try {
    let filePDFSASUrl = null;
    // ToDo: before copying the new pdf, we need to relocate the old pdf to another folder
    let responseSAS = yield PrivateAPI.get(job_PDF_SAS_URL(action.jobId) + "?isGmgConvertedFile=true");
   
    if(responseSAS && responseSAS.data){ 
     filePDFSASUrl = responseSAS.data;
    }
     console.log("ConvertedColorGMG File URL");
     console.log(filePDFSASUrl);
 

     yield fetch(responseSAS.data, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob"
      },
      body: action.file
    }); 
    // ToDo: Fix the temporary file name
     yield PrivateAPI.post(proofFileURL(action.jobId), {
      originalerDateiname: action.file.name + "gmg-converted-file.pdf", //todo: meinPdf.pdf (Originalname) => meinPdf-gmg-converted.pdf (Name nach Konvertierung + "-gmg-converted-pdf")
      

      webUIVersion: process.env.REACT_APP_Version
    }); 
    yield put(
      actions.saveConvertedColorGMGFileSuccess(action.jobId, "gmg-converted-file.pdf")
    );
    yield put(
      actions.checkConvertedColorGMGJobStatus(action.jobId, 1, 50, null)
    );
  } catch (error) {
    yield put(actions.saveConvertedColorGMGFileFailed());
    yield logError(
      error,
      error.response,
      "saveConvertedFile",
      job_PDF_SAS_URL(action.jobId)
    );
  }
}

export function* saveJobFinishSaga(action) {
  yield delay(2000);
  yield put(
    actions.finalizeJobUpload(action.statusId, action.activeJobSection)
  );
}

export function* saveConvertedColorGMGJobFinishSaga(action) {
  yield delay(2000);
  yield put(
    actions.finalizeConvertedColorGMGJobUpload(action.statusId, action.jobId)
  );
  yield put(
    actions.checkContainerCanBeConverted()
  );
}

export function* checkJobStatusSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let hasJobImage = action.hasJobImage ? action.hasJobImage : false;
    let retries = action.retries;
    let maxRetries = action.maxRetries;

    const response = yield API.get(jobStatusURL(action.jobId));
    if (
      !hasJobImage &&
      (response.data.statusId === 64 ||
        (response.data.statusId > 70 && response.data.statusId !== 74))
    ) {
      yield put(actions.fetchJobImage(action.jobId, action.activeJobSection));
      hasJobImage = true;
    }

    // if preflight not finished
    if (
      response.data.statusId !== 73 &&
      response.data.statusId !== 74 &&
      retries < maxRetries
    ) {
      retries++;
      yield delay(3000);
      yield put(
        actions.checkJobStatus(
          action.jobId,
          retries,
          maxRetries,
          hasJobImage,
          action.activeJobSection
        )
      );
    } else if (response.data.statusId === 74) {
      yield put(actions.checkJobStatusFileError(action.activeJobSection));
    } else if (response.data.statusId !== 73 && retries >= maxRetries) {
      yield put(actions.checkJobStatusFail(action.activeJobSection));
    } else if (response.data) {
      yield put(
        actions.statusCheckSuccess(response.data, action.activeJobSection)
      );
      yield put(
        actions.checkContainerCanBeConverted()
      );
   
      yield put(
        actions.saveJobFinish(response.data.statusId, action.activeJobSection)
      );
    }
  } catch (error) {
    yield put(actions.checkJobStatusFail(action.activeJobSection));
    yield logError(
      error,
      error.response,
      "checkJobStatus",
      jobStatusURL(action.jobId)
    );
  }
}

export function* checkConvertedColorGMGJobStatusSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let hasJobImage = action.hasJobImage ? action.hasJobImage : false;
    let retries = action.retries;
    let maxRetries = action.maxRetries;

    const response = yield API.get(jobStatusURL(action.jobId));
    if (
      !hasJobImage &&
      (response.data.statusId === 64 ||
        (response.data.statusId > 70 && response.data.statusId !== 74))
    ) {
      yield put(actions.fetchJobImage(action.jobId, null));
      hasJobImage = true;
    }

    // if preflight not finished
    if (
      response.data.statusId !== 73 &&
      response.data.statusId !== 74 &&
      retries < maxRetries
    ) {
      retries++;
      yield delay(3000);
      yield put(
        actions.checkConvertedColorGMGJobStatus(
          action.jobId,
          retries,
          maxRetries,
          hasJobImage
        )
      );
    } else if (response.data.statusId === 74) {
      yield put(actions.checkConvertedColorGMGJobStatusFileError(action.jobId));
    } else if (response.data.statusId !== 73 && retries >= maxRetries) {
      yield put(actions.checkConvertedColorGMGJobStatusFail(action.jobId));
    } else if (response.data) {
      yield put(
        actions.convertedColorGMGstatusCheckSuccess(response.data, action.jobId)
      );
      yield put(
        actions.saveConvertedColorGMGJobFinish(response.data.statusId, action.jobId)
      ); 
    }
  } catch (error) {
     yield put(actions.checkConvertedColorGMGJobStatusFail(action.jobId));
    yield logError(
      error,
      error.response,
      "checkConvertedColorGMGJobStatus",
      jobStatusURL(action.jobId)
    ); 
  }
}

export function* fetchJobImageSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(createdJobImageURL(action.jobId));
    yield put(actions.setCreatedJobImage(response.data, action.jobId));
  } catch (error) {
    yield put(actions.fetchJobImageFailed());
    yield logError(
      error,
      error.response,
      "fetchJobImage",
      createdJobImageURL(action.jobId)
    );
  }
}


export function* proofFileSaga(action) {
  try {
    yield put(
      actions.saveExistingContainer(
        action.containerJobs,
        action.containerFormData,
        action.containerId,
        action.containerMedia
      )
    );
    yield take("IS_SAVE_EXISTING_CONTAINER_COMPLETE");
    yield put(actions.proofFileStart(action.jobId, action.activeJobSection));

    yield PrivateAPI.post(proofFileURL(action.jobId), {
      originalerDateiname: action.activeJobSection.originalFileName,
      webUIVersion: process.env.REACT_APP_Version
    });

    yield put(actions.proofFileSuccess(action.jobId));
    yield put(
      actions.checkJobStatus(action.jobId, 1, 50, null, action.activeJobSection)
    );
  } catch (error) {
    yield put(actions.proofFileFail());
    logError(error, error.response, "proofFile", proofFileURL(action.jobId));
  }
}

export function* downloadReportSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      apiEndpoints.PDF_REPORT_LINK.replace("{id}", action.jobId),
      { responseType: "blob" }
    );

    let disposition = response.headers["content-disposition"];
    let filename = action.jobId + ".pdf";

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadReportFailed());
    yield logError(
      error,
      error.response,
      "downloadReport",
      apiEndpoints.PDF_REPORT_LINK.replace("{id}", action.jobId)
    );
  }
}


export function* downloadPDFSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    if (action.jobId !== null) {
      const response = yield API.get(
        apiEndpoints.FETCH_PDF_BLOB_SAS.replace("{id}", action.jobId)
      );
      let url = response.data;

      let filename = action.jobId + ".pdf";

      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } else {
      yield put(actions.downloadPDFFailed());
    }
  } catch (error) {
    yield put(actions.downloadPDFFailed());
    yield logError(
      error,
      error.response,
      "downloadPDF",
      apiEndpoints.JOB_PDF_ENDPOINT.replace("{id}", action.jobId)
    );
  }
}

const prepareNewJobData = currentSection => {
  return {
    mediumId: currentSection.mediaItem.mediumId,
    erscheinungsTermin: currentSection.selectedIssue.datum,
    motiv: currentSection.jobData.motive.value,
    anmerkungen: currentSection.jobData.notes.value,
    teilbelegung:
      currentSection.jobData.partialBooking &&
      currentSection.jobData.partialBooking.value
        ? 1
        : 0,
    sortierung: currentSection.order,
    formatMD5Hash:
      currentSection.chosenMD5Hash === "FF"
        ? null
        : currentSection.chosenMD5Hash
        ? currentSection.chosenMD5Hash
        : this.props.chosenMD5Hash,
    explizitesFormat:
      currentSection.isFreeFormat && currentSection.freeFormat
        ? currentSection.freeFormat
        : null
  };
};

const prepareNewChildJobData = currentSection => {
  return {
    mediumId: currentSection.mediaItem.mediumId,
    erscheinungsTermin: currentSection.selectedIssue.datum,
    motiv: currentSection.jobData.motive.value,
    anmerkungen: currentSection.jobData.notes.value,
    teilbelegung:
      currentSection.jobData.partialBooking &&
      currentSection.jobData.partialBooking.value
        ? 1
        : 0,
    sortierung: currentSection.order,
    linksOderRechts:
      currentSection.parentFormat && currentSection.splitOrder
        ? currentSection.splitOrder === 1
          ? "L"
          : "R"
        : null,
    elternFormatMD5Hash: currentSection.parentFormat
      ? currentSection.parentFormat
      : null,
    formatMD5Hash:
      currentSection.chosenMD5Hash === "FF"
        ? null
        : currentSection.chosenMD5Hash
        ? currentSection.chosenMD5Hash
        : this.props.chosenMD5Hash,
    explizitesFormat:
      currentSection.isFreeFormat && currentSection.freeFormat
        ? currentSection.freeFormat
        : null
  };
};

export function* saveExistingContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    // get jobs from state
    let auftraege = action.containerJobs.filter(job => {
      return job.jobId !== null;
    });

    let jobs = auftraege
      .filter(job => {
        return !job.hasChildren && job.jobId;
      })
      .map(job => {
        return {
          id: job.jobId,
          mediumId: job.mediaItem && job.mediaItem.mediumId,
          erscheinungsTermin: job.selectedIssue && job.selectedIssue.datum,
          duDateiNameOriginal: job.originalFileName,
          motiv: job.jobData.motive && job.jobData.motive.value,
          teilbelegung:
            job.jobData.partialBooking && job.jobData.partialBooking.value
              ? 1
              : 0,
          sortierung: job.order,
          anmerkungen: job.jobData.notes && job.jobData.notes.value,
          linksOderRechts:
            job.parentFormat && job.splitOrder
              ? job.splitOrder === 1
                ? "L"
                : "R"
              : null,
          elternFormatMD5Hash: job.parentFormat ? job.parentFormat : null,
          formatMD5Hash:
            (job.chosenMD5Hash && job.chosenMD5Hash !== "FF")
              ? job.chosenMD5Hash
              : null,
          explizitesFormat:
            job.isFreeFormat && job.freeFormat ? job.freeFormat : null
        };
      });

    // if normal format changes to split format - delete old changed jobs
    let parentJobs = action.containerJobs.filter(job => {
      return job.hasChildren;
    });
    for (let parentJob of parentJobs) {
      if (parentJob.jobId) {
        yield API.delete(deleteJobURL(action.containerId, parentJob.jobId));
      }
    }

    // save container information
    let containerFormData = {
      anmerkungen:
        action.containerFormData.notes && action.containerFormData.notes.value,
      kunde:
        action.containerFormData.clientName &&
        action.containerFormData.clientName.value,
      produkt:
        action.containerFormData.productName &&
        action.containerFormData.productName.value,
      auftraege: jobs
    };

    yield API.put(
      editContainerURL(action.containerId),
      JSON.stringify(containerFormData),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    // save new jobs and post them to DB
    let newJobs = action.containerJobs.filter(job => {
      return job.jobId === null;
    });

    for (let job of newJobs) {
      if (job.isChild) {
        const response = yield API.post(
          containerJobURL(action.containerId),
          prepareNewChildJobData(job)
        );

        let jobId = response.data.id;
        yield put(actions.setChildJobId(jobId, job));
      } else if (!job.hasChildren) {
        yield put(
          actions.saveNewJobWithoutFile(
            action.containerId,
            prepareNewJobData(job),
            job
          )
        );
      }
    }

    yield put(actions.showSavedSuccessMessage());
    yield put({ type: "IS_SAVE_EXISTING_CONTAINER_COMPLETE" });
  } catch (error) {
    yield put(actions.saveExistingContainerFailed(action.containerId));
    yield logError(
      error,
      error.response,
      "saveExistingContainer",
      editContainerURL(action.containerId)
    );
  }
}

export function* saveNewContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let parentJobs = [];
    // get jobs from state
    let auftraege = action.containerJobs
      .filter(job => {
        return !job.hasChildren;
      })
      .map(job => {
        return {
          id: job.jobId,
          mediumId: job.mediaItem && job.mediaItem.mediumId,
          erscheinungsTermin: job.selectedIssue && job.selectedIssue.datum,
          duDateiNameOriginal: job.originalFileName,
          motiv: job.jobData.motive && job.jobData.motive.value,
          teilbelegung:
            job.jobData.partialBooking && job.jobData.partialBooking.value
              ? 1
              : 0,
          sortierung: job.order,
          anmerkungen: job.jobData.notes && job.jobData.notes.value,
          linksOderRechts:
            job.splitOrder && job.parentIssueFormat
              ? job.splitOrder === 1
                ? "L"
                : "R"
              : null,
          elternFormatMD5Hash: job.parentIssueFormat
            ? job.parentIssueFormat
            : null,
          formatMD5Hash:
            job.chosenMD5Hash && job.chosenMD5Hash !== "FF"
              ? job.chosenMD5Hash
              : null,
          elternFormatBezeichnung: null,
          explizitesFormat:
            job.isFreeFormat && job.freeFormat ? job.freeFormat :null
        };
      });

    // save container info
    let containerFormData = {
      anmerkungen:
        action.containerFormData.notes && action.containerFormData.notes.value,
      kunde:
        action.containerFormData.clientName &&
        action.containerFormData.clientName.value,
      produkt:
        action.containerFormData.productName &&
        action.containerFormData.productName.value,
      auftraege: auftraege
    };

    const response = yield API.post(
      apiEndpoints.NEW_CONTAINER_ENDPOINT,
      containerFormData
    );

    yield put(actions.setContainerId(response.data.id));

    // re-populate state so  jobs include jobId and other info
    let jobs = response.data.auftraege;
    let modifiedJobs = jobs.map((job, key) => {
      let mediaItem = action.containerMedia
        .filter(media => {
          return media.mediumId === job.mediumId;
        })
        .reduce((arr, el) => {
          return el;
        }, []);

      // get parent job for split format cases
      let parentJob = null;
      if (job.elternFormatMD5Hash) {
        parentJob = action.containerJobs
          .filter(containerJob => {
            return (
              containerJob.chosenMD5Hash === job.elternFormatMD5Hash &&
              containerJob.order === job.sortierung &&
              containerJob.mediaItem.mediumId === job.mediumId
            );
          })
          .reduce((arr, el) => {
            return el;
          }, []);
      }
      if (parentJob && parentJob.mediaItem && job.linksOderRechts === "L") {
        parentJobs.push(parentJob);
      }

      return {
        mediaItem: mediaItem,
        mediaImage: mediaItem.mediaImage,
        selectedIssue: {
          nummer: job.heftNummer,
          datum: job.erscheinungsTermin,
          duSchluss: job.duSchlussOffiziell,
          istTeilbelegungMoeglich: job.technikInfo.istTeilbelegungMoeglich,
          bezeichnungDE: job.technikInfo ?  job.technikInfo.sonderheftBezeichnungDE : null,
          bezeichnungEN: job.technikInfo ?  job.technikInfo.sonderheftBezeichnungEN : null
        },
        issueFormats: mediaItem.issueFormats,
        issues: mediaItem.issues,
        chosenMD5Hash: job.formatMD5Hash,
        chosenFormatText: job.formatBezeichnung,
        isFreeFormat: job.istFreiesFormat,
        freeFormatProperties:
          job.istFreiesFormat && job.technikInfo
            ? job.technikInfo.anzeigenFormat
            : null,
        jobId: job.id,
        jobProgress: null,
        stepNumber: 1,
        statusId: null,
        statusColor: null,
        preflightErgebnis: null,
        id: parentJob && parentJob.id ? parentJob.id : parseInt(key, 10) + 1,
        displayNumber: "" + job.sortierung,
        order: job.sortierung,
        originalFileName: null,
        createdJobImage: null,
        jobData: {
          partialBooking: {
            value: job.teilbelegung === 1 ? true : false,
            valid: true,
            touched: true,
            validation: {
              required: false
            }
          },
          motive: {
            value: job.motiv ? job.motiv : "",
            valid: true,
            touched: true,
            validation: {
              required: true,
              maxLength: 255
            }
          },
          notes: {
            value: job.anmerkungen ? job.anmerkungen : "",
            valid: true,
            touched: false,
            validation: {
              required: false,
              maxLength: 1000
            }
          }
        },
        isUploadingFile: false,
        isFileProofRequired: false,
        isJobValid: job.motiv && job.motiv.length > 0 ? true : false,
        hasChildren: false,
        parentJobId: parentJob ? parentJob.jobId : null,
        parentId: parentJob ? parentJob.id : null,
        parentFormat: parentJob ? parentJob.chosenMD5Hash : null,
        isChild: parentJob ? true : false,
        splitOrder: job.linksOderRechts && job.linksOderRechts === "L" ? 1 : 2,
        parentIssueFormat: parentJob ? parentJob.chosenMD5Hash : null,
        isJobSaved: true,
        mediaBunddoppelungInnenteil: parentJob
          ? parentJob.mediaBunddoppelungInnenteil
          : null,
        mediaBunddoppelungUmschlag: parentJob
          ? parentJob.mediaBunddoppelungUmschlag
          : null
      };
    });

    // set flag hasChildren for parent jobs if present
    let modifiedParentJobs = [];
    if (parentJobs.length > 0) {
      modifiedParentJobs = parentJobs.map(parentJob => {
        return {
          ...parentJob,
          hasChildren: true
        };
      });
    }

    // combine parent jobs with jobs from API response
    let allJobs = modifiedParentJobs.concat(modifiedJobs);

    // save new state and success message
    yield put(actions.setContainer(allJobs));
    yield put(actions.showSavedSuccessMessage());
  } catch (error) {
    yield put(actions.saveNewContainerFailed());
    yield logError(
      error,
      error.response,
      "saveNewContainer",
      apiEndpoints.NEW_CONTAINER_ENDPOINT
    );
  }
}

export function* updateContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.put(
      editContainerURL(action.containerId),
      JSON.stringify(action.formData),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(actions.updateContainerSuccess());
    yield put(actions.updateJobSuccess());
  } catch (error) {
    yield put(actions.updateContainerFail());
    yield logError(
      error,
      error.response,
      "updateContainer",
      editContainerURL(action.containerId)
    );
  }
}

export function* createContainerManyJobsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    // get jobs from state
    let currentJobs = action.containerJobs.map(job => {
      return {
        id: job.jobId,
        mediumId: job.mediaItem && job.mediaItem.mediumId,
        erscheinungsTermin: job.selectedIssue && job.selectedIssue.datum,
        duDateiNameOriginal: job.originalFileName,
        motiv: job.jobData.motive && job.jobData.motive.value,
        teilbelegung:
          job.jobData.partialBooking && job.jobData.partialBooking.value
            ? 1
            : 0,
        sortierung: job.order,
        anmerkungen: job.jobData.notes && job.jobData.notes.value,
        formatMD5Hash:
          job.chosenMD5Hash && job.chosenMD5Hash !== "FF"
            ? job.chosenMD5Hash
            : null,
        explizitesFormat:
          job.isFreeFormat && job.freeFormat ? job.freeFormat : null
      };
    });

    // create new container with info and jobs except the job having pdf being uploaded
    let containerFormData = {
      anmerkungen:
        action.containerFormData.notes && action.containerFormData.notes.value,
      kunde:
        action.containerFormData.clientName &&
        action.containerFormData.clientName.value,
      produkt:
        action.containerFormData.productName &&
        action.containerFormData.productName.value,
      auftraege: currentJobs
    };

    const response = yield API.post(
      apiEndpoints.NEW_CONTAINER_ENDPOINT,
      containerFormData
    );
    yield put(actions.setContainerId(response.data.id));

    // get jobs from API response and re-populate state
    let jobs = response.data.auftraege;
    let sequenceKeys = 0;
    let modifiedJobs = jobs.map((job, key) => {
      let mediaItem = action.containerMedia
        .filter(media => media.mediumId === job.mediumId)
        .reduce((arr, el) => el, []);

      let newActiveSectionId = parseInt(sequenceKeys, 10) + 1;
      if (action.activeJobSectionId === newActiveSectionId) {
        newActiveSectionId = newActiveSectionId + 1;
        sequenceKeys = sequenceKeys + 2;
      } else {
        sequenceKeys = sequenceKeys + 1;
      }

      return {
        mediaItem: mediaItem,
        mediaImage: mediaItem.mediaImage,
        selectedIssue: {
          nummer: job.heftNummer,
          datum: job.erscheinungsTermin,
          duSchluss: job.duSchlussOffiziell
        },
        issueFormats: mediaItem.issueFormats,
        issues: mediaItem.issues,
        chosenMD5Hash: job.formatMD5Hash,
        jobId: job.id,
        jobProgress: null,
        stepNumber: 1,
        statusId: null,
        statusColor: null,
        preflightErgebnis: null,
        id: newActiveSectionId,
        displayNumber: "" + job.sortierung,
        order: job.sortierung,
        originalFileName: null,
        createdJobImage: null,
        jobData: {
          partialBooking: {
            value: job.teilbelegung === 1 ? true : false,
            valid: true,
            touched: true,
            validation: {
              required: false
            }
          },
          motive: {
            value: job.motiv ? job.motiv : "",
            valid: true,
            touched: true,
            validation: {
              required: true,
              maxLength: 255
            }
          },
          notes: {
            value: job.anmerkungen ? job.anmerkungen : "",
            valid: true,
            touched: false,
            validation: {
              required: false,
              maxLength: 1000
            }
          }
        },
        isUploadingFile: false,
        isFileProofRequired: false,
        isJobValid: job.motiv && job.motiv.length > 0 ? true : false,
        isJobSaved: true
      };
    });

    // combine jobs with the job having pdf file being uploaded
    modifiedJobs.push(action.activeJobSection);

    yield put(actions.setContainer(modifiedJobs));
    yield put(actions.toggleUploadComponent(action.activeJobSection));

    // upload pdf file for new job
    yield put(
      actions.addFileToNewJob(
        action.file,
        response.data.id,
        action.newJobForm,
        action.container,
        action.activeJobSection
      )
    );
  } catch (error) {
    yield put(actions.createContainerFailed());
    yield logError(
      error,
      error.response,
      "createContainerManyJobs",
      apiEndpoints.NEW_CONTAINER_ENDPOINT
    );
  }
}



// Called when file upload is triggered in a split format job
export function* createContainerManyJobsSplitSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    // filter state to get child jobs with no parents
    let childAndOrphanJobs = action.containerJobs.filter(job => {
      return !job.hasChildren || job.isChild;
    });

    let currentJobs = childAndOrphanJobs
      .filter(job => !job.hasChildren)
      .map(job => {
        return job.isChild
          ? prepareNewChildJobData(job)
          : prepareNewJobData(job);
      });

    // save new container with the children jobs
    let containerFormData = {
      anmerkungen:
        action.containerFormData.notes && action.containerFormData.notes.value,
      kunde:
        action.containerFormData.clientName &&
        action.containerFormData.clientName.value,
      produkt:
        action.containerFormData.productName &&
        action.containerFormData.productName.value,
      auftraege: currentJobs
    };

    const response = yield API.post(
      apiEndpoints.NEW_CONTAINER_ENDPOINT,
      containerFormData
    );

    yield put(actions.setContainerId(response.data.id));

    // re-populate state to inclue jobs with jobIds
    let jobs = response.data.auftraege;
    // set job ids
    for (let job of jobs) {
      // if job is child
      if (job.elternFormatMD5Hash) {
        // get corresponding container job
        let containerJob = action.container
          .filter(
            containerJob =>
              containerJob.mediaItem.mediumId === job.mediumId &&
              containerJob.order === job.sortierung &&
              containerJob.chosenMD5Hash === job.formatMD5Hash &&
              containerJob.splitOrder === (job.linksOderRechts === "L" ? 1 : 2)
          )
          .reduce((arr, el) => el, []);

        yield put(actions.setChildJobId(job.id, containerJob));
      } else {
        let containerJob = action.container
          .filter(
            containerJob =>
              containerJob.mediaItem.mediumId === job.mediumId &&
              containerJob.order === job.sortierung &&
              containerJob.chosenMD5Hash === job.formatMD5Hash &&
              !containerJob.hasChildren &&
              !containerJob.isChild
          )
          .reduce((arr, el) => el, []);

        yield put(actions.setNonChildJobId(job.id, containerJob));
      }
    }
    yield put(actions.toggleUploadComponent(action.activeJobSection));

    // upload pdf file to new split format job
    yield put(
      actions.addFileToNewJob(
        action.file,
        response.data.id,
        action.newJobForm,
        action.container,
        action.activeJobSection
      )
    );
  } catch (error) {
    yield put(actions.createContainerFailed());
    yield logError(
      error,
      error.response,
      "createContainerManyJobsSplit",
      apiEndpoints.NEW_CONTAINER_ENDPOINT
    );
  }
}

export function* sendContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield take("IS_SAVE_EXISTING_CONTAINER_COMPLETE");
    const response = yield API.get(sendContainerURL(action.containerId)); //, { withCredentials: true })
    yield put(actions.startSendingContainerForm());
    let auftraege = response.data.auftraege;
    if (auftraege.length > 0) {
      for (let job of auftraege) {
        yield put(actions.checkSentStatus(job.id, 1, 15));
      }
    }
  } catch (error) {
    yield put(actions.sendJobFailed());
    yield logError(
      error,
      error.response,
      "approveJob",
      sendContainerURL(action.containerId)
    );
  }
}

export function* checkSentStatusSaga(action) {
  try {
    let retries = action.retries;
    let maxRetries = action.maxRetries;

    const response = yield API.get(jobStatusURL(action.jobId));
    // if job is not sent
    if (response.data.statusId !== 81 && retries < maxRetries) {
      retries++;
      yield delay(2000);
      yield put(actions.jobCheckStart(action.jobId));
      yield put(actions.checkSentStatus(action.jobId, retries, maxRetries));
    } else if (response.data.statusId !== 81 && retries >= maxRetries) {
      //ToDo: write Fail method
      // yield put(actions.checkJobStatusFail(action.activeJobSectionId));
    } else {
      yield put(actions.jobCheckSuccess(response.data, action.jobId));
    }
  } catch (error) {
    yield logError(
      error,
      error.response,
      "checkJobStatus",
      jobStatusURL(action.jobId)
    );
  }
}

export function* fetchJobAnonymouslySaga(action) {
  try {
    const response = yield PrivateAPI.get(fetchJobAnonymouslyURL(action.code));
    yield put(actions.setJobForAnonymousUser(response.data, action.code));
  } catch (error) {
    yield put(actions.fetchJobAnonymouslyFailed());
    yield logError(
      error,
      error.response,
      "fetchJobAnonymously",
      fetchJobAnonymouslyURL(action.code)
    );
  }
}

export function* downloadJobFileAnonymouslySaga(action) {
  try {
    const response = yield PrivateAPI.get(
      downloadJobFileAnonymouslyURL(action.code, action.fileType),
      { responseType: "blob" }
    );

    let disposition = response.headers["content-disposition"];
    let filename = action.code + "." + action.fileType;

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadJobFileAnonymouslyFailed(error));
    yield logError(
      error,
      error.response,
      "downloadJobFileAnonymously",
      downloadJobFileAnonymouslyURL(action.code, action.fileType)
    );
  }
}

export function* fetchSenderInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.get(senderInfoURL(action.containerId));
    yield put(actions.setSenderInfo(action.containerId, response.data));
  } catch (error) {
    yield put(actions.fetchSenderInfoFail());
    yield logError(
      error,
      error.response,
      "fetchSenderInfo",
      senderInfoURL(action.containerId)
    );
  }
}

export function* proofContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield put(
      actions.saveExistingContainer(
        action.container,
        action.containerFormData,
        action.containerId,
        action.containerMedia
      )
    );
    yield take("IS_SAVE_EXISTING_CONTAINER_COMPLETE");
    let jobSections = { ...action.container };
    let jobSectionsArray = Object.keys(jobSections).map(k => jobSections[k]);
    for (let job of jobSectionsArray) {
      if (job.createdJobImage) {
        yield put(actions.proofFileStart(job.jobId, job));

        yield PrivateAPI.post(proofFileURL(job.jobId), {
          originalerDateiname: job.originalFileName,
          webUIVersion: process.env.REACT_APP_Version
        });

        yield put(actions.proofFileSuccess(job.jobId));
        yield put(actions.checkJobStatus(job.jobId, 1, 50, null, job));
      }
    }
  } catch (error) {
    yield put(actions.proofContainerFailed(action.containerId));
    yield logError(error, error.response, "proofContainer", "");
  }
}

export function* proofContainerWithoutColorGMGJobsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    for (let job of action.normalJobs) {
      if (job.createdJobImage) {
        yield put(actions.proofFileStart(job.jobId, job));

        yield PrivateAPI.post(proofFileURL(job.jobId), {
          originalerDateiname: job.originalFileName,
          webUIVersion: process.env.REACT_APP_Version
        });

        yield put(actions.proofFileSuccess(job.jobId));
        yield put(actions.checkJobStatus(job.jobId, 1, 50, null, job));
      }
    }
  } catch (error) {
    yield put(actions.proofContainerFailed(action.containerId));
    yield logError(error, error.response, "proofContainer", "");
  }
}

const sendContainerURL = value => {
  return apiEndpoints.SEND_JOB_ENDPOINT.replace("{id}", value);
};

const fetchContainerURL = containerId => {
  return apiEndpoints.CONTAINER_ENDPOINT.replace("{id}", containerId);
};

const mediaURL = mediaId => {
  return apiEndpoints.MEDIA_ENDPOINT.replace("{id}", mediaId);
};

const issuesURL = mediaId => {
  return apiEndpoints.ISSUES_ENDPOINT.replace("{id}", mediaId);
};

const datesURL = mediaItemId => {
  return apiEndpoints.DATES_ENDPOINT.replace("{id}", mediaItemId);
};

const technicalInfoURL = (mediaId, priceList, appLanguage) => {
  let url = apiEndpoints.TECHNICAL_INFO_ENDPOINT.replace("{id}", mediaId);
  url = url.replace("{sprache}", appLanguage);
  return url.replace("{datum}", priceList);
};

const technicalCheckURL = (
  mediaId,
  selectedIssueDate,
  formatMD5Hash,
  isPartialBooking,
  appLanguage
) => {
  let url = apiEndpoints.TECHNICAL_CHECK_ENDPOINT.replace("{id}", mediaId);
  url = url.replace("{datum}", selectedIssueDate);
  url = url.replace("{md5hash}", formatMD5Hash);
  url = url.replace("{istTeilbelegung}", isPartialBooking === true ? 1 : 0);
  url = url.replace("{sprache}", appLanguage);
  return url;
};

const technicalCheckFreeFormatURL = (
  mediaId,
  selectedIssueDate,
  platzierung,
  appLanguage
) => {
  let url = apiEndpoints.TECHNICAL_CHECK_FREE_FORMAT_ENDPOINT.replace(
    "{id}",
    mediaId
  );
  url = url.replace("{datum}", selectedIssueDate);
  url = url.replace("{platzierung}", platzierung);
  url = url.replace("{sprache}", appLanguage);
  return url;
};

const job_PDF_URL = jobId => {
  return apiEndpoints.JOB_PDF_ENDPOINT.replace("{id}", jobId);
};

const jobStatusURL = jobId => {
  return apiEndpoints.JOB_STATUS_ENDPOINT.replace("{id}", jobId);
};

const containerJobURL = containerId => {
  return apiEndpoints.NEW_CONTAINER_JOB_ENDPOINT.replace("{id}", containerId);
};

const deleteJobURL = (containerId, jobId) => {
  return apiEndpoints.DELETE_JOB_ENDPOINT.replace("{id}", containerId).replace(
    "{auftragsId}",
    jobId
  );
};

const editContainerURL = containerId => {
  return apiEndpoints.UPDATE_CONTAINER_ENDPOINT.replace("{id}", containerId);
};

const editJobURL = jobId => {
  return apiEndpoints.UPDATE_JOB_ENDPOINT.replace("{id}", jobId);
};

const proofFileURL = jobId => {
  return apiEndpoints.PROOF_FILE_ENDPOINT.replace("{id}", jobId);
};

const createdJobImageURL = jobId => {
  return apiEndpoints.PREVIEW_IMAGE_ENDPOINT.replace("{id}", jobId);
};

const fetchJobAnonymouslyURL = code => {
  return apiEndpoints.ANONYMOUS_JOB_DOWNLOAD_INFO_ENDPOINT.replace(
    "{code}",
    code
  );
};

const downloadJobFileAnonymouslyURL = (code, fileType) => {
  return apiEndpoints.DOWNLOAD_JOB_FILE_ANONYMOUSLY_ENDPOINT.replace(
    "{code}",
    code
  ).replace("{fileType}", fileType);
};

const senderInfoURL = containerId => {
  return apiEndpoints.SENDER_INFO_ENDPPOINT.replace("{id}", containerId);
};

const job_PDF_SAS_URL = jobId => {
  return apiEndpoints.UPLOAD_FILE_SAS.replace("{id}", jobId);
};

const jobPDFUrl = (jobId) => {
  return apiEndpoints.FETCH_PDF_BLOB_SAS.replace("{id}", jobId);
};


const jobOriginalPDFUrl = (jobId) => {
  return apiEndpoints.FETCH_ORIGINAL_PDF_BLOB_SAS.replace("{id}", jobId);
};

const getDefaultIssue = (data, datum) => {
  let defaultItem = null;
  if (datum !== null) {
    defaultItem = data.filter(item => item.datum === datum);
  } else if (data.constructor === Array && data.length === 1) {
    return data[0];
  } else {
    defaultItem = data
      .filter(item => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return today - new Date(item.datum) < 0;
      })
      .slice(0, 1);
  }

  return defaultItem.reduce((arr, el) => el, null);
};

const sortNoBleedToTop = formats => {
  return formats
    ? formats.sort((a, b) => {
        return b.anschnitt - a.anschnitt;
      })
    : [];
};
