import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import containerData from "./containerData";
import jobData from "./jobData";
import container from "./container";
import { has } from "lodash";
// import { has } from "lodash";

const initialState = {
  mediaItem: null,
  mediaImage: null,
  issues: null,
  contactPersons: null,
  containerId: null,
  isEditMode: false,
  containerFormData: containerData,
  selectedIssue: null,
  issueFormats: null,
  technicalInfo: null,
  container: container,
  selectedMedia: [],
  canSendContainer:false,
  canSaveContainer: true,
  isContainerBeingSent: false,
  isContainerSent: false,
  isContainerTouched: false,
  hasContainerWarning: false,
  showSuccessMessage: false,
  error: false,
  errorMessage: "",
  sender: null,
  isContainerSaved: false,
  canConvertContainer:false,

};

const leaveJobBuilder = (state, action) => {
  return updateObject(state, {
    ...initialState,
    container: [
      {
        mediaItem: null,
        mediaImage: null,
        selectedIssue: null,
        issueFormats: null,
        chosenMD5Hash: null,
        jobId: null,
        jobProgress: null,
        stepNumber: 1,
        statusId: null,
        statusColor: null,
        preflightErgebnis: null,
        id: 1,
        displayNumber: "1",
        order: 1,
        originalFileName: null,
        createdJobImage: null,
        jobData,
        isUploadingFile: false,
        isFileProofRequired: false,
        isJobValid: false,
        isJobSaved: false,
        ausgetauschtDurchJobId: null,
        austauschContainerId: null,
        austauschFuerJobId: null,
        hasErgebnisDownloads: false,
      }
    ]
  });
};

const setMedia = (state, action) => {
  const { mediaItem, mediaImage } = action;
  let modifiedContainer = null;
  let modifiedSelectedMedia = null;

  if (!state.mediaItem) {
    modifiedContainer = state.container.map(job => {
      if (!job.mediaItem) {
        job.mediaItem = mediaItem;
        job.mediaImage = mediaImage;
      }
      return job;
    });

    modifiedSelectedMedia = [
      {
        ...state.selectedMedia[0],
        ...mediaItem,
        mediaImage: mediaImage,
        order: 1
      }
    ];

    return updateObject(state, {
      mediaItem: mediaItem,
      mediaImage: mediaImage,
      container: modifiedContainer,
      selectedMedia: modifiedSelectedMedia
    });
  } else {
    modifiedSelectedMedia = state.selectedMedia.map(medium => {
      return medium.mediumId === mediaItem.mediumId
        ? updateObject(medium, {
            ...mediaItem,
            mediaImage: mediaImage
          })
        : medium;
    });

    return updateObject(state, {
      selectedMedia: modifiedSelectedMedia
    });
  }
};

const setMediaEditMode = (state, action) => {
  const { mediaImage, mediaItem, jobId } = action;
  let modifiedSelectedMedia = null;

  let modifiedContainer = state.container.map(job => {
    if (job.jobId === jobId) {
      job.mediaItem = mediaItem;
      job.mediaImage = mediaImage;
      job.isJobSaved = true;
    }
    return job;
  });

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediaItem.mediumId
      ? updateObject(medium, {
          ...mediaItem,
          mediaImage: mediaImage
        })
      : medium;
  });

  if (state.mediaItem === null) {
    return updateObject(state, {
      mediaItem: mediaItem,
      mediaImage: mediaImage,
      selectedMedia: modifiedSelectedMedia,
      container: modifiedContainer,
      isEditMode: true,
      isContainerSaved: true
    });
  } else {
    return updateObject(state, {
      selectedMedia: modifiedSelectedMedia,
      container: modifiedContainer,
      isEditMode: true,
      isContainerSaved: true
    });
  }
};

const setParentMediaEditMode = (state, action) => {
  const { order, chosenMD5Hash } = action.job;
  const { mediaItem, mediaImage } = action;
  let modifiedSelectedMedia = null;

  let modifiedContainer = state.container.map(job => {
    if (
      job.order === order &&
      job.chosenMD5Hash === chosenMD5Hash &&
      job.mediumId === mediaItem.mediumId
    ) {
      job.mediaItem = mediaItem;
      job.mediaImage = mediaImage;
    }
    return job;
  });

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediaItem.mediumId
      ? updateObject(medium, {
          ...mediaItem,
          mediaImage: mediaImage
        })
      : medium;
  });

  return state.mediaItem === null
    ? updateObject(state, {
        mediaItem: mediaItem,
        mediaImage: mediaImage,
        selectedMedia: modifiedSelectedMedia,
        container: modifiedContainer,
        isEditMode: true
      })
    : updateObject(state, {
        selectedMedia: modifiedSelectedMedia,
        container: modifiedContainer,
        isEditMode: true
      });
};

const setJobIssues = (state, action) => {
  const { issues, mediumId } = action;
  let modifiedSelectedMedia = null;

  if (!state.issues) {
    modifiedSelectedMedia = [
      {
        ...state.selectedMedia[0],
        issues: issues
      }
    ];

    return updateObject(state, {
      issues: issues,
      selectedMedia: modifiedSelectedMedia
    });
  } else {
    modifiedSelectedMedia = state.selectedMedia.map(medium => {
      return medium.mediumId === mediumId
        ? updateObject(medium, {
            issues: issues
          })
        : medium;
    });

    return updateObject(state, {
      selectedMedia: modifiedSelectedMedia
    });
  }
};

const setJobIssuesEditMode = (state, action) => {
  const { issues, mediumId } = action;
  let modifiedSelectedMedia = null;

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediumId
      ? updateObject(medium, {
          issues: issues
        })
      : medium;
  });

  return state.issues === null
    ? updateObject(state, {
        issues: issues,
        selectedMedia: modifiedSelectedMedia
      })
    : updateObject(state, {
        selectedMedia: modifiedSelectedMedia
      });
};

const setIssue = (state, action) => {
  const {
    issueFormats,
    chosenMD5Hash,
    selectedIssue,
    issueSizes,
    mediumId,
    activeJobSectionId,
    bunddoppelungInnenteil,
    bunddoppelungUmschlag,
    appLanguage
  } = action;
  let modifiedContainer = null;
  let modifiedSelectedMedia = null;

  if (!state.selectedIssue) {
    modifiedContainer = state.container.map(job => {
      if (!job.selectedIssue) {
        job.issueFormats = issueFormats;
        job.chosenMD5Hash = chosenMD5Hash;
        job.selectedIssue = selectedIssue;
        job.isFileProofRequired =
          job.jobId && job.createdJobImage ? true : false;
        job.fileImageSource = null;
        job.jobProgress = 100;
        job.stepNumber = job.jobId ? 2 : 1;
        job.isUploadingFile = false;
        job.mediaBunddoppelungInnenteil = bunddoppelungInnenteil;
        job.mediaBunddoppelungUmschlag = bunddoppelungUmschlag;

        if (action.chosenMD5Hash === "freies-format") {
          job.chosenMD5Hash = "FF";
          job.isFreeFormat = true;
          job.freeFormat = null;
          job.chosenFormatText =
            appLanguage === "en" ? "Free Format" : "Freies Format";
        }
      }
      return job;
    });

    modifiedSelectedMedia = [
      {
        ...state.selectedMedia[0],
        issueFormats: issueFormats,
        chosenMD5Hash: chosenMD5Hash,
        selectedIssue: selectedIssue,
        mediaBunddoppelungInnenteil: bunddoppelungInnenteil,
        mediaBunddoppelungUmschlag: bunddoppelungUmschlag
      }
    ];

    return updateObject(state, {
      container: modifiedContainer,
      selectedMedia: modifiedSelectedMedia,
      issueFormats: issueFormats,
      chosenMD5Hash: chosenMD5Hash,
      selectedIssue: selectedIssue,
      issueSizes: issueSizes
    });
  } else {
    modifiedContainer = state.container.map(job => {
      if (
        job.id === activeJobSectionId &&
        job.mediaItem.mediumId === mediumId
      ) {
        job.issueFormats = issueFormats;
        if (!job.hasChildren && !job.isChild) job.chosenMD5Hash = chosenMD5Hash;
        job.selectedIssue = selectedIssue;
        job.isFileProofRequired =
          job.jobId && job.createdJobImage ? true : false;
        job.fileImageSource = null;
        job.jobProgress = 100;
        job.stepNumber = job.jobId ? 2 : 1;
        job.isUploadingFile = false;
        job.mediaBunddoppelungInnenteil = bunddoppelungInnenteil;
        job.mediaBunddoppelungUmschlag = bunddoppelungUmschlag;
      } else if (job.mediaItem && job.mediaItem.mediumId === mediumId) {
        job.issueFormats = issueFormats;
      }
      if (action.chosenMD5Hash === "freies-format") {
        job.chosenMD5Hash = "FF";
        job.isFreeFormat = true;
        job.freeFormat = null;
        job.chosenFormatText =
          appLanguage === "en" ? "Free Format" : "Freies Format";
      }
      return job;
    });

    modifiedSelectedMedia = state.selectedMedia.map(medium => {
      return medium.mediumId === mediumId
        ? updateObject(medium, {
            issueFormats: issueFormats,
            chosenMD5Hash: chosenMD5Hash,
            selectedIssue: selectedIssue,
            issueSizes: issueSizes,
            mediaBunddoppelungInnenteil: bunddoppelungInnenteil,
            mediaBunddoppelungUmschlag: bunddoppelungUmschlag
          })
        : medium;
    });

    return updateObject(state, {
      container: modifiedContainer,
      selectedMedia: modifiedSelectedMedia,
      issueFormats: issueFormats
      //isEditMode: state.containerId ? true : false
    });
  }
};




//-------------------------------------------



const setIssueEditMode = (state, action) => {
  const {
    issueFormats,
    chosenMD5Hash,
    selectedIssue,
    mediumId,
    issueSizes,
    bunddoppelungInnenteil,
    bunddoppelungUmschlag
  } = action;
  const { id, elternFormatMD5Hash } = action.job;
  let modifiedContainer = null;
  let modifiedSelectedMedia = null;

  modifiedContainer = state.container.map(job => {
    if (job.jobId === id) {
      job.issueFormats = issueFormats;
      job.chosenMD5Hash = chosenMD5Hash;
      job.selectedIssue = selectedIssue;
      job.mediaBunddoppelungInnenteil = bunddoppelungInnenteil;
      job.mediaBunddoppelungUmschlag = bunddoppelungUmschlag;
    }
    return job;
  });

  // check if the edited job is of split type
  let firstNoSplitFormat = null;
  if (elternFormatMD5Hash) {
    let noSplitFormats = issueFormats.filter(
      format => !format.druckunterlageAufsplitten
    );

    if (noSplitFormats.length > 0) {
      firstNoSplitFormat = noSplitFormats[0];
    }
  }

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediumId
      ? updateObject(medium, {
          issueFormats: issueFormats,
          chosenMD5Hash: elternFormatMD5Hash
            ? firstNoSplitFormat.mD5Hash
            : chosenMD5Hash,
          selectedIssue: selectedIssue,
          issueSizes: issueSizes,
          mediaBunddoppelungInnenteil: bunddoppelungInnenteil,
          mediaBunddoppelungUmschlag: bunddoppelungUmschlag
        })
      : medium;
  });

  if (!state.selectedIssue) {
    return !elternFormatMD5Hash
      ? updateObject(state, {
          issueFormats: issueFormats,
          chosenMD5Hash: chosenMD5Hash,
          selectedIssue: selectedIssue,
          container: modifiedContainer,
          selectedMedia: modifiedSelectedMedia,
          issueSizes: issueSizes
        })
      : firstNoSplitFormat
      ? updateObject(state, {
          issueFormats: issueFormats,
          chosenMD5Hash: firstNoSplitFormat.mD5Hash,
          selectedIssue: selectedIssue,
          container: modifiedContainer,
          selectedMedia: modifiedSelectedMedia,
          issueSizes: issueSizes
        })
      : updateObject(state, {
          container: modifiedContainer,
          selectedMedia: modifiedSelectedMedia
        });
  }

  return updateObject(state, {
    container: modifiedContainer,
    selectedMedia: modifiedSelectedMedia
  });
};

const setParentIssueEditMode = (state, action) => {
  const { issueFormats, selectedIssue, mediumId } = action;
  const { mediaItem, chosenMD5Hash, hasChildren, order } = action.job;
  let modifiedContainer = null;
  let modifiedSelectedMedia = null;

  modifiedContainer = state.container.map(job => {
    if (
      job.order === order &&
      job.mediaItem.mediumId === mediaItem.mediumId &&
      job.chosenMD5Hash === chosenMD5Hash &&
      job.hasChildren &&
      hasChildren
    ) {
      job.issueFormats = issueFormats;
      job.chosenMD5Hash = chosenMD5Hash;
      job.selectedIssue = selectedIssue;
    }
    return job;
  });

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediumId
      ? updateObject(medium, {
          issueFormats: issueFormats,
          chosenMD5Hash: chosenMD5Hash,
          selectedIssue: selectedIssue
        })
      : medium;
  });

  return !state.selectedIssue
    ? updateObject(state, {
        issueFormats: issueFormats,
        chosenMD5Hash: chosenMD5Hash,
        selectedIssue: selectedIssue,
        container: modifiedContainer,
        selectedMedia: modifiedSelectedMedia
      })
    : updateObject(state, {
        container: modifiedContainer,
        selectedMedia: modifiedSelectedMedia
      });
};

const setContactPerson = (state, action) => {
  const { contactPersons, mediumId } = action;
  let modifiedSelectedMedia = null;

  if (!state.contactPersons) {
    modifiedSelectedMedia = [
      {
        ...state.selectedMedia[0],
        contactPersons: contactPersons
      }
    ];

    return updateObject(state, {
      contactPersons: contactPersons,
      selectedMedia: modifiedSelectedMedia
    });
  } else {
    modifiedSelectedMedia = state.selectedMedia.map(medium => {
      return medium.mediumId === mediumId
        ? updateObject(medium, {
            contactPersons: contactPersons
          })
        : medium;
    });

    return updateObject(state, {
      selectedMedia: modifiedSelectedMedia
    });
  }
};

const setContactsEditMode = (state, action) => {
  const { contactPersons, mediumId } = action;
  let modifiedSelectedMedia = null;

  modifiedSelectedMedia = state.selectedMedia.map(medium => {
    return medium.mediumId === mediumId
      ? updateObject(medium, {
          contactPersons: contactPersons
        })
      : medium;
  });

  return state.contactPersons === null
    ? updateObject(state, {
        contactPersons: contactPersons,
        selectedMedia: modifiedSelectedMedia
      })
    : updateObject(state, {
        selectedMedia: modifiedSelectedMedia
      });
};

const fetchMediaFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Media Item can't be loaded"
  });
};

const fetchJobImageFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Job Image can't be loaded"
  });
};

const fetchJobIssuesFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Issues can't be loaded"
  });
};

const fetchTechnicalInfoFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical Info can't be loaded"
  });
};

const actualizeTechnicalInfoFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical Info can't be loaded"
  });
};

const downloadReportFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Report can't be downloaded"
  });
};

const downloadPDFFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "PDF can't be downloaded"
  });
};

const saveExistingContainerFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Container #" + action.containerId + " can't be saved"
  });
};

const saveNewContainerFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "New container can't be saved"
  });
};

const createContainerFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Container can't be created"
  });
};



const proofContainerFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Container #" + action.containerId + " can't be created"
  });
};

const setFileImagePreview = (state, action) => {
  return updateObject(state, { fileImageSource: action.fileImageSource });
};

const setContainerId = (state, action) => {
  return updateObject(state, {
    containerId: action.containerId,
    isContainerSaved: true
  });
};

const setContainerFirmenId = (state, action) => {
  return updateObject(state, {
    containerFirmenId: action.containerFirmenId
  });
};

const setContainer = (state, action) => {
  const { container } = action;
  let canSendContainer = true;
  let hasContainerWarning = false;

  if (container.length === 0) {
    canSendContainer = false;
    hasContainerWarning = false;
  } else {
    container.forEach(job => {
      if (!job.preflightErgebnis && !job.hasChildren) {
        canSendContainer = false;
      } else if (
        !job.hasChildren &&
        job.preflightErgebnis &&
        (job.preflightErgebnis.ergebnisId === 50 ||
          job.preflightErgebnis.ergebnisId === 53 ||
          job.preflightErgebnis.ergebnisId === 54 
        )
        
      ) {
        canSendContainer = false;
      } 
      else if (
        job.originalFileName &&
        job.preflightErgebnis.ergebnisId === 52
      ) {
        hasContainerWarning = true;
        console.log(job.preflightErgebnis);

      }
    });
  }

  return updateObject(state, {
    container: container,
    canSendContainer: canSendContainer,
    hasContainerWarning: hasContainerWarning
  });
};


const checkJobHasErgebnisDownloads = (state, action) => {
  const { jobId } = action;
  state.container.map(job => {
    if (job.jobId === jobId) {
      job.hasErgebnisDownloads = true;
    }
    return job;
  });

  return updateObject(state, {
    container: state.container
  });
};


const checkContainerCanBeConverted = (state, action) => {

  let canConvertContainer = false;
  state.container.forEach(job => {
    if (job.preflightErgebnis && job.preflightErgebnis.ergebnisId === 54) {
      canConvertContainer = true;
    }
  });
  return updateObject(state, {
    canConvertContainer: canConvertContainer
  });
};

const checkContainerCanBeConvertedWithoutCurrentJob = (state, action) => {
  const { jobId } = action;
  let canConvertContainer = false;
  
  let modifiedContainer = state.container.filter(job => job.jobId !== jobId);

  modifiedContainer.forEach(job => {
     if (
      job.preflightErgebnis && 
      job.preflightErgebnis.ergebnisId === 54
    ) {
      canConvertContainer = true;
    }
  });

  return updateObject(state, {
    canConvertContainer: canConvertContainer
  });
};


const checkContainerCanBeSent = (state, action) => {
  let canSendContainer = true;
  let hasContainerWarning = false;
  state.container.forEach(job => {
    if (!job.hasChildren && !job.preflightErgebnis) {
      canSendContainer = false;
    } else if (
      !job.hasChildren &&
      job.preflightErgebnis &&
      (job.preflightErgebnis.ergebnisId === 50 ||
        job.preflightErgebnis.ergebnisId === 53 ||
        job.preflightErgebnis.ergebnisId === 54) 
    ) {
      canSendContainer = false;
    } else if (
      job.preflightErgebnis &&
      job.preflightErgebnis.ergebnisId === 52
    ) {
      hasContainerWarning = true;
    }
  });

  if (action.container && action.container.length === 0) {
    canSendContainer = false;
    hasContainerWarning = false;
  }

  return updateObject(state, {
    canSendContainer: canSendContainer,
    hasContainerWarning: hasContainerWarning
  });
};

const setMediaSections = (state, action) => {
  return updateObject(state, {
    selectedMedia: action.mediaSections,
    isEditMode: false
  });
};

const setJobId = (state, action) => {
  const {
    mediaItem,
    chosenMD5Hash,
    splitOrder,
    order,
    hasChildren,
    id
  } = action.activeJobSection;
  const { jobId } = action;
  let modifiedContainer = state.container.map(job => {
    // Child Job
    if (action.activeJobSection.isChild) {
      if (
        job.mediaItem.mediumId === mediaItem.mediumId &&
        job.order === order &&
        job.chosenMD5Hash === chosenMD5Hash &&
        job.isChild &&
        job.splitOrder === splitOrder
      ) {
        job.jobId = jobId;
        job.isJobSaved = true;
        job.error = false;
      }
    } else if (!hasChildren) {
      if (
        job.id === id &&
        job.mediaItem.mediumId === mediaItem.mediumId &&
        !job.isChild &&
        !job.hasChildren
      ) {
        job.jobId = jobId;
        job.isJobSaved = true;
        job.error = false;
      }
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const setParentJob = (state, action) => {
  const { id, mediaItem } = action.activeJobSection;
  const { jobId } = action;

  let modifiedContainer = state.container.map(job => {
    if (
      job.id === id &&
      job.mediaItem.mediumId === mediaItem.mediumId &&
      !job.isChild
    ) {
      job.jobId = jobId;
      job.isFileProofRequired = false;
      job.preflightErgebnis = null;
      job.originalFileName = null;
      job.createdJobImage = null;
      job.stepNumber = 1;
      job.statusId = null;
      job.jobProgress = null;
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const setChildJobId = (state, action) => {
  const { mediaItem, order, chosenMD5Hash, splitOrder } = action.childJob;
  const { jobId } = action;

  let modifiedContainer = state.container.map(job => {
    if (
      job.mediaItem.mediumId === mediaItem.mediumId &&
      job.order === order &&
      job.chosenMD5Hash === chosenMD5Hash &&
      job.isChild &&
      job.splitOrder === splitOrder
    ) {
      job.jobId = jobId;
      job.isJobSaved = true;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const setNonChildJobId = (state, action) => {
  const { mediaItem, order, hasChildren } = action.job;
  const { jobId } = action;

  let modifiedContainer = state.container.map(containerJob => {
    if (
      containerJob.mediaItem.mediumId === mediaItem.mediumId &&
      containerJob.order === order &&
      !hasChildren
    ) {
      containerJob.jobId = jobId;
      containerJob.isJobSaved = true;
    }
    return containerJob;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const setJobFreeFormatText = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.chosenFormatText = action.formatText;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.chosenFormatText = action.formatText;
        }
      }
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const setJobProgress = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.jobProgress = 25;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.jobProgress = 25;
        }
      }
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const setJobStatusId = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.statusId = null;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.statusId = null;
        }
      }
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const createContainerOneJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error uploading File"
  });
};

const saveFileSuccess = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;
  const { fileName } = action;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.jobProgress = 35;
          job.stepNumber = 1;
          job.statusId = null;
          job.originalFileName = action.fileName;
          job.isUploadingFile = true;
          job.createdJobImage = null;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.jobProgress = 35;
          job.stepNumber = 1;
          job.statusId = null;
          job.originalFileName = fileName;
          job.isUploadingFile = true;
          job.createdJobImage = null;
        }
      }
    } else if (job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isUploadingFile = true;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};



const saveConvertedColorGMGFileSuccess = (state, action) => {
  const { fileName } = action;
  console.log(fileName);
  let modifiedContainer = state.container.map(job => {
        if (
          job.jobId === action.jobId
        ) {
          job.jobProgress = 35;
          job.stepNumber = 1;
          job.statusId = null;
          job.isUploadingFile = true; 
          job.createdJobImage = null;
          job.hasErgebnisDownloads= true;
        }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true,
  });
};

const addFileToNewJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Job"
  });
};

const addFileToExistingJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error uploading new Job File"
  });
};

const saveFileFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error uploading File"
  });
};

const saveConvertedColorGMGFileFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error uploading Converted File"
  });
};

const statusCheckSuccess = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;
  
  const { preflightErgebnis } = action;
  let canSendContainer = true;
  let hasContainerWarning = false;



  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.preflightErgebnis = preflightErgebnis;
          job.jobProgress = 85;
          job.stepNumber = 2;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.preflightErgebnis = preflightErgebnis;
          job.jobProgress = 85;
          job.stepNumber = 2;
        }
        console.log(job.preflightErgebnis);
      }
    }

    return job;
  });

  modifiedContainer
    .filter(job => {
      return !job.hasChildren;
    })
    .forEach(job => {
      if (
        !job.preflightErgebnis ||
        job.preflightErgebnis.ergebnisId === 50 ||
        job.preflightErgebnis.ergebnisId === 53 ||
        job.preflightErgebnis.ergebnisId === 54
      ) {
        canSendContainer = false;
      } else if (
        job.preflightErgebnis &&
        job.preflightErgebnis.ergebnisId === 52
      ) {
        hasContainerWarning = true;
      }
      
    });

  if (modifiedContainer.length === 0) {
    canSendContainer = false;
    hasContainerWarning = false;
  }

  return updateObject(state, {
    container: modifiedContainer,
    canSendContainer: canSendContainer,
    hasContainerWarning: hasContainerWarning,
    isContainerTouched: true
  });
};

const convertedColorGMGstatusCheckSuccess = (state, action) => {
  const { preflightErgebnis } = action;
  let canSendContainer = true;
  let hasContainerWarning = false;

  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === action.jobId
    ) {
      job.preflightErgebnis = preflightErgebnis;
      job.jobProgress = 85;
      job.stepNumber = 2;
    }
    return job;
  });

  modifiedContainer
    .filter(job => {
      return !job.hasChildren;
    })
    .forEach(job => {
      if (
        !job.preflightErgebnis ||
        job.preflightErgebnis.ergebnisId === 50 ||
        job.preflightErgebnis.ergebnisId === 53
      ) {
        canSendContainer = false;
      } else if (
        job.preflightErgebnis &&
        job.preflightErgebnis.ergebnisId === 52
      ) {
        hasContainerWarning = true;
      }
    });

  if (modifiedContainer.length === 0) {
    canSendContainer = false;
    hasContainerWarning = false;
  }

  return updateObject(state, {
    container: modifiedContainer,
    canSendContainer: canSendContainer,
    hasContainerWarning: hasContainerWarning,
    isContainerTouched: true
  });
};

const finalizeJobUpload = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;
  const { statusId } = action;
  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.statusId = statusId;
          job.jobProgress = 100;
          job.isUploadingFile = false;
          job.stepNumber = 2;
          job.isFileProofRequired = false;
          job.isUploadingFile = false;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.statusId = statusId;
          job.jobProgress = 100;
          job.isUploadingFile = false;
          job.stepNumber = 2;
          job.isFileProofRequired = false;
          job.isUploadingFile = false;
        }
      }
    } else if (job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isUploadingFile = false;
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const finalizeConvertedColorGMGJobUpload = (state, action) => {
  const { statusId } = action;
  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === action.jobId
    ) {
      job.statusId = statusId;
      job.jobProgress = 100;
      job.isUploadingFile = false;
      job.stepNumber = 2;
      job.isFileProofRequired = false;
      job.isUploadingFile = false;
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const toggleUploadComponent = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.isUploadingFile = !job.isUploadingFile ? true : false;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.isUploadingFile = !job.isUploadingFile ? true : false;
        }
      }
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const checkJobStatusFailed = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.jobProgress = 100;
          job.isUploadingFile = false;
          job.error = true;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.jobProgress = 100;
          job.isUploadingFile = false;
          job.error = true;
        }
      }
    } else if (job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isUploadingFile = false;
      job.error = true;
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const checkConvertedColorGMGJobStatusFailed = (state, action) => {
  let modifiedContainer = state.container.map(job => {

        if (
          job.jobId === action.jobId
        ) {
          job.jobProgress = 100;
          job.isUploadingFile = false;
          job.error = true;
        }

        return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const checkJobStatusFileError = (state, action) => {
  const { id, mediaItem, order, splitOrder, isChild } = action.activeJobSection;

  let modifiedContainer = state.container.map(job => {
    if (!job.hasChildren) {
      if (job.isChild) {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.order === order &&
          job.splitOrder === splitOrder
        ) {
          job.isUploadingFile = false;
          job.statusId = 74;
          job.isFileProofRequired = false;
          job.error = true;
        }
      } else {
        if (
          job.id === id &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          !isChild
        ) {
          job.isUploadingFile = false;
          job.statusId = 74;
          job.isFileProofRequired = false;
          job.error = true;
        }
      }
    } else if (job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isUploadingFile = false;
      job.statusId = 74;
      job.isFileProofRequired = false;
      job.error = true;
    }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const checkConvertedColorGMGJobStatusFileError = (state, action) => {
  let modifiedContainer = state.container.map(job => {
        if (
          job.jobId === action.jobId
        ) {
          job.isUploadingFile = false;
          job.statusId = 74;
          job.isFileProofRequired = false;
          job.error = true;
        }

    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const resetError = (state, action) => {
  let modifiedContainer = state.container.map(job => {
    job.error = false;
    return job;
  });

  return updateObject(state, {
    error: false,
    container: modifiedContainer,
    createdJobImage: null
  });
};

const proofFileStart = (state, action) => {
  let modifiedContainer = state.container.map(job => {
    if (job.jobId === action.jobId) {
      job.isFileProofRequired = job.createdJobImage ? true : false;
      job.isUploadingFile = true;
    } else if (action.activeJobSection.hasChildren) {
      job.isUploadingFile = true;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const proofFileSuccess = (state, action) => {
  let modifiedContainer = state.container.map(job => {
    if (job.jobId === action.jobId) {
      job.preflightErgebnis = null;
      job.statusId = null;
      job.jobProgress = 50;
      job.stepNumber = 1;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const proofFileFail = (state, action) => {
  return updateObject(state, {
    error: true
  });
};


const saveColorGMGFileStart = (state, action) => {
  const { jobId } = action;
  
  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === jobId
    ) {
      job.jobProgress = 35;
      job.stepNumber = 1;
      job.statusId = null;
      job.isUploadingFile = true;
      job.createdJobImage = null;
    }
    return job;
  });

  console.log("saveColorGMGFileStart", modifiedContainer);

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true,
    
  });
  
};

const saveColorGMGFileSuccess = (state, action) => {
  const { jobId, fileId } = action;

  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === jobId
    ) {
      job.jobProgress = 15;
      job.stepNumber = 1;
      job.statusId = null;
      job.colorGMGFileId = fileId;
      job.isUploadingFile = true;
      job.createdJobImage = null;
    }
    return job;
  });

  console.log("saveColorGMGFileSuccess", modifiedContainer);

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const saveColorGMGFileFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error uploading Color GMG File"
  });
};

const saveColorGMGJobSuccess = (state, action) => {
  const { jobId, colorGMGJobId } = action;
 
  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === jobId
    ) {
      job.jobProgress = 25;
      job.stepNumber = 2;
      job.statusId = null;
      job.colorGMGJobId = colorGMGJobId;
      job.isUploadingFile = true;
      job.createdJobImage = null;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const saveColorGMGJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Color GMG Job"
  });
};

const getColorGMGJobResultSuccess = (state, action) => {
  const { jobId, fileId } = action;

  console.log("jobId" +jobId);
  console.log("fileId" +fileId);

  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === jobId
    ) {
      job.jobProgress = 30;
      job.stepNumber = 2;
      job.statusId = null;
      job.convertedFileId = fileId;
      job.isUploadingFile = true;
      job.createdJobImage = null;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const getColorGMGJobResultFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error fetching Color GMG Job Result"
  });
};

const getColorGMGConvertedFileSuccess = (state, action) => {
  const { jobId, file } = action;

  let modifiedContainer = state.container.map(job => {
    if (
      job.jobId === jobId
    ) {
      job.jobProgress = 40;
      job.stepNumber = 2;
      job.statusId = null;
      job.convertedFile = file;
      job.isUploadingFile = true;
      job.createdJobImage = null;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const getColorGMGConvertedFileFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error fetching Color GMG Converted File"
  });
};
//----------------------------------------------
const fetchContainerSuccess = (state, action) => {
  const {
    client,
    product,
    containerNotes,
    containerId,
    jobId,
    preflightErgebnis,
    jobHasPreview,
    statusId,
    fileName
  } = action;
  const updatedClient = updateObject(state.containerFormData.clientName, {
    value: client,
    valid: true
  });

  const updatedProduct = updateObject(state.containerFormData.productName, {
    value: product,
    valid: true
  });

  const updatedContainerNotes = updateObject(state.containerFormData.notes, {
    value: containerNotes,
    valid: true
  });

  const updatedContainerData = updateObject(state.containerFormData, {
    clientName: updatedClient,
    productName: updatedProduct,
    notes: updatedContainerNotes
  });

  return updateObject(state, {
    containerId: containerId,
    jobId: jobId,
    preflightErgebnis: preflightErgebnis,
    jobHasPreview: jobHasPreview,
    containerFormData: updatedContainerData,
    statusId: statusId,
    originalFileName: fileName,
    error: false,
    errorMessage: ""
  });
};

const fetchContainerFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error fetching Container"
  });
};

const setCreatedJobImage = (state, action) => {
  const { jobId, createdJobImage } = action;

  let modifiedContainer = state.container.map(job => {
    if (job.jobId === jobId) {
      job.jobProgress = job.jobProgress !== 100 ? 65 : 100;
      job.createdJobImage = createdJobImage;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const updateContainerFormData = (state, action) => {
  return updateObject(state, {
    containerFormData: action.containerFormData,
    isContainerTouched: true,
    isContainerSaved: false
  });
};

const setActualizedTechnicalInfo = (state, action) => {
  return updateObject(state, {
    technicalInfo: action.technicalInfo
  });
};

const setContainerFormData = (state, action) => {
  const { client, product, notes } = action;
  const updatedClient = updateObject(state.containerFormData.clientName, {
    value: client,
    valid: true
  });

  const updatedProduct = updateObject(state.containerFormData.productName, {
    value: product,
    valid: true
  });

  const updatedContainerNotes = updateObject(state.containerFormData.notes, {
    value: notes,
    valid: true
  });

  const updatedContainerData = updateObject(state.containerFormData, {
    clientName: updatedClient,
    productName: updatedProduct,
    notes: updatedContainerNotes
  });

  return updateObject(state, {
    containerFormData: updatedContainerData
  });
};

const setSelectedIssue = (state, action) => {
  const { activeJobSectionId, mediumId, selectedIssue } = action;

  let modifiedContainer = state.container.map(job => {
    if (job.id === activeJobSectionId && job.mediaItem.mediumId === mediumId) {
      job.selectedIssue = selectedIssue;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerTouched: true
  });
};

const showSavedSuccessMessage = (state, action) => {
  let modifiedContainer = state.container.map(job => {
    job.isJobSaved = true;
    return job;
  });

  return updateObject(state, {
    showSuccessMessage: true,
    isContainerSaved: true,
    container: modifiedContainer
  });
};

const hideContainerSavedSuccessMessage = (state, action) => {
  return updateObject(state, {
    showSuccessMessage: false
  });
};

const jobCheckSuccess = (state, action) => {
  const { jobId, statusResult } = action;

  let modifiedContainer = state.container.map(job => {
    if (job.jobId === jobId && statusResult.statusId === 81) {
      job.statusId = statusResult.statusId;
      job.stepNumber = 4;
    }
    return job;
  });

  let isContainerSent = true;
  modifiedContainer.forEach(job => {
    if (job.originalFileName && job.statusId !== 81) {
      isContainerSent = false;
    }
  });

  return updateObject(state, {
    container: modifiedContainer,
    isContainerSent: isContainerSent
  });
};

const jobCheckStart = (state, action) => {
  let modifiedContainer = state.container.map(job => {
    if (job.jobId === action.jobId) {
      job.stepNumber = 3;
    }
    return job;
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const startSendingContainerForm = (state, action) => {
  return updateObject(state, {
    canSendContainer: false,
    canSaveContainer: false,
    hasContainerWarning: false,
    isContainerBeingSent: true
  });
};


const checkContainerIsSent = (state, action) => {
  let isContainerSent = true;

  state.container.forEach(job => {
    if (job.statusId !== 81 && job.statusId !== 82 && !job.hasChildren) {
      isContainerSent = false;
    }
  });

  if (isContainerSent) {
    return updateObject(state, {
      isContainerSent: true,
      canSaveContainer: false,
      canSendContainer: false,
      hasContainerWarning: false
    });
  } else {
    return state;
  }
};

const addChildJobsToParent = (state, action) => {
  const {
    jobId,
    id,
    order,
    mediaItem,
    parentFormat,
    chosenMD5Hash
  } = action.job;
  const { mediumId } = action;

  let parentJob = state.container
    .filter(job => {
      if (jobId) {
        return job.jobId === jobId;
      } else if (id) {
        return job.id === id && job.mediaItem.mediumId === mediumId;
      } else {
        return (
          job.order === order &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.chosenMD5Hash === parentFormat
        );
      }
    })
    .reduce((arr, el) => el);

  let updatedParentJob = updateObject(parentJob, {
    hasChildren: true,
    parentJobId: null,
    parentId: null,
    parentFormat: null,
    isChild: false,
    splitOrder: null,
    preflightErgebnis: null,
    originalFileName: null,
    createdJobImage: null,
    isFileProofRequired: false
  });

  // get rest of jobs without parent
  let modifiedContainer = state.container.filter(job => {
    if (jobId) {
      return job.jobId !== jobId || job.isChild;
    } else if (id) {
      return (
        job.id !== id || job.mediaItem.mediumId !== mediumId || job.isChild
      );
    } else {
      return (
        job.order !== order ||
        job.chosenMD5Hash !== chosenMD5Hash ||
        job.mediaItem.mediumId !== mediumId ||
        job.isChild
      );
    }
  });

  let newChildJob = updateObject(parentJob, {
    hasChildren: false,
    parentJobId: jobId,
    parentId: id,
    parentFormat: parentJob.chosenMD5Hash,
    isChild: true,
    splitOrder: action.splitOrder,
    chosenMD5Hash: action.chosenMD5Hash,
    parentIssueFormat: action.parentIssueFormat,
    jobId: null,
    isFileProofRequired: false,
    preflightErgebnis: null,
    originalFileName: null,
    createdJobImage: null,
    stepNumber: 1,
    statusId: null,
    jobProgress: null,
    jobData,
    isJobValid: false
  });

  modifiedContainer.push(newChildJob);
  modifiedContainer.push(updatedParentJob);

  return updateObject(state, {
    container: modifiedContainer
  });
};

const addAllChildJobsToParent = (state, action) => {
  const {
    jobId,
    id,
    order,
    mediaItem,
    parentFormat,
    formatLeft,
    formatRight
  } = action.job;
  const { mediumId } = action;

  let parentJob = state.container
    .filter(job => {
      if (jobId) {
        return job.jobId === jobId;
      } else if (id) {
        return job.id === id && job.mediaItem.mediumId === mediumId;
      } else {
        return (
          job.order === order &&
          job.mediaItem.mediumId === mediaItem.mediumId &&
          job.chosenMD5Hash === parentFormat
        );
      }
    })
    .reduce((arr, el) => el);

  let updatedParentJob = updateObject(parentJob, {
    hasChildren: true,
    parentJobId: null,
    parentId: null,
    parentFormat: null,
    isChild: false,
    splitOrder: null,
    preflightErgebnis: null,
    originalFileName: null,
    createdJobImage: null,
    isFileProofRequired: false,
    jobId: null
  });

  // get rest of jobs without parent
  let modifiedContainer = state.container.filter(job => {
    if (jobId) {
      return job.jobId !== jobId || job.isChild;
    } else if (id) {
      return (
        job.id !== id || job.mediaItem.mediumId !== mediumId || job.isChild
      );
    } else {
      return (
        job.order !== order ||
        job.chosenMD5Hash !== formatLeft ||
        job.mediaItem.mediumId !== mediumId ||
        job.isChild ||
        (job.order !== order ||
          job.chosenMD5Hash !== formatRight ||
          job.mediaItem.mediumId !== mediumId ||
          job.isChild)
      );
    }
  });

  let newLeftChildJob = updateObject(parentJob, {
    hasChildren: false,
    parentJobId: jobId,
    parentId: id,
    parentFormat: parentJob.chosenMD5Hash,
    isChild: true,
    splitOrder: 1,
    chosenMD5Hash: action.formatLeft,
    parentIssueFormat: action.parentIssueFormat,
    jobId: null,
    isFileProofRequired: false,
    preflightErgebnis: null,
    originalFileName: null,
    createdJobImage: null,
    stepNumber: 1,
    statusId: null,
    jobProgress: null,
    jobData,
    isJobValid: false
  });

  let newRightChildJob = updateObject(parentJob, {
    hasChildren: false,
    parentJobId: jobId,
    parentId: id,
    parentFormat: parentJob.chosenMD5Hash,
    isChild: true,
    splitOrder: 2,
    chosenMD5Hash: action.formatRight,
    parentIssueFormat: action.parentIssueFormat,
    jobId: null,
    isFileProofRequired: false,
    preflightErgebnis: null,
    originalFileName: null,
    createdJobImage: null,
    stepNumber: 1,
    statusId: null,
    jobProgress: null,
    jobData,
    isJobValid: false
  });

  modifiedContainer.push(newLeftChildJob);
  modifiedContainer.push(newRightChildJob);
  modifiedContainer.push(updatedParentJob);

  return updateObject(state, {
    container: modifiedContainer
  });
};

const removeChildJobsFromParent = (state, action) => {
  const { jobId, mediumId, parentOrder, jobSectionId } = action;

  let parentJob = state.container
    .filter(job => {
      if (jobId) {
        return job.jobId === jobId && !job.isChild;
      } else {
        return (
          job.order === parentOrder &&
          job.mediaItem &&
          job.mediaItem.mediumId === mediumId &&
          job.id === jobSectionId &&
          !job.isChild
        );
      }
    })
    .reduce((arr, el) => el, null);

  let updatedParentJob = null;
  if (parentJob) {
    updatedParentJob = updateObject(parentJob, {
      hasChildren: false,
      isFileProofRequired: false,
      preflightErgebnis: null,
      originalFileName: null,
      createdJobImage: null,
      stepNumber: 1,
      statusId: null,
      jobProgress: null,
      jobData,
      isJobValid: false
    });

    let modifiedContainer = state.container.filter(job => {
      if (jobId) {
        return (
          job.jobId !== jobId &&
          (!job.isChild ||
            (job.isChild &&
              job.mediaItem &&
              job.mediaItem.mediumId !== action.mediumId))
        );
      } else {
        return (
          job.order !== parentOrder ||
          (job.mediaItem && job.mediaItem.mediumId !== mediumId) ||
          job.id !== jobSectionId
        );
      }
    });

    modifiedContainer.push(updatedParentJob);

    return updateObject(state, {
      container: modifiedContainer
    });
  } else {
    return state;
  }
};

const removeNormalJob = (state, action) => {
  const { job, mediumId } = action;

  let modifiedContainer = state.container.filter(selectedJob => {
    if (job.jobId) {
      return job.jobId !== selectedJob.jobId;
    } else {
      return (
        job.order !== selectedJob.order ||
        (selectedJob.mediaItem &&
          selectedJob.mediaItem.mediumId !== mediumId) ||
        job.id !== selectedJob.id
      );
    }
  });

  return updateObject(state, {
    container: modifiedContainer
  });
};

const addFreeFormat = (state, action) => {
  const { id, mediaItem } = action.activeJobSection;
  const {
    width,
    height,
    isAnschnitt,
    isUeberbund,
    platzierung
  } = action.formData;

  let updatedContainer = state.container.map(job => {
    if (job.id === id && job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isJobSaved = false;
      job.isFileProofRequired = job.createdJobImage ? true : false;
      job.isFreeFormatSaved = true;
      job.isFreeFormat = true;
      job.freeFormat = {
        breiteInMM: width,
        hoeheInMM: height,
        istAnschnitt: isAnschnitt && isAnschnitt === "yes" ? 1 : 0,
        ueberBund: isUeberbund ? 1 : 0,
        platzierungImHeft: platzierung,
        formatTyp: 304,
        seitenteil: "FF"
      };
    }
    return job;
  });

  return updateObject(state, {
    container: updatedContainer,
    canSaveContainer: true
  });
};

const editFreeFormat = (state, action) => {
  const { id, mediaItem } = action.activeJobSection;

  let updatedContainer = state.container.map(job => {
    if (job.id === id && job.mediaItem.mediumId === mediaItem.mediumId) {
      job.isFreeFormatSaved = false;
      job.isFreeFormat = true;
    }
    return job;
  });

  return updateObject(state, {
    container: updatedContainer,
    canSaveContainer: false,
    canSendContainer: false,
    hasContainerWarning: false
  });
};

const disableSavingContainer = (state, action) => {
  return updateObject(state, {
    canSaveContainer: false
  });
};

const enableSavingContainer = (state, action) => {
  return updateObject(state, {
    canSaveContainer: true
  });
};

const allowReProof = (state, action) => {
  let updatedContainer = state.container.map(job => {
    if (!job.hasChildren && job.jobId === action.activeJobSection.jobId) {
      job.isFileProofRequired = job.createdJobImage ? true : false;
      job.isJobSaved = false;
      job.chosenFormatText = null;
    } else if (
      job.hasChildren &&
      job.order === action.activeJobSection.order &&
      job.mediaItem.mediumId === action.activeJobSection.mediaItem.mediumId &&
      job.id === action.activeJobSection.id
    ) {
      job.isFileProofRequired = true;
      job.isJobSaved = false;
    }
    return job;
  });

  return updateObject(state, {
    container: updatedContainer
  });
};

export const fetchJobAnonymouslyFailed = (state, action) => {
  return updateObject(state, {
    fetchJobAnonymouslyError: action.error
  });
};

export const setJobForAnonymousUser = (state, action) => {
  return updateObject(state, {
    jobForAnonymousUser: action.job
  });
};

export const setSenderInfo = (state, action) => {
  return updateObject(state, {
    sender: action.sender
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEDIA:
      return setMedia(state, action);
    case actionTypes.SET_MEDIA_EDIT_MODE:
      return setMediaEditMode(state, action);
    case actionTypes.SET_PARENT_MEDIA_EDIT_MODE:
      return setParentMediaEditMode(state, action);
    case actionTypes.SET_MEDIA_SECTIONS:
      return setMediaSections(state, action);
    case actionTypes.SET_JOB_ISSUES:
      return setJobIssues(state, action);
    case actionTypes.SET_JOB_ISSUES_EDIT_MODE:
      return setJobIssuesEditMode(state, action);
    case actionTypes.SET_ISSUE:
      return setIssue(state, action);
    case actionTypes.SET_ISSUE_EDIT_MODE:
      return setIssueEditMode(state, action);


     
    case actionTypes.SET_PARENT_ISSUE_EDIT_MODE:
      return setParentIssueEditMode(state, action);
    case actionTypes.SET_CONTACT_PERSON:
      return setContactPerson(state, action);
    case actionTypes.SET_CONTACTS_EDIT_MODE:
      return setContactsEditMode(state, action);
    case actionTypes.FETCH_MEDIA_FAIL:
      return fetchMediaFailed(state, action);
    case actionTypes.FETCH_JOB_ISSUES_FAIL:
      return fetchJobIssuesFailed(state, action);
    case actionTypes.FETCH_TECHNICAL_INFO_FAIL:
      return fetchTechnicalInfoFailed(state, action);
    case actionTypes.SET_ACTUALIZED_TECHNICAL_INFO:
      return setActualizedTechnicalInfo(state, action);
    case actionTypes.SET_FILE_IMAGE_PREVIEW:
      return setFileImagePreview(state, action);
    case actionTypes.SET_CONTAINER:
      return setContainer(state, action);
    case actionTypes.SET_CONTAINER_ID:
      return setContainerId(state, action);
    case actionTypes.SET_CONTAINER_FIRMEN_ID:
        return setContainerFirmenId(state, action);
    case actionTypes.SET_JOB_ID:
      return setJobId(state, action);
    case actionTypes.SET_PARENT_JOB:
      return setParentJob(state, action);
    case actionTypes.SET_JOB_PROGRESS:
      return setJobProgress(state, action);
    case actionTypes.SET_JOB_FREE_FORMAT_TEXT:
        return setJobFreeFormatText(state, action);
    case actionTypes.SET_JOB_STATUS_ID:
      return setJobStatusId(state, action);
    case actionTypes.CREATE_CONTAINER_ONE_JOB_FAIL:
      return createContainerOneJobFailed(state, action);
    case actionTypes.ADD_FILE_TO_NEW_JOB_FAILED:
      return addFileToNewJobFailed(state, action);
    case actionTypes.ADD_FILE_TO_EXISTING_JOB_FAIL:
      return addFileToExistingJobFailed(state, action);
    case actionTypes.SAVE_FILE_SUCCESS:
      return saveFileSuccess(state, action);
    case actionTypes.SAVE_FILE_FAIL:
      return saveFileFailed(state, action);
      case actionTypes.SAVE_CONVERTED_COLOR_GMG_FILE_SUCCESS:
        return saveConvertedColorGMGFileSuccess(state, action);
      case actionTypes.SAVE_CONVERTED_COLOR_GMG_FILE_FAIL:
        return saveConvertedColorGMGFileFailed(state, action);
    case actionTypes.STATUS_CHECK_SUCCESS:
      return statusCheckSuccess(state, action);
    case actionTypes.CONVERTED_COLOR_GMG_STATUS_CHECK_SUCCESS:
        return convertedColorGMGstatusCheckSuccess(state, action);
    case actionTypes.FINIALIZE_JOB_UPLOAD:
      return finalizeJobUpload(state, action);
    case actionTypes.FINIALIZE_CONVERTED_COLOR_GMG_JOB_UPLOAD:
      return finalizeConvertedColorGMGJobUpload(state, action);
    case actionTypes.TOGGLE_UPLOAD_COMPONENT:
      return toggleUploadComponent(state, action);
    case actionTypes.CHECK_JOB_STATUS_FAIL:
      return checkJobStatusFailed(state, action);
    case actionTypes.CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FAIL:
        return checkConvertedColorGMGJobStatusFailed(state, action);
    case actionTypes.RESET_ERROR:
      return resetError(state, action);
    case actionTypes.PROOF_FILE_START:
      return proofFileStart(state, action);
    case actionTypes.PROOF_FILE_SUCCESS:
      return proofFileSuccess(state, action);
    case actionTypes.PROOF_FILE_FAIL:
      return proofFileFail(state, action);

    case actionTypes.SAVE_COLOR_GMG_FILE_START:
        return saveColorGMGFileStart(state, action);
    case actionTypes.SAVE_COLOR_GMG_FILE_SUCCESS:
      return saveColorGMGFileSuccess(state, action);
    case actionTypes.SAVE_COLOR_GMG_FILE_FAIL:
      return saveColorGMGFileFailed(state, action);
    case actionTypes.SAVE_COLOR_GMG_JOB_SUCCESS:
      return saveColorGMGJobSuccess(state, action);
    case actionTypes.SAVE_COLOR_GMG_JOB_FAIL:
      return saveColorGMGJobFailed(state, action);
    case actionTypes.GET_COLOR_GMG_JOB_RESULT_SUCCESS:
      return getColorGMGJobResultSuccess(state, action);
    case actionTypes.GET_COLOR_GMG_JOB_RESULT_FAIL:
      return  getColorGMGJobResultFailed(state, action);
    case actionTypes.GET_COLOR_GMG_CONVERTED_FILE_SUCCESS:
      return getColorGMGConvertedFileSuccess(state, action);
    case actionTypes.GET_COLOR_GMG_CONVERTED_FILE_FAIL:
      return getColorGMGConvertedFileFailed(state, action);


    case actionTypes.FETCH_CONTAINER_SUCCESS:
      return fetchContainerSuccess(state, action);
    case actionTypes.FETCH_CONTAINER_FAIL:
      return fetchContainerFail(state, action);
    case actionTypes.LEAVE_JOB_BUILDER:
      return leaveJobBuilder(state, action);
    case actionTypes.SET_CREATED_JOB_IMAGE:
      return setCreatedJobImage(state, action);
    case actionTypes.SET_UPDATED_CONTAINER_FORM_DATA:
      return updateContainerFormData(state, action);
    case actionTypes.SET_CONTAINER_FORM_DATA:
      return setContainerFormData(state, action);
    case actionTypes.SET_SELECTED_ISSUE:
      return setSelectedIssue(state, action);
    case actionTypes.SHOW_CONTAINER_SAVED_SUCCESS_MESSAGE:
      return showSavedSuccessMessage(state, action);
    case actionTypes.HIDE_CONTAINER_SAVED_SUCCESS_MESSAGE:
      return hideContainerSavedSuccessMessage(state, action);
    case actionTypes.JOB_CHECK_SUCCESS:
      return jobCheckSuccess(state, action);
    case actionTypes.JOB_CHECK_START:
      return jobCheckStart(state, action);
    case actionTypes.START_SENDING_CONTAINER_FORM:
      return startSendingContainerForm(state, action);
    case actionTypes.CHECK_CONTAINER_IS_SENT:
      return checkContainerIsSent(state, action);
    case actionTypes.CHECK_CONTAINER_CAN_BE_SENT:
      return checkContainerCanBeSent(state, action);

    case actionTypes.CHECK_CONTAINER_CAN_BE_CONVERTED:
      return checkContainerCanBeConverted(state, action); 

    case actionTypes.CHECK_CONTAINER_CAN_BE_CONVERTED_WITHOUT_CURRENT_JOB:
      return checkContainerCanBeConvertedWithoutCurrentJob(state, action); 

    case actionTypes.CHECK_JOB_HAS_ERGEBNIS_DOWNLOADS:
      return checkJobHasErgebnisDownloads(state,action);

    case actionTypes.ADD_CHILD_JOBS_TO_PARENT:
      return addChildJobsToParent(state, action);
    case actionTypes.ADD_ALL_CHILD_JOBS_TO_PARENT:
      return addAllChildJobsToParent(state, action);
    case actionTypes.REMOVE_CHILD_JOBS_FROM_PARENT:
      return removeChildJobsFromParent(state, action);
    case actionTypes.REMOVE_NORMAL_JOB:
      return removeNormalJob(state, action);
    case actionTypes.SET_CHILD_JOB_ID:
      return setChildJobId(state, action);
    case actionTypes.SET_NON_CHILD_JOB_ID:
      return setNonChildJobId(state, action);
    case actionTypes.ADD_FREE_FORMAT:
      return addFreeFormat(state, action);
    case actionTypes.EDIT_FREE_FORMAT:
      return editFreeFormat(state, action);
    case actionTypes.DISABLE_SAVING_CONTAINER:
      return disableSavingContainer(state, action);
     case actionTypes.ENABLE_SAVING_CONTAINER:
      return enableSavingContainer(state, action);
    case actionTypes.ALLOW_REPROOF:
      return allowReProof(state, action);
    case actionTypes.FETCH_JOB_ANONYMOUSLY_FAILED:
      return fetchJobAnonymouslyFailed(state, action);
    case actionTypes.SET_JOB_FOR_ANONYMOUS_USER:
      return setJobForAnonymousUser(state, action);
    case actionTypes.SET_SENDER_INFO:
      return setSenderInfo(state, action);
    case actionTypes.ACTUALIZE_TECHNICAL_INFO_FAIL:
      return actualizeTechnicalInfoFailed(state, action);
    case actionTypes.FETCH_JOB_IMAGE_FAIL:
      return fetchJobImageFailed(state, action);
    case actionTypes.DOWNLOAD_REPORT_FAIL:
      return downloadReportFailed(state, action);
    case actionTypes.DOWNLOAD_PDF_FAIL:
      return downloadPDFFailed(state, action);
    case actionTypes.SAVE_EXISTING_CONTAINER_FAIL:
      return saveExistingContainerFailed(state, action);
    case actionTypes.SAVE_NEW_CONTAINER_FAIL:
      return saveNewContainerFailed(state, action);
    case actionTypes.CREATE_CONTAINER_FAIL:
      return createContainerFailed(state, action);
    case actionTypes.PROOF_CONTAINER_FAIL:
      return proofContainerFailed(state, action);
    case actionTypes.CHECK_JOB_STATUS_FILE_ERROR:
      return checkJobStatusFileError(state, action);
    case actionTypes.CHECK_CONVERTED_COLOR_GMG_JOB_STATUS_FILE_ERROR:
      return checkConvertedColorGMGJobStatusFileError(state, action);
    default:
      return state;
  }
};



export default reducer;
