import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  pristineJobItems: null,
  jobItems: null,
  statusFilter: null,
  jobExchangeFilter: null,
  publishedStatusFilter: null,
  wordTagFilter: null,
  colorTagFilter: null,
  preflightStatusFilter: null,
  searchKeyword: "",
  isLoading: true,
  wordTags: [],
  colorTags: [],
  gridSettings: null,
  gridReceiverSettings: null,
  isContainerBeingSent: false,
  isContainerSent: false,
  error: false,
  errorMessage: "",
  startCopyingContainer: false,
  gridType: null,
  mediumFilter: null,
  issueFilter: null,
  savedView: null,
  senderGridGrouping: null,
  senderGridSorting: null,
  receiverGridGrouping: null,
  receiverGridSorting: null,
  contacts: null
};

const leaveJobList = (state, action) => {
  return updateObject(state, {
    ...initialState
  });
};

const setJobs = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    pristineJobItems: action.jobItems.slice(),
    statusFilter: null,
    wordTagFilter: null,
    colorTagFilter: null,
    preflightStatusFilter: null,
    mediumFilter: null,
    issueFilter: null,
    jobExchangeFilter: null,
    publishedStatusFilter: null,
    isLoading: false,
    error: false,
    errorMessage: "",
    gridType: action.gridType,
    contacts: null
  });
};

const setJobsByDate = (state, action) => {
  let jobs = action.jobItems;

  // filter by words
  if (state.wordTagFilter) {
    jobs = jobs.filter(item => {
      if (item.tag !== undefined) {
        return item.tag === state.wordTagFilter;
      } else if (item.stichworte) {
        return item.stichworte === state.wordTagFilter;
      }
      return false;
    });
  }

  // filter by status
  if (state.statusFilter) {
    jobs = jobs.filter(item => {
      switch (state.statusFilter) {
        case "notsent":
          return item.statusId < 81;
        case "sent":
          return item.statusId >= 81;
        default:
          return true;
      }
    });
  }

  // filter by preflight status
  if (state.preflightStatusFilter) {
    jobs = jobs.filter(
      item => item.ergebnisId.toString() === state.preflightStatusFilter
    );
  }

  // filter by color
  if (state.colorTagFilter) {
    jobs = jobs.filter(item => {
      if (item.colorTag !== undefined) {
        return item.colorTag
          ? item.colorTag.id === state.colorTagFilter
          : false;
      } else if (item.farben) {
        let farbenTag =
          typeof item.farben === "string" && item.farben.includes("code")
            ? JSON.parse(item.farben)
            : item.farben;
        return farbenTag && farbenTag.id === state.colorTagFilter;
      }
      return false;
    });
  }

  // filter by medium
  if (state.mediumFilter) {
    jobs = jobs.filter(item => item.medium === state.mediumFilter);
  }

  // filter by issue
  if (state.issueFilter) {
    jobs = jobs.filter(item => item.heft === state.issueFilter);
  }

  // filter by exchange job
  if (state.jobExchangeFilter) {
    if (state.jobExchangeFilter === "oldjobs") {
      jobs = jobs.filter(item => {
        return item.ausgetauschtDurchJobId !== undefined;
      });
    } else if (state.jobExchangeFilter === "newjobs") {
      jobs = jobs.filter(item => {
        return item.austauschFuerJobId !== undefined;
      });
    } else {
      return jobs;
    }
  }

  // filter by published status
  if (state.publishedStatusFilter) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (state.publishedStatusFilter === "pending") {
      jobs = jobs.filter(item => {
        return new Date(item.et).getTime() > today.getTime();
      });
    } else if (state.publishedStatusFilter === "published") {
      jobs = jobs.filter(item => {
        return new Date(item.et).getTime() <= today.getTime();
      });
    } else {
      return jobs;
    }
  }

  return updateObject(state, {
    jobItems: jobs,
    pristineJobItems: action.jobItems.slice(),
    isLoading: false,
    error: false,
    errorMessage: "",
    dateFilter:
      action.dateFilter !== null
        ? action.dateFilter.toString()
        : action.dateFilter,
    //gridType: action.gridType
  });
};

const setReceiverJobsByDate = (state, action) => {
  let jobs = action.jobItems;

  // filter by words
  if (state.wordTagFilter) {
    jobs = jobs.filter(item => {
      if (item.tag !== undefined) {
        return item.tag === state.wordTagFilter;
      } else if (item.stichworte) {
        return item.stichworte === state.wordTagFilter;
      }
      return false;
    });
  }

  // filter by status
  if (state.statusFilter) {
    jobs = jobs.filter(item => {
      switch (state.statusFilter) {
        case "notreceived":
          return item.statusId === 81;
        case "received":
          return item.statusId > 81;
        default:
          return true;
      }
    });
  }

  // filter by preflight status
  if (state.preflightStatusFilter) {
    jobs = jobs.filter(
      item => item.ergebnisId.toString() === state.preflightStatusFilter
    );
  }

  // filter by color
  if (state.colorTagFilter) {
    jobs = jobs.filter(item => {
      if (item.colorTag !== undefined) {
        return item.colorTag
          ? item.colorTag.id === state.colorTagFilter
          : false;
      } else if (item.farben) {
        let farbenTag =
          typeof item.farben === "string" && item.farben.includes("code")
            ? JSON.parse(item.farben)
            : item.farben;
        return farbenTag && farbenTag.id === state.colorTagFilter;
      }
      return false;
    });
  }

  // filter by medium
  if (state.mediumFilter) {
    jobs = jobs.filter(item => item.medium === state.mediumFilter);
  }

  // filter by issue
  if (state.issueFilter) {
    jobs = jobs.filter(item => item.heft === state.issueFilter);
  }

  // filter by exchange job
  if (state.jobExchangeFilter) {
    if (state.jobExchangeFilter === "oldjobs") {
      jobs = jobs.filter(item => {
        return item.ausgetauschtDurchJobId !== undefined;
      });
    } else if (state.jobExchangeFilter === "newjobs") {
      jobs = jobs.filter(item => {
        return item.austauschFuerJobId !== undefined;
      });
    } else {
      return jobs;
    }
  }

  // filter by published status
  if (state.publishedStatusFilter) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (state.publishedStatusFilter === "pending") {
      jobs = jobs.filter(item => {
        return new Date(item.et).getTime() > today.getTime();
      });
    } else if (state.publishedStatusFilter === "published") {
      jobs = jobs.filter(item => {
        return new Date(item.et).getTime() <= today.getTime();
      });
    } else {
      return jobs;
    }
  }

  return updateObject(state, {
    jobItems: jobs,
    pristineJobItems: action.jobItems.slice(),
    isLoading: false,
    error: false,
    errorMessage: "",
    dateFilter: action.dateFilter !== null ? action.dateFilter.toString() : "0",
    //gridType: action.gridType
  });
};

const fetchJobsFail = (state, action) => {
  return updateObject(state, {
    isLoading: false,
    error: true,
    errorMessage: "Jobs können nicht geladen werden"
  });
};

const copyJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Job #" + action.jobId + " kann nicht kopiert werden"
  });
};

const copyContainerFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Container #" + action.jobId + " kann nicht kopiert werden"
  });
};

const exchangeJobFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Job #" + action.jobId + " kann nicht austauscht werden"
  });
};

const setJobAsReceivedFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage:
      "Job #" + action.jobId + " kann nicht als abgeholt markiert werden"
  });
};

const fetchContainerNotesFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Container #" + action.containerId + " notes can't be fetched"
  });
};

const updateJobs = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    pristineJobItems: action.jobItems,
    error: false,
    errorMessage: null
  });
};

const filterByStatus = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    statusFilter: action.statusFilter
  });
};

const filterByStatusFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByPublicationStatus = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    publishedStatusFilter: action.publishedStatusFilter
  });
};

const filterByPublicationStatusFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByJobExchange = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    jobExchangeFilter: action.jobExchangeFilter
  });
};

const filterByJobExchangeFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByWord = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    wordTagFilter: action.wordTagFilter
  });
};

const filterByWordFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByColor = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    colorTagFilter: action.colorTagFilter
  });
};

const filterByColorFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByPreflightStatus = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    preflightStatusFilter: action.preflightStatusFilter
  });
};

const filterByPreflightStatusFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByMedium = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    mediumFilter: action.mediumFilter
  });
};

const filterByMediumFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const filterByIssue = (state, action) => {
  return updateObject(state, {
    jobItems: action.jobItems,
    issueFilter: action.issueFilter
  });
};

const filterByIssueFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error filteringjobs"
  });
};

const sendJobFailed = (state, action) => {
  return updateObject(state, {
    isLoading: false,
    error: true,
    errorMessage: "Jobs können nicht geladen werden"
  });
};

const saveWordTagsSuccess = (state, action) => {
  return updateObject(state, {
    wordTags: action.wordTags
  });
};

const saveWordTagsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error tagging Stichworte"
  });
};

const saveColorTagsSuccess = (state, action) => {
  return updateObject(state, {
    colorTags: action.colorTags
  });
};

const saveGridSettingsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid"
  });
};

const saveGridSettingsSuccess = (state, action) => {
  return updateObject(state, {
    gridSettings: action.gridSettings.columns
  });
};

const saveReceiverGridSettingsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid"
  });
};

const saveReceiverGridSettingsSuccess = (state, action) => {
  return updateObject(state, {
    gridReceiverSettings: action.gridSettings.columns
  });
};

const setSearchTerm = (state, action) => {
  return updateObject(state, {
    searchKeyword: action.searchKeyword
  });
};

const saveViewSuccess = (state, action) => {
  return updateObject(state, {
    savedView: action.value
  });
};

const saveViewFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving view"
  });
};

const setUserPreferences = (state, action) => {
  return updateObject(state, {
    wordTags: action.wordTags,
    colorTags: action.colorTags,
    gridSettings: action.gridSettings,
    senderGridGrouping:
      action.savedView && action.savedView === "custom"
        ? action.senderGridGrouping
        : null,
    senderGridSorting:
      action.savedView && action.savedView === "custom"
        ? action.senderGridSorting
        : null,
    dateFilter: action.senderDateTag !== null ? action.senderDateTag : "0",
    statusFilter: action.statusFilter ? action.statusFilter : null,
    mediumFilter: action.mediumFilter ? action.mediumFilter : null,
    issueFilter: action.issueFilter ? action.issueFilter : null,
    preflightStatusFilter: action.preflightStatusFilter
      ? action.preflightStatusFilter
      : null,
    wordTagFilter: action.wordTagFilter ? action.wordTagFilter : null,
    colorTagFilter: action.colorTagFilter ? action.colorTagFilter : null,
    savedView: action.savedView ? action.savedView : null,
    jobExchangeFilter: action.jobExchangeFilter
      ? action.jobExchangeFilter
      : null,
    publishedStatusFilter: action.publishedStatusFilter
      ? action.publishedStatusFilter
      : null
  });
};

const setReceiverUserPreferences = (state, action) => {
  return updateObject(state, {
    wordTags: action.wordTags,
    colorTags: action.colorTags,
    gridReceiverSettings: action.gridReceiverSettings,
    receiverGridGrouping:
      action.savedView && action.savedView === "custom"
        ? action.receiverGridGrouping
        : null,
    receiverGridSorting:
      action.savedView && action.savedView === "custom"
        ? action.receiverGridSorting
        : null,
    dateFilter: action.receiverDateTag !== null ? action.receiverDateTag : "0",
    statusFilter: action.statusFilter ? action.statusFilter : null,
    mediumFilter: action.mediumFilter ? action.mediumFilter : null,
    issueFilter: action.issueFilter ? action.issueFilter : null,
    preflightStatusFilter: action.preflightStatusFilter
      ? action.preflightStatusFilter
      : null,
    wordTagFilter: action.wordTagFilter ? action.wordTagFilter : null,
    colorTagFilter: action.colorTagFilter ? action.colorTagFilter : null,
    savedView: action.savedView ? action.savedView : null,
    jobExchangeFilter: action.jobExchangeFilter
      ? action.jobExchangeFilter
      : null,
    publishedStatusFilter: action.publishedStatusFilter
      ? action.publishedStatusFilter
      : null
  });
};

const fecthUserPreferencesFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Fehler"
  });
};

const tagJobFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Fehler Job tagging"
  });
};

const searchJobsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Keine Jobs gefunden"
  });
};

const deleteJobFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Fehler bei Job Löschen"
  });
};

const downloadJobFileFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Fehler Download Job Zip"
  });
};



const downloadReportFileFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Fehler Download Report " + action.fileType
  });
};

const startSendingContainer = (state, action) => {
  return updateObject(state, {
    isContainerBeingSent: true,
    isContainerSent: false
  });
};

const finishSendingContainer = (state, action) => {
  return updateObject(state, {
    isContainerBeingSent: false,
    isContainerSent: true
  });
};

const hideContainerSentSuccessMessage = (state, action) => {
  return updateObject(state, {
    isContainerBeingSent: false,
    isContainerSent: false
  });
};

const saveSenderGroupingFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid grouping"
  });
};

const saveSenderGroupingSuccess = (state, action) => {
  return updateObject(state, {
    senderGridGrouping: action.grouping
  });
};

const changeSenderGridGrouping = (state, action) => {
  return updateObject(state, {
    senderGridGrouping: action.grouping
  });
};

const saveReceiverGroupingFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid grouping"
  });
};

const saveReceiverGroupingSuccess = (state, action) => {
  return updateObject(state, {
    receiverGridGrouping: action.grouping
  });
};

const changeReceiverGridGrouping = (state, action) => {
  return updateObject(state, {
    receiverGridGrouping: action.grouping
  });
};

const saveSenderSortingFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid sorting"
  });
};

const saveSenderSortingSuccess = (state, action) => {
  return updateObject(state, {
    senderGridSorting: action.sorting
  });
};

const saveReceiverSortingFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Error saving Grid sorting"
  });
};

const saveReceiverSortingSuccess = (state, action) => {
  return updateObject(state, {
    receiverGridSorting: action.sorting
  });
};

const changeSenderGridSorting = (state, action) => {
  return updateObject(state, {
    senderGridSorting: action.sorting
  });
};

const changeReceiverGridSorting = (state, action) => {
  return updateObject(state, {
    receiverGridSorting: action.sorting
  });
};

const setJobListNotes = (state, action) => {
  let modifiedJobItems = state.jobItems.map(job => {
    if (job.id === action.jobId) {
      job.containerNote = action.containerNote || "";
      job.jobNote = action.jobNote || "";
    }
    return job;
  });

  return updateObject(state, {
    jobItems: modifiedJobItems
  });
};

const setCopyContainerSuccess = (state, action) => {
  return updateObject(state, {
    copiedContainerId: action.containerId,
    copiedJobId: action.jobId,
    originalCopiedId: action.originalCopiedId,
    copyType: action.copyType
  });
};

const finishCopyingContainer = (state, action) => {
  return updateObject(state, {
    startCopyingContainer: false
  });
};

const enterJobList = (state, action) => {
  return updateObject(state, {
    gridType: action.gridType
  });
};

const notifyJobChange = (state, action) => {
  return updateObject(state, {
    updatedContainerId: action.containerId
  });
};

const resetFilters = (state, action) => {
  return updateObject(state, {
    statusFilter: null,
    wordTagFilter: null,
    colorTagFilter: null,
    preflightStatusFilter: null,
    publishedStatusFilter: null,
    jobExchangeFilter: null
  });
};

const setJobListContactInfo = (state, action) => {
  return updateObject(state, {
    contacts: action.contacts
  });
};

const requestJobEmailSuccess = (state, action) => {
  return updateObject(state, {
    isConfirmationEmailRequested: true,
    confirmationJobId: action.jobId,
    error: false,
    errorMessage: null
  });
}

const requestJobEmailFail = (state, action) => {
  return updateObject(state, {
    isConfirmationEmailRequested: false,
    confirmationJobId: action.jobId
  });
}

const hideConfirmationEmailSuccessMessage = (state, action) => {
  return updateObject(state, {
    isConfirmationEmailRequested: false,
    confirmationJobId: null,
    error: false,
    errorMessage: null
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_JOBS:
      return setJobs(state, action);
    case actionTypes.SET_JOBS_BY_DATE:
      return setJobsByDate(state, action);
    case actionTypes.SET_RECEIVER_JOBS_BY_DATE:
      return setReceiverJobsByDate(state, action);
    case actionTypes.FETCH_JOBS_FAIL:
      return fetchJobsFail(state, action);
    case actionTypes.UPDATE_JOBS:
      return updateJobs(state, action);
    case actionTypes.FILTER_JOBS_BY_STATUS_SUCCESS:
      return filterByStatus(state, action);
    case actionTypes.FILTER_JOBS_BY_STATUS_FAIL:
      return filterByStatusFail(state, action);
    case actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS_SUCCESS:
      return filterByPublicationStatus(state, action);
    case actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS_FAIL:
      return filterByPublicationStatusFail(state, action);
    case actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE_SUCCESS:
      return filterByJobExchange(state, action);
    case actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE_FAIL:
      return filterByJobExchangeFail(state, action);
    case actionTypes.FILTER_JOBS_BY_WORD_SUCCESS:
      return filterByWord(state, action);
    case actionTypes.FILTER_JOBS_BY_WORD_FAIL:
      return filterByWordFail(state, action);
    case actionTypes.FILTER_JOBS_BY_COLOR_SUCCESS:
      return filterByColor(state, action);
    case actionTypes.FILTER_JOBS_BY_COLOR_FAIL:
      return filterByColorFail(state, action);
    case actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS_SUCCESS:
      return filterByPreflightStatus(state, action);
    case actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS_FAIL:
      return filterByPreflightStatusFail(state, action);
    case actionTypes.SET_JOBS_FROM_LOCAL:
      return setJobs(state, action);
    case actionTypes.SEND_JOB_FAIL:
      return sendJobFailed(state, action);
    case actionTypes.SAVE_WORD_TAGS_SUCCESS:
      return saveWordTagsSuccess(state, action);
    case actionTypes.SAVE_WORD_TAGS_FAIL:
      return saveWordTagsFail(state, action);
    case actionTypes.SAVE_COLOR_TAGS_SUCCESS:
      return saveColorTagsSuccess(state, action);
    case actionTypes.SAVE_GRID_SETTINGS_SUCCESS:
      return saveGridSettingsSuccess(state, action);
    case actionTypes.SAVE_GRID_SETTINGS_FAIL:
      return saveGridSettingsFail(state, action);
    case actionTypes.SAVE_RECEIVER_GRID_SETTINGS_SUCCESS:
      return saveReceiverGridSettingsSuccess(state, action);
    case actionTypes.SAVE_RECEIVER_GRID_SETTINGS_FAIL:
      return saveReceiverGridSettingsFail(state, action);
    case actionTypes.SET_SEARCH_TERM:
      return setSearchTerm(state, action);
    case actionTypes.SET_USER_PREFERENCES:
      return setUserPreferences(state, action);
    case actionTypes.SET_RECEIVER_USER_PREFERENCES:
      return setReceiverUserPreferences(state, action);
    case actionTypes.TAG_JOB_FAIL:
      return tagJobFail(state, action);
    case actionTypes.FETCH_USER_PREFERENCES_FAIL:
      return fecthUserPreferencesFail(state, action);
    case actionTypes.SEARCH_JOBS_FAIL:
      return searchJobsFail(state, action);
    case actionTypes.DELETE_JOB_FAIL:
      return deleteJobFail(state, action);
    case actionTypes.DOWNLOAD_JOB_FILE_FAIL:
      return downloadJobFileFail(state, action);
    case actionTypes.DOWNLOAD_REPORT_FILE_FAIL:
      return downloadReportFileFail(state, action);
    case actionTypes.LEAVE_JOB_LIST:
      return leaveJobList(state, action);
    case actionTypes.START_SENDING_CONTAINER:
      return startSendingContainer(state, action);
    case actionTypes.FINISH_SENDING_CONTAINER:
      return finishSendingContainer(state, action);
    case actionTypes.HIDE_CONTAINER_SENT_SUCCESS_MESSAGE:
      return hideContainerSentSuccessMessage(state, action);
    case actionTypes.SAVE_SENDER_GROUPING_SUCCESS:
      return saveSenderGroupingSuccess(state, action);
    case actionTypes.SAVE_SENDER_GROUPING_FAIL:
      return saveSenderGroupingFail(state, action);
    case actionTypes.SAVE_RECEIVER_GROUPING_SUCCESS:
      return saveReceiverGroupingSuccess(state, action);
    case actionTypes.SAVE_RECEIVER_GROUPING_FAIL:
      return saveReceiverGroupingFail(state, action);
    case actionTypes.SAVE_SENDER_SORTING_SUCCESS:
      return saveSenderSortingSuccess(state, action);
    case actionTypes.SAVE_SENDER_SORTING_FAIL:
      return saveSenderSortingFail(state, action);
    case actionTypes.SAVE_RECEIVER_SORTING_SUCCESS:
      return saveReceiverSortingSuccess(state, action);
    case actionTypes.SAVE_RECEIVER_SORTING_FAIL:
      return saveReceiverSortingFail(state, action);
    case actionTypes.SET_JOBLIST_NOTES:
      return setJobListNotes(state, action);
    case actionTypes.SET_COPY_CONTAINER_SUCCESS:
      return setCopyContainerSuccess(state, action);
    case actionTypes.FINISH_COPYING_CONTAINER:
      return finishCopyingContainer(state, action);
    case actionTypes.ENTER_JOB_LIST:
      return enterJobList(state, action);
    case actionTypes.SET_JOB_AS_RECEIVED_FAIL:
      return setJobAsReceivedFail(state, action);
    case actionTypes.FETCH_CONTAINER_JOB_NOTES_FAIL:
      return fetchContainerNotesFailed(state, action);
    case actionTypes.COPY_JOB_FAIL:
      return copyJobFailed(state, action);
    case actionTypes.COPY_CONTAINER_FAIL:
      return copyContainerFailed(state, action);
    case actionTypes.EXCHANGE_JOB_FAIL:
      return exchangeJobFailed(state, action);
    case actionTypes.NOTIFY_JOB_CHANGE:
      return notifyJobChange(state, action);
    case actionTypes.FILTER_BY_MEDIUM_SUCCESS:
      return filterByMedium(state, action);
    case actionTypes.FILTER_BY_MEDIUM_FAIL:
      return filterByMediumFail(state, action);
    case actionTypes.FILTER_BY_ISSUE_SUCCESS:
      return filterByIssue(state, action);
    case actionTypes.FILTER_BY_ISSUE_FAIL:
      return filterByIssueFail(state, action);
    case actionTypes.RESET_JOBLIST_FILTERS:
      return resetFilters(state, action);
    case actionTypes.SAVE_VIEW_SUCCESS:
      return saveViewSuccess(state, action);
    case actionTypes.SAVE_VIEW_FAIL:
      return saveViewFail(state, action);
    case actionTypes.CHANGE_SENDER_GRID_SORTING:
      return changeSenderGridSorting(state, action);
    case actionTypes.CHANGE_RECEIVER_GRID_SORTING:
      return changeReceiverGridSorting(state, action);
    case actionTypes.CHANGE_SENDER_GRID_GROUPING:
      return changeSenderGridGrouping(state, action);
    case actionTypes.CHANGE_RECEIVER_GRID_GROUPING:
      return changeReceiverGridGrouping(state, action);
    case actionTypes.SET_JOBLIST_CONTACT_INFO:
      return setJobListContactInfo(state, action);
    case actionTypes.REQUEST_CONFIRMATION_EMAIL_SUCCESS:
      return requestJobEmailSuccess(state, action);
    case actionTypes.REQUEST_CONFIRMATION_EMAIL_FAIL:
      return requestJobEmailFail(state, action);
    case actionTypes.HIDE_CONFIRMATION_EMAIL_SUCCESS_MESSAGE:
      return hideConfirmationEmailSuccessMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
